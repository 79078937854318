export const socialmedias = [
      {
            id: 1,
            title: "Facebook",
            icon: <i className="bx bxl-facebook"></i>,
            link: "",
      },
      {
            id: 1,
            title: "Instagram",
            icon: <i className="bx bxl-instagram"></i>,
            link: "",
      },
      {
            id: 1,
            title: "",
            icon: <i className="ri-twitter-x-line"></i>,
            link: "Twitter",
      },
];
