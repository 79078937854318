import React, { useEffect, useState } from "react";
import getCurrentDate from "../../../../hooks/CurrentData";
import {
      SearchFormAction,
      SearchFormWrapper,
} from "../../home/SearchForm/styles";
import { Form } from "react-bootstrap";
import FormItem from "../../../form";
import CustomButton from "../../../Common/CustomButton";
import HotelFormIcon from "../../../../icons/HotelFormIcon";
import HotelInput from "../../../form/HotelInput";
import HotelTraveler from "../../../form/HotelTraveler";
import { useNavigate } from "react-router-dom";
import { locations } from "../../../../data/locations";

const HotelForm = ({ width, marginTop, marginBottom }) => {
      const [loading, setLoading] = useState(false);
      const [room, setRoom] = useState(1);
      const [children, setChildren] = useState(0);
      const [adult, setAdult] = useState(1);
      const [childrenAges, setChildAges] = useState([]);
      const [destination, setDestination] = useState("");
      const [checkoutDate, setCheckoutDate] = useState("");
      const [checkinDate, setCheckinDate] = useState("");

      const navigate = useNavigate();

      const formatDate = (inputDate) => {
            const dateObject = new Date(inputDate);

            // Check if the date is valid before proceeding
            if (isNaN(dateObject.getTime())) {
                  console.error("Invalid date");
                  return null;
            }

            // Get individual date components
            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, "0");
            const day = String(dateObject.getDate()).padStart(2, "0");

            // Format the date as "YYYY-MM-DD"
            const formattedDate = `${year}-${month}-${day}`;

            return formattedDate;
      };

      useEffect(() => {
            setCheckinDate(formatDate(getCurrentDate()));
      }, []);

      const handleCheckout = (event) => {
            setCheckoutDate(formatDate(event.target.value));
      };

      const handleCheckin = (event) => {
            setCheckinDate(formatDate(event.target.value));
      };

      const handleSubmit = (e) => {
            e.preventDefault();

            setLoading(true);
            const data = {
                  destination: destination,
                  checkinDate: checkinDate,
                  checkoutDate: checkoutDate,
                  adult: adult,
                  children: children,
                  room: room,
            };

            const queryString = Object.entries(data)
                  .map(
                        ([key, value]) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                    value
                              )}`
                  )
                  .join("&");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/hotel/search?${queryString}`);
            }, 3000);
      };

      return (
            <SearchFormWrapper
                  width={width}
                  marginBottom={marginBottom}
                  marginTop={marginTop}
                  flex="flex"
            >
                  <Form onSubmit={handleSubmit}>
                        <HotelInput
                              controlId="formName"
                              label="Enter City or Hotel"
                              type="text"
                              name={"destination"}
                              placeholder="Jaipur (JAI)"
                              width="30%"
                              options={locations}
                              state={true}
                              required={true}
                              destination={destination}
                              setDestination={setDestination}
                              icon={<HotelFormIcon />}
                              svg={true}
                        />
                        <FormItem
                              controlId="formName"
                              label="Check In"
                              type="date"
                              name="depart"
                              value={checkinDate}
                              onChange={handleCheckin}
                              width="15%"
                              state={true}
                              required={true}
                        />
                        <FormItem
                              controlId="formName"
                              label="Check Out"
                              type="date"
                              name="depart"
                              value={checkoutDate}
                              onChange={handleCheckout}
                              width="15%"
                              state={true}
                              required={true}
                        />
                        <HotelTraveler
                              label="Rooms & Guest"
                              room={room}
                              setRoom={setRoom}
                              adult={adult}
                              setAdult={setAdult}
                              children={children}
                              setChildren={setChildren}
                              state={true}
                        />

                        <SearchFormAction>
                              <label style={{ visibility: "hidden" }}>
                                    Submit
                              </label>
                              <CustomButton
                                    type={"submit"}
                                    className="mt-3"
                                    title={"Search "}
                                    loading={loading}
                              />
                        </SearchFormAction>
                  </Form>
            </SearchFormWrapper>
      );
};

export default HotelForm;
