import React from "react";
import { HotelAccordionContainer, HotelAccordionHeading } from "./style";
import { Accordion, Col, Row } from "react-bootstrap";
import { HotelHeading } from "../styles";

const HotelAccodion = ({ description }) => {
      return (
            <>
                  <HotelAccordionContainer>
                        <HotelAccordionHeading className="mt-4 mb-4"></HotelAccordionHeading>
                        <Accordion as={Row} className="border-none g-3">
                              <Col lg={4}>
                                    <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                                Rooms
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {description?.rooms}
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                              <Col lg={4}>
                                    <Accordion.Item eventKey="2">
                                          <Accordion.Header>
                                                Dinning
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {description?.dining}
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                              <Col lg={4}>
                                    <Accordion.Item eventKey="3">
                                          <Accordion.Header>
                                                Business Amenities
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {
                                                      description?.business_amenities
                                                }
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                              <Col lg={4}>
                                    <Accordion.Item eventKey="4">
                                          <Accordion.Header>
                                                Location
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {description?.location}
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                              <Col lg={4}>
                                    <Accordion.Item eventKey="5">
                                          <Accordion.Header>
                                                Attractions
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {description?.attractions}
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                              <Col lg={4}>
                                    <Accordion.Item eventKey="6">
                                          <Accordion.Header>
                                                Headline
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {description?.headline}
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                              <Col lg={4}>
                                    <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                                Spoken Language
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                {description?.spoken_languages}
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Col>
                        </Accordion>
                  </HotelAccordionContainer>
            </>
      );
};

export default HotelAccodion;
