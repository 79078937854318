import React from "react";
import {
      IndicatorContainer,
      IndicatorItemIcon,
      IndicatorItemTitle,
} from "./styles";
import {
      Path,
      Svg,
      SvgArrow,
      SvgContainer,
      SvgWrapper,
} from "../../../../frontend/register/styles";

const IndicatorItem = ({
      icon,
      title,
      showIcon,
      animate,
      solid,
      visibleBlock,
      name,
}) => {
      return (
            <>
                  <IndicatorContainer>
                        <IndicatorItemIcon
                              className="d-flex align-items-center justify-content-center mb-3"
                              show={visibleBlock === name}
                        >
                              {icon}
                        </IndicatorItemIcon>
                        <IndicatorItemTitle
                              show={visibleBlock === name}
                              className="text-center"
                        >
                              {title}
                        </IndicatorItemTitle>
                        {showIcon && (
                              <SvgWrapper>
                                    <SvgContainer>
                                          <Svg xmlns="http://www.w3.org/2000/svg">
                                                <Path
                                                      d="M20,150 C100,100 200,100 280,150" // Less pronounced S-curve path
                                                      animate={animate || solid}
                                                />
                                          </Svg>
                                          <SvgArrow>
                                                <span className="material-symbols-outlined">
                                                      chevron_right
                                                </span>
                                          </SvgArrow>
                                    </SvgContainer>
                              </SvgWrapper>
                        )}
                  </IndicatorContainer>
            </>
      );
};

export default IndicatorItem;
