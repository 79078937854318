import React, { useEffect, useState } from "react";
import {
      FlightAirline,
      FlightAirlineTime,
      FlightAirlineTitle,
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
      FlightDetailTitle,
} from "../FlightDetails/style";
import axios from "axios";
import { format } from "date-fns";
import { Spinner } from "react-bootstrap";

const FlightFareRule = ({ fareId, searchDetail }) => {
      const [fareDetails, setFareDetails] = useState(false);
      const [loading, setLoading] = useState(false);

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE - dd MMM yyyy");
      };

      const loadData = async () => {
            setLoading(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/fms/v1/farerule`,
                        {
                              id: fareId?.id,
                              flowType: "SEARCH",
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setFareDetails(response.data?.fareRule);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [fareId]);

      // const farePrice
      const splitDetails = (policyString) => {
            const policyList = policyString.split("__nls__");
            return policyList;
      };

      return (
            <>
                  <FlightDetailContainer>
                        <FlightDetailCategory>{`${searchDetail?.leavingFrom} - ${searchDetail?.destination}`}</FlightDetailCategory>
                        <FlightDetailInfo>
                              <FlightDetailTitle>
                                    Date Change{" "}
                                    <span>
                                          {convertDate(
                                                searchDetail?.departDate
                                          )}
                                    </span>
                              </FlightDetailTitle>
                              {!loading ? (
                                    <>
                                          {fareDetails ? (
                                                <>
                                                      <FlightDetailAirline>
                                                            <FlightAirlineTime width="25%">
                                                                  <div>
                                                                        Type
                                                                  </div>
                                                                  <span>
                                                                        Date
                                                                        Change
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightAirline width="20%">
                                                                  <FlightAirlineTitle>
                                                                        <div>
                                                                              Amount
                                                                        </div>
                                                                        <span>
                                                                              {fareDetails[
                                                                                    `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                              ]
                                                                                    ?.fr
                                                                                    ?.DATECHANGE
                                                                                    ?.DEFAULT
                                                                                    ?.amount ||
                                                                                    fareDetails[
                                                                                          `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                    ]
                                                                                          ?.fr
                                                                                          ?.DATECHANGE
                                                                                          ?.BEFORE_DEPARTURE
                                                                                          ?.amount}
                                                                        </span>
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime width="55%">
                                                                  <div>
                                                                        Policy
                                                                  </div>
                                                                  <span>
                                                                        {fareDetails[
                                                                              `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                        ]?.fr
                                                                              ?.DATECHANGE
                                                                              ?.DEFAULT
                                                                              ?.policyInfo
                                                                              ? splitDetails(
                                                                                      fareDetails[
                                                                                            `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                      ]
                                                                                            ?.fr
                                                                                            ?.DATECHANGE
                                                                                            ?.DEFAULT
                                                                                            ?.policyInfo
                                                                                )?.map(
                                                                                      (
                                                                                            policy,
                                                                                            index
                                                                                      ) =>
                                                                                            policy && (
                                                                                                  <li
                                                                                                        key={
                                                                                                              index
                                                                                                        }
                                                                                                  >
                                                                                                        {
                                                                                                              policy
                                                                                                        }
                                                                                                  </li>
                                                                                            )
                                                                                )
                                                                              : fareDetails[
                                                                                      `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                ]
                                                                                      ?.fr
                                                                                      ?.DATECHANGE
                                                                                      ?.BEFORE_DEPARTURE
                                                                                      ?.policyInfo}
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                      <FlightDetailAirline marginTop="1.5rem">
                                                            <FlightAirlineTime width="25%">
                                                                  <span>
                                                                        CANCELLATION
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightAirline width="20%">
                                                                  <FlightAirlineTitle>
                                                                        <span>
                                                                              {fareDetails[
                                                                                    `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                              ]
                                                                                    ?.fr
                                                                                    ?.CANCELLATION
                                                                                    ?.DEFAULT
                                                                                    ?.amount ||
                                                                                    fareDetails[
                                                                                          `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                    ]
                                                                                          ?.fr
                                                                                          ?.CANCELLATION
                                                                                          ?.BEFORE_DEPARTURE
                                                                                          ?.amount}
                                                                        </span>
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime width="55%">
                                                                  <span>
                                                                        {fareDetails[
                                                                              `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                        ]?.fr
                                                                              ?.CANCELLATION
                                                                              ?.DEFAULT
                                                                              ?.policyInfo
                                                                              ? splitDetails(
                                                                                      fareDetails[
                                                                                            `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                      ]
                                                                                            ?.fr
                                                                                            ?.CANCELLATION
                                                                                            ?.DEFAULT
                                                                                            ?.policyInfo
                                                                                )?.map(
                                                                                      (
                                                                                            policy,
                                                                                            index
                                                                                      ) =>
                                                                                            policy && (
                                                                                                  <li
                                                                                                        key={
                                                                                                              index
                                                                                                        }
                                                                                                  >
                                                                                                        {
                                                                                                              policy
                                                                                                        }
                                                                                                  </li>
                                                                                            )
                                                                                )
                                                                              : fareDetails[
                                                                                      `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                ]
                                                                                      ?.fr
                                                                                      ?.CANCELLATION
                                                                                      ?.BEFORE_DEPARTURE
                                                                                      ?.policyInfo}
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                </>
                                          ) : (
                                                <>
                                                      <FlightDetailAirline>
                                                            <FlightAirlineTime width="100%">
                                                                  <span className="text-danger">
                                                                        Keys
                                                                        Passed
                                                                        in the
                                                                        request
                                                                        is
                                                                        already
                                                                        expired.
                                                                        Please
                                                                        pass
                                                                        valid
                                                                        keys
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                </>
                                          )}
                                    </>
                              ) : (
                                    <>
                                          <div className="w-100 text-center mb-3">
                                                <Spinner />
                                          </div>
                                    </>
                              )}
                        </FlightDetailInfo>
                  </FlightDetailContainer>
            </>
      );
};

export default FlightFareRule;
