import React, { useState } from "react";
import { CustomFileInputContainer } from "../CustomFileInput/styles";
import AttachFileIcon from "../../../icons/AttachFileIcon";
import { CommonLineClamp } from "../../../styles/components/common/CommonStyle";
import { FileInputWrapper } from "./style";
import { Col, FormLabel } from "react-bootstrap";
import { LoginFileInput } from "../../../frontend/login/styles";

const FileInput = ({ placeholder, handleFile, title, required, classes }) => {
      const [fileName, setFileName] = useState("");

      const handleFileChange = (event) => {
            if (event.target.files.length > 0) {
                  handleFile(event.target.files[0]);
                  setFileName(event.target.files[0].name);
            } else {
                  setFileName("");
            }
      };

      return (
            <>
                  <Col lg={classes || 4}>
                        <FormLabel>
                              {title}{" "}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </FormLabel>
                        <LoginFileInput className="ps-2 ">
                              <LoginFileInput>
                                    <CustomFileInputContainer className="w-100">
                                          <CommonLineClamp width={"100%"}>
                                                <FileInputWrapper className="file-input-wrapper w-100">
                                                      <input
                                                            type="file"
                                                            onChange={
                                                                  handleFileChange
                                                            }
                                                      />
                                                      <label
                                                            htmlFor="file-input"
                                                            className="custom-button d-flex align-items-center gap-1 w-100"
                                                      >
                                                            <AttachFileIcon />
                                                            {fileName ||
                                                                  placeholder}
                                                      </label>
                                                </FileInputWrapper>
                                          </CommonLineClamp>
                                    </CustomFileInputContainer>
                              </LoginFileInput>
                        </LoginFileInput>
                  </Col>
            </>
      );
};

export default FileInput;
