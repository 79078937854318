import React from "react";
import { HotelHeading } from "./styles";

const HotelOptional = ({ item }) => {
      const optional = item?.["Optional"];
      const parseOptionalFees = (items) => {
            return items.split("     ").map((item) => item.trim());
      };

      const fees = optional
            ? parseOptionalFees(optional)
            : parseOptionalFees(item?.["Mandatory"]);

      return (
            <>
                  <div>
                        <HotelHeading>FEES</HotelHeading>
                        {fees.map(
                              (fee, index) =>
                                    fee !== "" && <li key={index}>{fee}</li>
                        )}
                  </div>
            </>
      );
};

export default HotelOptional;
