import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../dashboard/styles";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FlightConfirmationHeader } from "./style";
import { FlightDetailContainer } from "../../components/Dashboard/FlightDetails/style";
import { Col, Row } from "react-bootstrap";
import { FlightReviewContent } from "../flight_reviews/style";
import {
      FlightReviewHeader,
      FlightReviewInfo,
} from "../../components/Frontend/flight_reviews/FlightReviewDetail/style";

const FlightConfirmation = () => {
      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();
      const [order, setOrder] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                        {
                              bookingId: bookingId,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.status?.success === true) {
                              setOrder(response.data.order);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardContainer flexDirection="column" padding="120px">
                        <FlightConfirmationHeader>
                              <div className="confirmation__header-img">
                                    <img src="/images/booking.png" alt="" />
                              </div>
                              <div className="confirmation__header-title text-success">
                                    Booking Success
                                    <div className="confirmation__header-subtitle text-muted">
                                          Booking Id: {bookingId}
                                    </div>
                              </div>
                        </FlightConfirmationHeader>
                        <Row>
                              <Col lg={7}>
                                    <FlightDetailContainer>
                                          <FlightReviewContent>
                                                <FlightReviewHeader>
                                                      Contact Details
                                                </FlightReviewHeader>
                                                <FlightReviewInfo>
                                                      <ul>
                                                            <li>Email: {order?.deliveryInfo?.emails}</li>
                                                            <li>Mobile: {order?.deliveryInfo?.contacts}</li>
                                                      </ul>
                                                </FlightReviewInfo>
                                          </FlightReviewContent>
                                    </FlightDetailContainer>
                              </Col>
                        </Row>
                  </DashboardContainer>
            </>
      );
};

export default FlightConfirmation;
