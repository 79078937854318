import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../dashboard/styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import { MapIcon } from "../../icons";
import { Col, Row } from "react-bootstrap";
import HotelOptional from "./HotelOptional";
import HotelRoom from "./HotelRoom";

import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import { HotelImageSlider } from "../../components/sliders";
import {
      HotelAddress,
      HotelAddressIcon,
      HotelFeatureImage,
      HotelHeading,
      LocationMap,
} from "./styles";
import HotelAccodion from "./HotelAccodion";

const location = {
      address: "1600 Amphitheatre Parkway, Mountain View, california.",
      lat: 37.42216,
      lng: -122.08427,
};

const LocationPin = ({ title, address }) => (
      <div className="pin">
            <Icon icon={locationIcon} className="pin-icon" />
            <div className="pin-text">
                  <h2>{title}</h2>
                  <p>{address}</p>
            </div>
      </div>
);

const HotelDetail = () => {
      const { hotelId } = useParams();

      const [loading, setLoading] = useState(false);
      const [hotel, setHotel] = useState([]);
      const [description, setDescription] = useState([]);
      const [largeImages, setLargeImages] = useState([]);

      const handleData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotelDetail-search`,
                        {
                              id: hotelId,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              setHotel(response.data.hotel);
                              if (response.data.hotel.img) {
                                    const responseImages =
                                          response.data.hotel.img.filter(
                                                (item) =>
                                                      (item.sz === "XL" ||
                                                            item.sz ===
                                                                  "XXL") &&
                                                      item
                                          );
                                    setLargeImages(responseImages);
                              }
                              setDescription(
                                    JSON.parse(response.data.hotel.des)
                              );
                        }, 1000);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            handleData();
      }, []);

      return (
            <>
                  <DashboardContainer padding="120px">
                        <Row>
                              <Col lg={12}>
                                    <div>
                                          <HotelHeading marginBottom="0px">
                                                {hotel?.name}
                                          </HotelHeading>
                                          <HotelAddress>
                                                <HotelAddressIcon>
                                                      <Icon icon="material-symbols:star" />
                                                      {hotel?.rt} Start Hotel
                                                </HotelAddressIcon>
                                                <HotelAddressIcon>
                                                      <MapIcon />
                                                      {hotel?.ad?.adr}
                                                      {hotel?.ad?.state?.name &&
                                                            `, ${hotel?.ad?.state?.name}`}
                                                      {hotel?.ad?.city?.name &&
                                                            `, ${hotel?.ad?.city?.name}`}
                                                </HotelAddressIcon>
                                          </HotelAddress>
                                    </div>
                              </Col>
                              <Col lg={12}>
                                    <Row>
                                          <Col lg={8}>
                                                <HotelImageSlider
                                                      images={largeImages}
                                                      title={hotel?.name}
                                                />
                                          </Col>
                                          <Col lg={4}>
                                                <HotelFeatureImage>
                                                      <img
                                                            src={
                                                                  largeImages[1]
                                                                        ?.url
                                                            }
                                                            alt=""
                                                            className="w-100"
                                                      />
                                                </HotelFeatureImage>
                                                <HotelFeatureImage>
                                                      <img
                                                            src={
                                                                  largeImages[2]
                                                                        ?.url
                                                            }
                                                            alt=""
                                                            className="w-100"
                                                      />
                                                </HotelFeatureImage>
                                          </Col>
                                          <Col lg={12}>
                                                <HotelHeading>
                                                      About Hotel
                                                </HotelHeading>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: description?.amenities,
                                                      }}
                                                ></div>
                                                <HotelAccodion
                                                      description={description}
                                                />
                                          </Col>
                                    </Row>
                                    <div>
                                          <div>
                                                <HotelHeading>
                                                      Popular Amenities
                                                </HotelHeading>
                                                <ul className="row">
                                                      {hotel?.fl?.map(
                                                            (item, index) => (
                                                                  <li className="col-lg-4">
                                                                        {item}
                                                                  </li>
                                                            )
                                                      )}
                                                </ul>
                                          </div>

                                          <div>
                                                <HotelHeading>
                                                      Rooms
                                                </HotelHeading>
                                                <div>
                                                      <HotelRoom
                                                            items={hotel?.ops}
                                                            hotel={hotel}
                                                      />
                                                </div>
                                          </div>
                                          <div>
                                                {hotel?.inst?.length > 0 &&
                                                      hotel?.inst?.map(
                                                            (item, index) =>
                                                                  item?.type ===
                                                                  "FEES" ? (
                                                                        <HotelOptional
                                                                              item={JSON.parse(
                                                                                    item?.msg
                                                                              )}
                                                                        />
                                                                  ) : (
                                                                        <div></div>
                                                                  )
                                                      )}
                                          </div>
                                          <HotelHeading>Location</HotelHeading>
                                          <LocationMap>
                                                <GoogleMapReact
                                                      bootstrapURLKeys={{
                                                            key: "AIzaSyD9hKUXIQsHlb4SuiAktynQr8cHej3Ix5c",
                                                      }}
                                                      defaultCenter={location}
                                                      defaultZoom={15}
                                                >
                                                      <LocationPin
                                                            lat={location.lat}
                                                            lng={location.lng}
                                                            title={hotel?.name}
                                                            address={`${
                                                                  hotel?.ad?.adr
                                                            }${
                                                                  hotel?.ad
                                                                        ?.state
                                                                        ?.name &&
                                                                  ", "
                                                            }${
                                                                  hotel?.ad
                                                                        ?.state
                                                                        ?.name &&
                                                                  hotel?.ad
                                                                        ?.state
                                                                        ?.name
                                                            }${
                                                                  hotel?.ad
                                                                        ?.city
                                                                        ?.name &&
                                                                  ", "
                                                            }${
                                                                  hotel?.ad
                                                                        ?.city
                                                                        ?.name &&
                                                                  hotel?.ad
                                                                        ?.city
                                                                        ?.name
                                                            }`}
                                                      />
                                                </GoogleMapReact>
                                          </LocationMap>
                                          <div>
                                                <HotelHeading>
                                                      All Policies
                                                </HotelHeading>
                                                {hotel?.inst?.map(
                                                      (item, index) =>
                                                            item?.type ===
                                                            "CHECKIN_INSTRUCTIONS" ? (
                                                                  <></>
                                                            ) : (
                                                                  // <HotelCheckin
                                                                  //       item={JSON.parse(
                                                                  //             item?.msg
                                                                  //       )}
                                                                  // />
                                                                  <div>
                                                                        <div>
                                                                              {
                                                                                    JSON.parse(
                                                                                          item.msg
                                                                                    )
                                                                                          ?.know_before_you_go
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            )
                                                )}
                                          </div>
                                    </div>
                              </Col>
                        </Row>
                  </DashboardContainer>
            </>
      );
};

export default HotelDetail;
