import { act, useState } from "react";
import {
      ToggleButtonWrapper,
      ToogleButtonContainer,
      ToogleText,
} from "./styles";
import Test from "../../Frontend/home/TravelerBox/Test";

const ToggleButton = ({ active, setActive, margin }) => {
      return (
            <>
                  <ToogleButtonContainer margin={margin}>
                        <ToogleText>Round Trip? </ToogleText>
                        <Test isActive={active} setIsActive={setActive} />
                  </ToogleButtonContainer>
            </>
      );
};

export default ToggleButton;
