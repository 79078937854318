import React, { useContext, useState } from "react";
import { LoginFormSection, LoginInput } from "../styles";
import { Col, FormControl, Row } from "react-bootstrap";
import { FormAdditionalHeader } from "../../../components/Frontend/home/TravelerBox/styles";
import CustomSelect from "../../../components/custom/CustomSelect";
import PhoneIcon from "../../../icons/PhoneIcon";
import CommonButton from "../../../components/Common/CommonButton";
import NonVisibilityIcon from "../../../icons/NonVisibilityIcon";
import VisbilityIcon from "../../../icons/VisibilityIcon";
import { LockIcon, MailIcon, UserIcon } from "../../../icons";
import { FormContext } from "../../../context/FormContext";
import { useNavigate } from "react-router-dom";
const options = [
      { value: "India +91", label: "India +91" },
      { value: "France +33", label: "France +33" },
      { value: "Nepal +97", label: "Nepal +97" },
];

const SignupForm = ({ regFormRef, activeForm }) => {
      const [loading, setLoading] = useState(false);
      const { formData, setFormData } = useContext(FormContext);
      const [showRegisterPassword, setShowRegsiterPassword] = useState(false);
      const navigate = useNavigate();

      const handleSelect = (option) => {
            setFormData({
                  ...formData,
                  ["country"]: option.value,
            });
      };

      const handleRegisterChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                  ...formData,
                  [name]: value,
            });
      };
      const handleRegisterForm = (e) => {
            e.preventDefault();
            setLoading(true);

            setTimeout(() => {
                  setLoading(false);
                  navigate("/register");
            }, 3000);
      };

      return (
            <>
                  <LoginFormSection
                        onSubmit={handleRegisterForm}
                        ref={regFormRef}
                        style={{
                              transform:
                                    activeForm === "register"
                                          ? "translateX(-420px)"
                                          : "translateX(0px)",
                        }}
                        className="register col-lg-12"
                  >
                        <Row className="px-0 g-3">
                              <Col lg={4}>
                                    <FormAdditionalHeader className="px-0   d-flex align-items-center justify-content-between ">
                                          <CustomSelect
                                                options={options}
                                                onSelect={handleSelect}
                                                label={"India +91"}
                                          />
                                    </FormAdditionalHeader>
                              </Col>
                              <Col lg={8}>
                                    <LoginInput className="ps-2 ">
                                          <PhoneIcon />
                                          <FormControl
                                                type="text"
                                                onChange={handleRegisterChange}
                                                placeholder="Phone number"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                          />
                                    </LoginInput>
                              </Col>

                              <Col lg={6}>
                                    <LoginInput className="ps-2 ">
                                          <UserIcon />
                                          <FormControl
                                                type="text"
                                                onChange={handleRegisterChange}
                                                placeholder="First name"
                                                name="firstName"
                                                value={formData.firstName}
                                          />
                                    </LoginInput>
                              </Col>
                              <Col lg={6}>
                                    <LoginInput className="ps-2 ">
                                          <UserIcon />
                                          <FormControl
                                                type="text"
                                                onChange={handleRegisterChange}
                                                placeholder="Last name"
                                                name="lastName"
                                                value={formData.lastName}
                                          />
                                    </LoginInput>
                              </Col>
                              <Col lg={12}>
                                    <LoginInput className="ps-2 ">
                                          <MailIcon />
                                          <FormControl
                                                type="email"
                                                onChange={handleRegisterChange}
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                          />
                                    </LoginInput>
                              </Col>
                              <Col lg={12}>
                                    <LoginInput className="px-2 ">
                                          <LockIcon />
                                          <FormControl
                                                name="password"
                                                type={
                                                      !showRegisterPassword
                                                            ? "password"
                                                            : "text"
                                                }
                                                onChange={handleRegisterChange}
                                                placeholder="Password"
                                                value={formData.password}
                                          />
                                          <VisbilityIcon
                                                show={showRegisterPassword}
                                                setShowPassword={
                                                      setShowRegsiterPassword
                                                }
                                          />
                                          <NonVisibilityIcon
                                                show={showRegisterPassword}
                                                setShowPassword={
                                                      setShowRegsiterPassword
                                                }
                                          />
                                    </LoginInput>
                              </Col>
                        </Row>

                        <Col
                              lg={12}
                              className="p-0  mt-4 d-flex align-items-center justify-content-center"
                        >
                              <CommonButton
                                    title={"Get Start"}
                                    button={true}
                                    loading={loading}
                                    type={"submit"}
                              />
                        </Col>
                  </LoginFormSection>
            </>
      );
};

export default SignupForm;
