import React, { useEffect, useState } from "react";
import {
      FlightTripAirline,
      FlightTripAirlineTime,
      FlightTripAirlineTitle,
      FlightTripDetailAirline,
      FlightTripDetailCategory,
      FlightTripDetailContainer,
      FlightTripDetailInfo,
      FlightTripDetailTitle,
} from "../FlightTripDetails/style";
import axios from "axios";
import { format } from "date-fns";
import { Spinner } from "react-bootstrap";

const FlightTripRule = ({ fareId, searchDetail }) => {
      const [fareDetails, setFareDetails] = useState(false);
      const [loading, setLoading] = useState(false);

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE - dd MMM yyyy");
      };

      const loadData = async () => {
            setLoading(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/fms/v1/farerule`,
                        {
                              id: fareId?.id,
                              flowType: "SEARCH",
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setFareDetails(response.data?.fareRule);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [fareId]);

      // const farePrice
      const splitDetails = (policyString) => {
            const policyList = policyString.split("__nls__");
            return policyList;
      };

      return (
            <>
                  <FlightTripDetailContainer>
                        <FlightTripDetailCategory>{`${searchDetail?.leavingFrom} - ${searchDetail?.destination}`}</FlightTripDetailCategory>
                        <FlightTripDetailInfo>
                              <FlightTripDetailTitle>
                                    Date Change{" "}
                                    <span>
                                          {convertDate(
                                                searchDetail?.departDate
                                          )}
                                    </span>
                              </FlightTripDetailTitle>
                              {!loading ? (
                                    <>
                                          <FlightTripDetailAirline>
                                                {fareDetails ? (
                                                      <>
                                                            <FlightTripAirlineTime width="25%">
                                                                  <div>
                                                                        Type
                                                                  </div>
                                                                  <span>
                                                                        Date
                                                                        Change
                                                                  </span>
                                                            </FlightTripAirlineTime>
                                                            <FlightTripAirline width="20%">
                                                                  <FlightTripAirlineTitle>
                                                                        <div>
                                                                              Amount
                                                                        </div>
                                                                        <span>
                                                                              {fareDetails[
                                                                                    `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                              ]
                                                                                    ?.fr
                                                                                    ?.DATECHANGE
                                                                                    ?.DEFAULT
                                                                                    ?.amount ||
                                                                                    fareDetails[
                                                                                          `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                    ]
                                                                                          ?.fr
                                                                                          ?.DATECHANGE
                                                                                          ?.BEFORE_DEPARTURE
                                                                                          ?.amount}
                                                                        </span>
                                                                  </FlightTripAirlineTitle>
                                                            </FlightTripAirline>
                                                            <FlightTripAirlineTime width="55%">
                                                                  <div>
                                                                        Policy
                                                                  </div>
                                                                  <span>
                                                                        {fareDetails[
                                                                              `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                        ]?.fr
                                                                              ?.DATECHANGE
                                                                              ?.DEFAULT
                                                                              ?.policyInfo
                                                                              ? splitDetails(
                                                                                      fareDetails[
                                                                                            `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                      ]
                                                                                            ?.fr
                                                                                            ?.DATECHANGE
                                                                                            ?.DEFAULT
                                                                                            ?.policyInfo
                                                                                )?.map(
                                                                                      (
                                                                                            policy,
                                                                                            index
                                                                                      ) =>
                                                                                            policy && (
                                                                                                  <li
                                                                                                        key={
                                                                                                              index
                                                                                                        }
                                                                                                  >
                                                                                                        {
                                                                                                              policy
                                                                                                        }
                                                                                                  </li>
                                                                                            )
                                                                                )
                                                                              : fareDetails[
                                                                                      `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                ]
                                                                                      ?.fr
                                                                                      ?.DATECHANGE
                                                                                      ?.BEFORE_DEPARTURE
                                                                                      ?.policyInfo}
                                                                  </span>
                                                            </FlightTripAirlineTime>
                                                      </>
                                                ) : (
                                                      <>
                                                            <FlightTripAirlineTime width="100%">
                                                                  <span className="text-danger">
                                                                        Keys
                                                                        Passed
                                                                        in the
                                                                        request
                                                                        is
                                                                        already
                                                                        expired.
                                                                        Please
                                                                        pass
                                                                        valid
                                                                        keys
                                                                  </span>
                                                            </FlightTripAirlineTime>
                                                      </>
                                                )}
                                          </FlightTripDetailAirline>
                                          {fareDetails && (
                                                <FlightTripDetailAirline marginTop="1.5rem">
                                                      <FlightTripAirlineTime width="25%">
                                                            <span>
                                                                  CANCELLATION
                                                            </span>
                                                      </FlightTripAirlineTime>
                                                      <FlightTripAirline width="20%">
                                                            <FlightTripAirlineTitle>
                                                                  <span>
                                                                        {fareDetails[
                                                                              `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                        ]?.fr
                                                                              ?.CANCELLATION
                                                                              ?.DEFAULT
                                                                              ?.amount ||
                                                                              fareDetails[
                                                                                    `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                              ]
                                                                                    ?.fr
                                                                                    ?.CANCELLATION
                                                                                    ?.BEFORE_DEPARTURE
                                                                                    ?.amount}
                                                                  </span>
                                                            </FlightTripAirlineTitle>
                                                      </FlightTripAirline>
                                                      <FlightTripAirlineTime width="55%">
                                                            <span>
                                                                  {fareDetails[
                                                                        `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                  ]?.fr
                                                                        ?.CANCELLATION
                                                                        ?.DEFAULT
                                                                        ?.policyInfo
                                                                        ? splitDetails(
                                                                                fareDetails[
                                                                                      `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                                ]
                                                                                      ?.fr
                                                                                      ?.CANCELLATION
                                                                                      ?.DEFAULT
                                                                                      ?.policyInfo
                                                                          )?.map(
                                                                                (
                                                                                      policy,
                                                                                      index
                                                                                ) =>
                                                                                      policy && (
                                                                                            <li
                                                                                                  key={
                                                                                                        index
                                                                                                  }
                                                                                            >
                                                                                                  {
                                                                                                        policy
                                                                                                  }
                                                                                            </li>
                                                                                      )
                                                                          )
                                                                        : fareDetails[
                                                                                `${searchDetail?.leavingFrom}-${searchDetail?.destination}`
                                                                          ]?.fr
                                                                                ?.CANCELLATION
                                                                                ?.BEFORE_DEPARTURE
                                                                                ?.policyInfo}
                                                            </span>
                                                      </FlightTripAirlineTime>
                                                </FlightTripDetailAirline>
                                          )}
                                    </>
                              ) : (
                                    <>
                                          <div className="w-100 text-center mb-3">
                                                <Spinner />
                                          </div>
                                    </>
                              )}
                        </FlightTripDetailInfo>
                  </FlightTripDetailContainer>
            </>
      );
};

export default FlightTripRule;
