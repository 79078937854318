import React from "react";

const InfantIcon = ({ color }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.0"
                        width="24px"
                        height="24px"
                        viewBox="0 0 100.000000 100.000000"
                        preserveAspectRatio="xMidYMid meet"
                  >
                        <g
                              transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                              fill={color || "#333"}
                              stroke="none"
                        >
                              <path d="M730 743 c-95 -49 -87 -193 13 -232 105 -40 206 61 166 166 -27 70 -111 101 -179 66z m121 -52 c20 -20 29 -39 29 -61 0 -22 -9 -41 -29 -61 -40 -39 -82 -39 -122 0 -20 20 -29 39 -29 61 0 22 9 41 29 61 40 39 82 39 122 0z" />
                              <path d="M325 630 c-11 -4 -33 -22 -50 -40 -29 -31 -30 -34 -33 -151 l-4 -119 -67 0 c-58 0 -71 -3 -89 -23 -28 -30 -28 -68 1 -95 21 -20 34 -22 138 -22 108 0 117 2 137 23 18 19 22 35 22 90 l0 67 70 0 70 0 0 -31 c0 -17 4 -39 10 -49 13 -25 151 -110 178 -110 44 0 74 56 56 104 -3 8 -28 29 -55 47 l-49 31 0 95 c0 163 -32 193 -205 192 -60 0 -119 -4 -130 -9z m95 -130 c0 -93 -1 -100 -20 -100 -13 0 -20 7 -20 18 0 30 -45 65 -74 60 -23 -5 -26 -2 -26 21 0 60 44 101 108 101 l32 0 0 -100z m171 71 c28 -29 29 -32 29 -135 l0 -106 55 -35 c37 -24 55 -42 55 -56 0 -37 -29 -33 -100 15 l-69 46 -3 77 c-2 61 -6 78 -18 78 -9 0 -16 -11 -18 -27 -3 -24 -8 -28 -33 -28 l-29 0 0 100 0 100 51 0 c43 0 55 -5 80 -29z m-256 -241 l0 -105 -115 0 -115 0 0 25 c0 24 2 25 85 30 l85 5 3 65 c3 83 7 92 34 88 22 -3 23 -7 23 -108z" />
                        </g>
                  </svg>
            </>
      );
};

export default InfantIcon;
