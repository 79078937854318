import React, { useEffect, useRef, useState } from "react";
import {
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
      FormInfo,
      FormWrapper,
} from "./styles";

import { Form } from "react-bootstrap";
import CustomSelect from "../../../custom/CustomSelect";

const TravelerBox = ({ data, setData, type, label, width }) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();

      const handleClick = () => {
            setShow(true);
      };

      const handleOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener("click", handleOutside);

            return () => {
                  document.removeEventListener("click", handleOutside);
            };
      }, []);

      const increment = (index, key) => {
            const newData = [...data];
            if (key === "infants") {
                  if (parseInt(newData[index][key]) < 1) {
                        newData[index][key] = (
                              parseInt(newData[index][key]) + 1
                        ).toString();
                        setData(newData);
                  }
            } else {
                  if (parseInt(newData[index][key]) < 5) {
                        newData[index][key] = (
                              parseInt(newData[index][key]) + 1
                        ).toString();
                        setData(newData);
                  }
            }
      };

      const decrement = (index, key) => {
            const newData = [...data];
            if (parseInt(newData[index][key]) > 0) {
                  newData[index][key] = (
                        parseInt(newData[index][key]) - 1
                  ).toString();
                  setData(newData);
            }
      };

      const getTotalTravelers = () => {
            return data.reduce(
                  (total, traveler) =>
                        total +
                        parseInt(traveler.adults) +
                        parseInt(traveler.children) +
                        parseInt(traveler.infants),
                  0
            );
      };
      const getTravelClasses = () => {
            return data.map((traveler) => traveler.class);
      };
      const options = [
            { value: "Economy", label: "Economy" },
            { value: "P.Economy", label: "P.Economy" },
            { value: "Business", label: "Business" },
            { value: "First", label: "First" },
      ];

      const handleSelect = (option) => {
            const newData = [...data];

            newData[0].class = option.value;
            setData(newData);
      };
      return (
            <>
                  <FormWrapper onClick={handleClick} ref={divRef} width={width}>
                        {label && <Form.Label>{label}</Form.Label>}

                        <FormInfo>
                              {getTotalTravelers()} {getTravelClasses()}
                              <span style={{ fontSize: "13px" }}></span>
                        </FormInfo>
                        <FormAdditional className={show ? "show" : ""}>
                              {data.map((traveler, index) => (
                                    <div key={index}>
                                          <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                                <FormAdditionalTitle>
                                                      Adults
                                                      <span
                                                            className="d-block "
                                                            style={{
                                                                  fontSize: "9px",
                                                                  color: "#777777",
                                                            }}
                                                      >
                                                            (+12year)
                                                      </span>
                                                </FormAdditionalTitle>
                                                <FormAdditionalCounter>
                                                      <FormAdditionalCounterIcon
                                                            className="align-items-center justify-content-center substract"
                                                            onClick={() =>
                                                                  decrement(
                                                                        index,
                                                                        "adults"
                                                                  )
                                                            }
                                                      >
                                                            <span>-</span>
                                                      </FormAdditionalCounterIcon>
                                                      <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                            {parseInt(
                                                                  traveler.adults
                                                            )}
                                                      </FormAdditionalCounterInput>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  increment(
                                                                        index,
                                                                        "adults"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center add"
                                                      >
                                                            <span>+</span>
                                                      </FormAdditionalCounterIcon>
                                                </FormAdditionalCounter>
                                          </FormAdditionalHeader>
                                          <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                                <FormAdditionalTitle>
                                                      Children
                                                      <span
                                                            className="d-block "
                                                            style={{
                                                                  fontSize: "9px",
                                                                  color: "#777777",
                                                            }}
                                                      >
                                                            (2-12 Years)
                                                      </span>
                                                </FormAdditionalTitle>
                                                <FormAdditionalCounter>
                                                      <FormAdditionalCounterIcon
                                                            className="align-items-center justify-content-center substract"
                                                            onClick={() =>
                                                                  decrement(
                                                                        index,
                                                                        "children"
                                                                  )
                                                            }
                                                      >
                                                            <span>-</span>
                                                      </FormAdditionalCounterIcon>
                                                      <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                            {parseInt(
                                                                  traveler.children
                                                            )}
                                                      </FormAdditionalCounterInput>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  increment(
                                                                        index,
                                                                        "children"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center add"
                                                      >
                                                            <span>+</span>
                                                      </FormAdditionalCounterIcon>
                                                </FormAdditionalCounter>
                                          </FormAdditionalHeader>
                                          <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                                <FormAdditionalTitle>
                                                      Infants
                                                      <span
                                                            className="d-block "
                                                            style={{
                                                                  fontSize: "9px",
                                                                  color: "#777777",
                                                            }}
                                                      >
                                                            (+12year)
                                                      </span>
                                                </FormAdditionalTitle>
                                                <FormAdditionalCounter>
                                                      <FormAdditionalCounterIcon
                                                            className="align-items-center justify-content-center substract"
                                                            onClick={() =>
                                                                  decrement(
                                                                        index,
                                                                        "infants"
                                                                  )
                                                            }
                                                      >
                                                            <span>-</span>
                                                      </FormAdditionalCounterIcon>
                                                      <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                            {parseInt(
                                                                  traveler.infants
                                                            )}
                                                      </FormAdditionalCounterInput>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  increment(
                                                                        index,
                                                                        "infants"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center add"
                                                      >
                                                            <span>+</span>
                                                      </FormAdditionalCounterIcon>
                                                </FormAdditionalCounter>
                                          </FormAdditionalHeader>
                                          <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                                <FormAdditionalTitle>
                                                      Class
                                                </FormAdditionalTitle>
                                                <CustomSelect
                                                      options={options}
                                                      onSelect={handleSelect}
                                                      label={"Economy"}
                                                      selectedWidth={"50px"}
                                                />
                                          </FormAdditionalHeader>
                                    </div>
                              ))}
                        </FormAdditional>
                  </FormWrapper>
            </>
      );
};

export default TravelerBox;
