import React from "react";
import { Link } from "react-router-dom";
import { SocialMediaIcon } from "./styles";

const SocialMediaItem = ({ data }) => {
      return (
            <>
                  <SocialMediaIcon
                        to="https://www.facebook.com/aircasa.in"
                        target="_blank"
                        className="icon"
                  >
                        <div className="d-none">aircasa Facebook Account</div>
                        {data.icon}
                  </SocialMediaIcon>
            </>
      );
};

export default SocialMediaItem;
