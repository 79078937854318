import React, { useEffect } from "react";
import { RoundtripLayoutContainer } from "./style";
import FlightLayout from "../FlightLayout";

const RoundtripLayout = ({
      flights,
      netFare,
      fareId,
      setFareId,
      searchDetail,
}) => {
      useEffect(() => {
            setFareId(flights[0]?.totalPriceList[0]);
      }, []);

      return (
            <>
                  <RoundtripLayoutContainer>
                        {flights?.map((item, index) => (
                              <FlightLayout
                                    item={item}
                                    netFare={netFare}
                                    key={index}
                                    fareId={fareId}
                                    setFareId={setFareId}
                                    searchDetail={searchDetail}
                              />
                        ))}
                  </RoundtripLayoutContainer>
            </>
      );
};

export default RoundtripLayout;
