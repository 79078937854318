import React from "react";

const UnoIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined rounded-pill">
                        swap_horiz
                  </span>
            </>
      );
};

export default UnoIcon;
