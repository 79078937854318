import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
      FormContainer,
      FormExtra,
      SearchFormAction,
      SearchFormWrapper,
} from "./styles";
import FormItem from "../../../form";
import UnoButton from "../../../form/UnoButton";
import CustomButton from "../../../Common/CustomButton";
import ToggleButton from "../../../Common/CommonToogle";
import getCurrentDate from "../../../../hooks/CurrentData";
import { useNavigate } from "react-router-dom";
import HotelInput from "../../../form/HotelInput";
import FlightTraveler from "../FlightTraveler";
import axios from "axios";
import toast from "react-hot-toast";

const SearchForm = () => {
      const [leavingFrom, setLeavingFrom] = useState("");
      const [destination, setDestination] = useState("");
      const [depart, setDepart] = useState(getCurrentDate());
      const [returnDate, setReturnDate] = useState("");
      const [adult, setAdult] = useState(1);
      const [children, setChildren] = useState(0);
      const [className, setClassName] = useState({
            value: "ECONOMY",
            label: "Economy",
      });
      const [infants, setInfants] = useState(0);
      const [pft, setPft] = useState("");
      const [nonStop, setNonStop] = useState(false);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const [loadingData, setLoadingData] = useState(false);
      const [destinations, setDestinations] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = (e) => {
            e.preventDefault();
            setLoading(true);

            if (pft) {
                  setInfants(0);
                  setChildren(0);
                  toast.error(`You cannot select ${pft} with child/infant`);
            }

            const data = {
                  leavingFrom: leavingFrom?.code,
                  destination: destination?.code,
                  depart: depart,
                  returnDate: returnDate,
                  adult: adult,
                  children: children,
                  className: className?.value,
                  infants: infants,
                  returnStatus: active,
                  pft: pft,
                  nonStop: nonStop,
            };

            const queryString = Object.entries(data)
                  .map(
                        ([key, value]) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                    value
                              )}`
                  )
                  .join("&");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/search?${queryString}`);
            }, 3000);
      };

      const [active, setActive] = useState(false);
      const [changeDestination, setChangeDestination] = useState(false);

      return (
            <SearchFormWrapper wrap={true}>
                  <ToggleButton active={active} setActive={setActive} />

                  <Form onSubmit={handleSubmit}>
                        <FormContainer>
                              <HotelInput
                                    width="18%"
                                    label="From"
                                    options={destinations}
                                    setDestinations={setDestinations}
                                    required={true}
                                    seletedValue={true}
                                    destination={leavingFrom}
                                    setDestination={setLeavingFrom}
                                    placeholder="New Delhi (DEL)"
                              />
                              <UnoButton
                                    leavingFrom={leavingFrom}
                                    setLeavingFrom={setLeavingFrom}
                                    destination={destination}
                                    setDestination={setDestination}
                                    setChangeDestination={setChangeDestination}
                                    changeDestination={changeDestination}
                              />
                              <HotelInput
                                    width="18%"
                                    label="To"
                                    required={true}
                                    options={destinations}
                                    seletedValue={true}
                                    destination={destination}
                                    setDestinations={setDestinations}
                                    setDestination={setDestination}
                                    placeholder="Jaipur (JAI)"
                              />
                              <FormItem
                                    label="Depart"
                                    type="date"
                                    required={true}
                                    value={depart}
                                    onChange={(event) =>
                                          setDepart(event.target.value)
                                    }
                                    width="13%"
                                    state={true}
                              />
                              <FormItem
                                    label="Return"
                                    type="date"
                                    required={active}
                                    value={returnDate}
                                    onChange={(event) =>
                                          setReturnDate(event.target.value)
                                    }
                                    width="13%"
                                    state={active}
                              />
                              <FlightTraveler
                                    label="Travellers & Class"
                                    children={children}
                                    setChildren={setChildren}
                                    adult={adult}
                                    setAdult={setAdult}
                                    infant={infants}
                                    setInfant={setInfants}
                                    className={className}
                                    setClassName={setClassName}
                                    width="15%"
                                    state={true}
                              />
                              <SearchFormAction>
                                    <label style={{ visibility: "hidden" }}>
                                          Submit
                                    </label>
                                    <CustomButton
                                          type={"submit"}
                                          className="mt-3"
                                          loading={loading}
                                          title={"Search Flights"}
                                    />
                              </SearchFormAction>
                        </FormContainer>
                        <FormExtra
                              justifyContent="space-between"
                              border={`1px dashed`}
                        >
                              <Form.Check
                                    type="checkbox"
                                    label="Non-Stop"
                                    className="mt-2"
                                    onChange={(event) =>
                                          setNonStop(event.target.checked)
                                    }
                              />
                              <FormExtra width="auto">
                                    <Form.Check
                                          type="checkbox"
                                          label="Student"
                                          className="mt-2"
                                          checked={
                                                pft === "STUDENT" ? true : false
                                          }
                                          value="STUDENT"
                                          disabled={
                                                infants !== 0 || children !== 0
                                                      ? true
                                                      : false
                                          }
                                          onChange={(event) =>
                                                setPft(
                                                      event.target.checked
                                                            ? event.target.value
                                                            : ""
                                                )
                                          }
                                    />
                                    <Form.Check
                                          type="checkbox"
                                          label="Senior Citizen"
                                          value="SENIOR_CITIZEN"
                                          className="mt-2"
                                          disabled={
                                                infants !== 0 || children !== 0
                                                      ? true
                                                      : false
                                          }
                                          checked={
                                                pft === "SENIOR_CITIZEN"
                                                      ? true
                                                      : false
                                          }
                                          onChange={(event) =>
                                                setPft(
                                                      event.target.checked
                                                            ? event.target.value
                                                            : ""
                                                )
                                          }
                                    />
                              </FormExtra>
                        </FormExtra>
                  </Form>
            </SearchFormWrapper>
      );
};

export default SearchForm;
