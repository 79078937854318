import React from "react";
import { RoomContainer } from "./style";
import RoomItemBox from "../RoomItemBox";

const HotelRoom = ({ items, hotel }) => {
      return (
            <>
                  <RoomContainer>
                        {items?.map((item, index) => (
                              <RoomItemBox
                                    hotel={hotel}
                                    item={item}
                                    key={index}
                              />
                        ))}
                  </RoomContainer>
            </>
      );
};

export default HotelRoom;
