import React from "react";
import {
      FlightModalButton,
      FlightModalContainer,
      FlightModalContent,
      FlightModalDestination,
      FlightModalIcon,
      FlightModalItem,
      FlightModalOption,
      FlightModalOptions,
} from "./style";
import UnoIcon from "../../icons/UnoIcon";
import { format } from "date-fns";

const FlightModal = ({ searchDetail, searchDetails }) => {
      const totalTraveller = () => {
            return (
                  parseInt(searchDetail?.adult) +
                  parseInt(searchDetail?.children) +
                  parseInt(searchDetail?.infants)
            );
      };

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE dd, MMM");
      };

      return (
            <>
                  <FlightModalContainer>
                        <FlightModalContent>
                              <FlightModalItem width="10%">
                                    <FlightModalOptions>
                                          <div>
                                                {searchDetail?.returnStatus ===
                                                "false"
                                                      ? "One Way"
                                                      : "Round Way"}
                                          </div>
                                          {/* <FlightModalOption>
                                                <li className="selected">
                                                      One Way
                                                </li>
                                                <li>Round Way</li>
                                          </FlightModalOption> */}
                                    </FlightModalOptions>
                              </FlightModalItem>
                              <FlightModalItem width="25%">
                                    <FlightModalDestination>
                                          <div>
                                                {
                                                      searchDetails
                                                            ?.leaving_from?.city
                                                }
                                          </div>
                                    </FlightModalDestination>
                                    <FlightModalDestination
                                          height="24px"
                                          width="10%"
                                    >
                                          <FlightModalIcon>
                                                <UnoIcon />
                                          </FlightModalIcon>
                                    </FlightModalDestination>
                                    <FlightModalDestination>
                                          <div className="ps-3">
                                                {
                                                      searchDetails?.destination
                                                            ?.city
                                                }
                                          </div>
                                    </FlightModalDestination>
                              </FlightModalItem>
                              <FlightModalItem width="20%">
                                    <FlightModalDestination>
                                          <div>
                                                {convertDate(
                                                      searchDetail?.departDate
                                                )}
                                          </div>
                                    </FlightModalDestination>
                                    <FlightModalDestination width="10%">
                                          |
                                    </FlightModalDestination>
                                    <FlightModalDestination>
                                          <div>
                                                {searchDetail?.returnStatus ===
                                                "true"
                                                      ? convertDate(
                                                              searchDetail?.returnDate
                                                        )
                                                      : "-"}
                                          </div>
                                    </FlightModalDestination>
                              </FlightModalItem>
                              <FlightModalItem width="12%">
                                    <FlightModalDestination width="15%">
                                          {totalTraveller()}
                                    </FlightModalDestination>
                                    <FlightModalDestination width="10%">
                                          |
                                    </FlightModalDestination>
                                    <FlightModalDestination width="75%">
                                          {searchDetail?.cabinClass}
                                    </FlightModalDestination>
                              </FlightModalItem>
                              <FlightModalItem width="12%">
                                    <div>
                                          <div className="d-flex">
                                                <div>Regular </div>{" "}
                                          </div>
                                    </div>
                              </FlightModalItem>
                              <FlightModalButton width="21%">
                                    Modify Search
                              </FlightModalButton>
                        </FlightModalContent>
                  </FlightModalContainer>
            </>
      );
};

export default FlightModal;
