import React from "react";

const DoubleIcon = ({ color, transform }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill={color || "#333"}
                        style={{ transform: transform }}
                  >
                        <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" />
                  </svg>
            </>
      );
};

export default DoubleIcon;
