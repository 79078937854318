import React from "react";
import { Col, FormControl, FormLabel } from "react-bootstrap";
import { LoginInput } from "./style";

const FormInput = ({
      icon,
      title,
      required,
      type,
      value,
      handleChange,
      classes,
}) => {
      return (
            <>
                  <Col lg={classes || 4}>
                        <FormLabel>
                              {title}{" "}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </FormLabel>
                        <LoginInput className="ps-2 ">
                              {icon}
                              <FormControl
                                    type={type || "text"}
                                    onChange={(event) =>
                                          handleChange(event.target.value)
                                    }
                                    value={value}
                                    required={required}
                              />
                        </LoginInput>
                  </Col>
            </>
      );
};

export default FormInput;
