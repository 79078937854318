import React from "react";

const CommunicationIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        communication
                  </span>
            </>
      );
};

export default CommunicationIcon;
