export const lightTheme = {
	body: "#212529",
	primary: "#224082",
	secondary: "#F29727",
	textColor: "#1a1a1a",
	darkColor: "#f0f0f0;",
	inputColor: "#9F9F9F",
	disableColor: " #f0f0f0",
	white: "#fff",
	black: "#222222;",
	background: "#f4f4f4",
	primaryFont: "'Rubik', sans-serif",
	regularFont: "'Open Sans', sans-serif",
};

export const DarkTheme = {
	body: "#000000",
	text: "#FCF6F4",
	fontFamily: "'Poppins', sans-serif",
	textRgba: "252, 246, 244",
	bodyRgba: "0,0,0",
};
