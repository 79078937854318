import React, { useState } from "react";
import { UnoButtonContainer } from "./styles";
import AeroplaneIcon from "../../../icons/AeroplaneIcon";
import UnoIcon from "../../../icons/UnoIcon";

const UnoButton = ({
      width,
      setChangeDestination,
      changeDestination,
      plane,
      leavingFrom,
      destination,
      setDestination,
      setLeavingFrom,
}) => {
      const [active, setActive] = useState(false);
      const handleClick = () => {
            if (active) {
                  setDestination(leavingFrom);
                  setLeavingFrom(destination);
            } else {
                  setDestination(leavingFrom);
                  setLeavingFrom(destination);
            }
            setActive(!active);
            setChangeDestination(!changeDestination);
      };

      return (
            <>
                  <UnoButtonContainer
                        className="d-flex justify-content-center align-items-center"
                        width={width}
                        onClick={handleClick}
                        isActive={active}
                        changeDestination={changeDestination}
                  >
                        {plane ? <AeroplaneIcon /> : <UnoIcon />}
                  </UnoButtonContainer>
            </>
      );
};

export default UnoButton;
