import styled from "styled-components";

export const FlightModalContainer = styled.div`
      padding-bottom: 1.5rem;
      border-bottom: 1px dashed ${(props) => props.theme.primary};
`;

export const FlightModalContent = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;
`;

export const FlightHeader = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
`;

export const FlightHeaderInfo = styled.div``;

export const FlightHeaderContent = styled.div`
      display: flex;
      gap: 10px;

      .header__content-item {
            display: flex;
            gap: 10px;
            background-color: white;
            border: 1px solid #e2e2e2;
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 13px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &.active {
                  background-color: ${(props) => props.theme.primary};
                  color: ${(props) => props.theme.white};
                  border: 1px solid ${(props) => props.theme.primary};
            }
      }
`;

export const FlightModalItem = styled.div`
      border-radius: 5px;
      width: ${(props) => props.width};
      background-color: #fff;
      padding: 10px 15px;
      border: 1px solid #e2e2e2;
      display: flex;
      text-align: left;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      color: #222 !important;
`;

export const FlightModalDestination = styled.div`
      width: ${(props) => props.width || "45%"};
      height: ${(props) => props.height};
      color: #222 !important;
`;

export const FlightModalIcon = styled.div`
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -4px;
      border-radius: 50rem;
      transition: all 0.3s ease-in-out;

      &:hover {
            background-color: #f3f3f3;
      }
`;

export const FlightModalButton = styled.button`
      background-color: ${(props) =>
            props.status ? props.theme.primary : "#CCCCCC"};
      padding: 10px 15px;
      border: 0;
      border-radius: 5px;
      width: ${(props) => props.width};
      color: ${(props) => props.theme.white};
`;

export const FlightModalOptions = styled.div``;

export const FlightModalOption = styled.ul`
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      background-color: white;
      min-width: 220px;
      top: 100%;
      margin-top: 10px;
      left: 0;
      border: 1px solid #dadce0;
      border-radius: 5px;
      box-shadow: 0 0 1.25rem 0 rgba(136, 136, 136, 0.2);

      li {
            padding: 16px 20px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:first-child {
                  border-top-right-radius: 5px;
                  border-top-left-radius: 5px;
            }

            &:last-child {
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
            }

            &.selected {
                  background-color: #eef4fb;
            }

            &:hover {
                  background-color: #eef4fb;
            }
      }
`;
