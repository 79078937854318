import React from "react";
import { CheckoutContainer } from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import { DashboardContainer } from "../dashboard/styles";
import FlightDetailBox from "../../components/Frontend/checkout/FlightDetailBox";
import PriceSummaryBox from "../../components/Frontend/checkout/PriceSummaryBox";
import TravellersDetailBox from "../../components/Frontend/checkout/TravellersDetailBox";

const CheckoutPage = () => {
      return (
            <>
                  <CheckoutContainer>
                        <DashboardContainer>
                              <Container>
                                    <Row>
                                          <Col lg={8}>
                                                <div
                                                      className="support mb-2"
                                                      style={{
                                                            visibility:
                                                                  "hidden",
                                                      }}
                                                >
                                                      Flight support : +91
                                                      8766601888
                                                </div>
                                                <FlightDetailBox
                                                      marginBottom={"30px"}
                                                />
                                                <TravellersDetailBox
                                                      marginBottom={"30px"}
                                                />
                                                {/* <TravellersAdditionalBox /> */}
                                          </Col>
                                          <Col lg={3}>
                                                <PriceSummaryBox />
                                          </Col>
                                    </Row>
                              </Container>
                        </DashboardContainer>
                  </CheckoutContainer>
            </>
      );
};

export default CheckoutPage;
