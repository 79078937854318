import React, { useState, useRef } from "react";
import {
      ChevronIcon,
      CustomSelectContainer,
      SelectButton,
      SelectList,
      SelectListItem,
} from "./styles";

const CustomSelect = ({
      options,
      onSelect,
      data,
      setData,
      index,
      label,
}) => {
      const [isOpen, setIsOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState(null);
      const selectRef = useRef();

      const handleSelectClick = () => {
            setIsOpen(!isOpen);
      };

      const handleOptionClick = (option) => {
            setSelectedOption(option);
            setIsOpen(false);
            onSelect(option);
      };

      const handleClickOutside = (event) => {
            if (
                  selectRef.current &&
                  !selectRef.current.contains(event.target)
            ) {
                  setIsOpen(false);
            }
      };
      const handleClassChange = (index, event) => {
            const newData = [...data];
            newData[index].class = selectedOption;
            setData(newData);
      };
      React.useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, []);

      return (
            <CustomSelectContainer ref={selectRef}>
                  <SelectButton
                        onClick={handleSelectClick}
                        className="d-flex"
                  >
                        <input
                              type="hidden"
                              value={selectedOption?.value}
                              onChange={(event) =>
                                    handleClassChange(index, event)
                              }
                        />

                        <div className="selected">
                              {selectedOption ? selectedOption.label : label}
                        </div>
                        <ChevronIcon>
                              <i className="bx bx-chevron-down"></i>
                        </ChevronIcon>
                  </SelectButton>

                  {isOpen && (
                        <SelectList className="options-list" isOpen={isOpen}>
                              {options.map((option) => (
                                    <SelectListItem
                                          key={option.value}
                                          className="option"
                                          onClick={() =>
                                                handleOptionClick(option)
                                          }
                                    >
                                          {option.label}
                                    </SelectListItem>
                              ))}
                        </SelectList>
                  )}
            </CustomSelectContainer>
      );
};

export default CustomSelect;
