import React, { useState } from "react";
import {
      FlightAirline,
      FlightAirlineTime,
      FlightAirlineTitle,
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
      FlightDetailTitle,
      FlightLayoverContainer,
      FlightLayoverContent,
      FlightLayoverLine,
      FlightLayoverTitle,
      FlightRefund,
} from "../../../Dashboard/FlightDetails/style";
import { FlightInfoLogo } from "../../../Dashboard/FlightInfo/styles";
import { format } from "date-fns";
import { BaggageIcon, DoubleIcon, TripIcon } from "../../../../icons";
import CommonButton from "../../../Common/CommonButton";
import { useNavigate } from "react-router-dom";

const FlightDetailBox = ({
      marginBottom,
      tripInfos,
      options,
      currentOption,
      setCurrentOption,
      setOptions,
      reviewId,
}) => {
      const previousSearch = localStorage.getItem("previousSearch");
      const navigate = useNavigate();
      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE - dd MMM yyyy");
      };

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const calculateTimeDifference = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            const differenceInMs = Math.abs(date1 - date2);

            const totalMinutes = Math.floor(differenceInMs / 60000);

            return calculateDuration(totalMinutes);
      };

      const handleOption = () => {
            setLoading(true);
            let optionItem = currentOption.index + 1;
            setTimeout(() => {
                  setLoading(false);
                  const updateOptions = options.map((item, i) => {
                        if (i <= optionItem) {
                              return { ...item, status: true };
                        }
                        return { ...item, status: false };
                  });
                  setOptions(updateOptions);
                  setCurrentOption({
                        id: updateOptions[optionItem].id,
                        index: optionItem,
                  });
                  navigate(
                        `/flight/${updateOptions[optionItem].id}/${reviewId}`
                  );
            }, 3000);
      };

      const handleBack = () => {
            setLoadingBack(true);
            setTimeout(() => {
                  setLoadingBack(false);
                  navigate(previousSearch);
            }, 3000);
      };

      return (
            <>
                  <FlightDetailContainer style={{ marginBottom }}>
                        <FlightDetailCategory>
                              Flight Details
                        </FlightDetailCategory>
                        {tripInfos?.map((info) => (
                              <FlightDetailInfo border={true}>
                                    {info?.sI?.map((item, index) => (
                                          <>
                                                {index === 0 && (
                                                      <FlightDetailTitle>
                                                            {item?.da?.city} -{" "}
                                                            {item?.aa?.city} |{" "}
                                                            <span>
                                                                  {convertDate(
                                                                        item?.at
                                                                  )}
                                                            </span>
                                                      </FlightDetailTitle>
                                                )}
                                                <div>
                                                      <FlightDetailAirline>
                                                            <FlightAirline>
                                                                  <FlightInfoLogo>
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  </FlightInfoLogo>
                                                                  <FlightAirlineTitle>
                                                                        <div>
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        </div>
                                                                        <span>
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code
                                                                              }{" "}
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.fN
                                                                              }
                                                                        </span>
                                                                        {/* <span>ECONOMY</span> */}
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime>
                                                                  <div>
                                                                        {formatTime(
                                                                              item?.dt
                                                                        )}
                                                                  </div>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.city
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.code
                                                                        }
                                                                        )
                                                                  </span>
                                                                  <span>
                                                                        {convertDate(
                                                                              item?.dt
                                                                        )}
                                                                  </span>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.terminal
                                                                        }
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightRefund>
                                                                  <div className="flight__duration">
                                                                        {`${
                                                                              calculateDuration(
                                                                                    item?.duration
                                                                              )
                                                                                    .hours
                                                                        }h ${
                                                                              calculateDuration(
                                                                                    item?.duration
                                                                              )
                                                                                    .minutes
                                                                        }m`}
                                                                  </div>
                                                                  <div className="line"></div>
                                                                  <div class="flight__refund">
                                                                        {tripInfos[0]
                                                                              ?.totalPriceList[0]
                                                                              ?.fd
                                                                              ?.ADULT
                                                                              ?.rT
                                                                              ? "REFUNDABLE"
                                                                              : "NON REFUNDABLE"}
                                                                  </div>
                                                            </FlightRefund>
                                                            <FlightAirlineTime>
                                                                  <div>
                                                                        {formatTime(
                                                                              item?.at
                                                                        )}
                                                                  </div>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.city
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.code
                                                                        }
                                                                        )
                                                                  </span>
                                                                  <span>
                                                                        {convertDate(
                                                                              item?.at
                                                                        )}
                                                                  </span>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.terminal
                                                                        }
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                      <FlightDetailInfo fontSize="14px">
                                                            <TripIcon
                                                                  width="18px"
                                                                  height="18px"
                                                            />{" "}
                                                            Check-In Baggage:{" "}
                                                            {
                                                                  tripInfos[0]
                                                                        ?.totalPriceList[0]
                                                                        ?.fd
                                                                        ?.ADULT
                                                                        ?.bI?.iB
                                                            }
                                                            {
                                                                  " (1 piece only) / Adult"
                                                            }
                                                            <BaggageIcon
                                                                  width="18px"
                                                                  height="18px"
                                                                  classes="ms-3"
                                                            />{" "}
                                                            Cabin Baggage:{" "}
                                                            {
                                                                  tripInfos[0]
                                                                        ?.totalPriceList[0]
                                                                        ?.fd
                                                                        ?.ADULT
                                                                        ?.bI?.cB
                                                            }
                                                            {
                                                                  " (1 piece only) / Adult"
                                                            }
                                                      </FlightDetailInfo>
                                                </div>
                                                {tripInfos?.sI?.length > 1 &&
                                                      tripInfos?.sI.length !==
                                                            index + 1 && (
                                                            <FlightLayoverContainer className="my-4">
                                                                  <FlightLayoverLine />
                                                                  <FlightLayoverContent>
                                                                        <FlightLayoverTitle
                                                                              fontWeight="600"
                                                                              color="#224082"
                                                                        >
                                                                              Plane
                                                                              Change
                                                                        </FlightLayoverTitle>
                                                                        <FlightLayoverTitle
                                                                              fontSize="0.875rem"
                                                                              color="#555"
                                                                        >
                                                                              <span>
                                                                                    Layover
                                                                                    at
                                                                                    {` `}
                                                                                    {
                                                                                          item
                                                                                                ?.aa
                                                                                                ?.city
                                                                                    }

                                                                                    ,
                                                                                    {` ${
                                                                                          calculateTimeDifference(
                                                                                                item?.at,
                                                                                                tripInfos[0]
                                                                                                      ?.sI[
                                                                                                      index +
                                                                                                            1
                                                                                                ]
                                                                                                      ?.dt
                                                                                          )
                                                                                                .hours
                                                                                    }h ${
                                                                                          calculateTimeDifference(
                                                                                                item?.at,
                                                                                                tripInfos[0]
                                                                                                      ?.sI[
                                                                                                      index +
                                                                                                            1
                                                                                                ]
                                                                                                      ?.dt
                                                                                          )
                                                                                                .minutes
                                                                                    }m`}
                                                                              </span>
                                                                        </FlightLayoverTitle>
                                                                  </FlightLayoverContent>
                                                            </FlightLayoverContainer>
                                                      )}
                                          </>
                                    ))}
                              </FlightDetailInfo>
                        ))}
                  </FlightDetailContainer>
            </>
      );
};

export default FlightDetailBox;
