import React, { useState } from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "../../../icons";
import {
      SliderContainer,
      SliderContent,
      SliderDotContainer,
      SliderDotItem,
      SliderImage,
      SliderNext,
      SliderPrevious,
} from "./styles";

const HotelImageSlider = ({ images, height }) => {
      const [currentIndex, setCurrentIndex] = useState(0);

      const goToPrevious = () => {
            const isFirstSlide = currentIndex === 0;
            const newIndex = isFirstSlide
                  ? images.length - 1
                  : currentIndex - 1;
            setCurrentIndex(newIndex);
      };

      const goToNext = () => {
            const isLastSlide = currentIndex === images.length - 1;
            const newIndex = isLastSlide ? 0 : currentIndex + 1;
            setCurrentIndex(newIndex);
      };

      const goToSlide = (slideIndex) => {
            setCurrentIndex(slideIndex);
      };

      return (
            <SliderContainer>
                  <SliderPrevious onClick={goToPrevious}>
                        <ArrowBackIcon />
                  </SliderPrevious>
                  <SliderNext onClick={goToNext}>
                        <ArrowForwardIcon />
                  </SliderNext>
                  <SliderContent height={height}>
                        {images.map((image, index) => (
                              <SliderImage
                                    key={index}
                                    src={image.url}
                                    alt={`Slide ${index}`}
                                    style={{
                                          opacity:
                                                index === currentIndex ? 1 : 0,
                                    }}
                              />
                        ))}
                  </SliderContent>
                  <SliderDotContainer>
                        {images.map((slide, slideIndex) => (
                              <SliderDotItem
                                    className={
                                          currentIndex === slideIndex
                                                ? "active"
                                                : ""
                                    }
                                    key={slideIndex}
                                    onClick={() => goToSlide(slideIndex)}
                              >
                                    ●
                              </SliderDotItem>
                        ))}
                  </SliderDotContainer>
            </SliderContainer>
      );
};

export default HotelImageSlider;
