import { Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { FlightDetails } from "../checkout/styles";

export const ReviewContainer = styled(Container)`
      padding-bottom: 100px;
`;
export const ReviewBox = styled(Col)``;
export const ReviewHeading = styled.div`
      background: ${(props) => props.theme.primary};
      padding: 20px 50px;
`;
export const ReviewTitle = styled.h2`
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
      color: ${(props) => props.theme.white};
`;
export const ReviewContent = styled.div`
      background: ${(props) => props.theme.white};
      ${FlightDetails} {
            border: unset;
      }
`;

export const PassengerDetail = styled(Col)`
      display: flex;
      algin-items: center;
      justify-content: space-between;
      .passenger__title {
            font-size: 19px;
            font-weight: 400;
            line-height: 28.57px;
            text-align: left;
      }
      .passenger__value {
            font-size: 16px;
            font-weight: 400;
            line-height: 28.57px;
            text-align: center;
            color: #1a1a1a;
      }
`;

export const PassengerFooter = styled.div`
      padding: 30px 0px;
`;
