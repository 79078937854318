import styled from "styled-components";

export const CommonContainer = styled.div`
      padding: 5rem 0;
`;
export const CommonWrapper = styled.div`
      padding: 0px 30px;
`;
export const CommonLineClamp = styled.div`
      width: ${(props) => (props.width ? props.width : "70px")};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
`;
