import styled from "styled-components";

export const FlightInfoContainer = styled.div`
      padding: ${(props) => props.padding || "20px 10px"};
      background: white;
      margin-bottom: 15px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

      &:hover {
            background-color: ${(props) =>
                  props.status ? "#FFFFFF" : "#eef4fb"};
      }
`;

export const FlightInfoContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const FlightInfoLogo = styled.div`
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: 2.5px;
      height: 34px;
      width: 34px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
            width: ${(props) => props.width || "35px"};
            height: ${(props) => props.height || "35px"};
            border-radius: 5px;
      }
`;
export const FlightInfoTitle = styled.div`
      display: flex;
      align-items: center;

      h4 {
            font-size: 14px;
            font-weight: 500;
      }
`;
export const FlightInfoTime = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
`;
export const FlightInfoDuration = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
`;
export const FlightInfoPrice = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;

      .flight__info-price {
            display: flex;
            align-items: end;

            gap: 8px;

            .flight__info-net {
                  font-size: 13px;
            }
      }
`;

export const FlightInfoButton = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
`;
