import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle` 
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: "Open Sans", sans-serif;
}
:root {
      --primary__font:"Rubik", sans-serif;
     
}
a {
    text-decoration: none !important;
}
.mm-listitem a{
  text-transform: capitalize;
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: unset;
    border-color: unset;
    outline: 0;
    box-shadow:unset;
}
.form-check-input:checked {
    background-color:#224082 ;
    border-color: #224082;
}
.form-control:focus {
    box-shadow: none;
}
.btn-primary {
		padding: 0;
		background: unset;
		color: #222222;
		border: unset;
	}
	.btn-check:checked + .btn,
	.btn.active,
	.btn.show,
	.btn:first-child:active,
	:not(.btn-check) + .btn:active {
	color: #222222;
		background-color: unset;
		border-color: unset;
	}
    .btn:hover {
        color: #222222;
    background-color:unset;
    border-color:unset;
}



.animation__cloud--back {
      position: absolute;
      top: 6%;
      left: 34%;
      fill: #cee4e7;
      animation: cloudBack 8s linear infinite;
}

.animation__cloud--middle {
      position: absolute;
      top: 22%;
      left: 75%;
      width: 6rem;
      height: 3rem;
      fill: #cee4e7;
      animation: cloudMiddle 5s linear infinite;
}

.animation__plane {
      position: absolute;
      z-index: 1;
      top: calc(40% - 31px);
      left: calc(50% - 136px);
      width: 17rem;
      height: 6.2rem;
      animation: takeOff 2s ease-in-out,
            flight 5s ease-in-out 2s infinite alternate;
}

/* .plane__text {
      position: absolute;
      z-index: 1;
      top: calc(80% - 0px);
      left: calc(50% - 136px);
} */

.animation__cloud--front {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 20%;
      width: 8.5rem;
      height: 3rem;
      fill: #cee4e7;
      animation: cloudFront 4s linear infinite;
}

@keyframes loader {
      0% {
            left: -100%;
      }

      to {
            left: 100%;
      }
}

@keyframes takeOff {
      0% {
            transform: translate(-220%, 110%);
      }

      to {
            transform: translate(0);
      }
}

@keyframes takeOffShadow {
      0% {
            transform: translate(-440%, 110%);
            opacity: 1;
      }

      to {
            transform: translate(0);
            opacity: 0.8;
      }
}

@keyframes flight {
      0% {
            transform: translate(0);
      }

      25% {
            transform: translateY(10%);
      }

      75% {
            transform: translateY(-10%);
      }

      to {
            transform: translate(0);
      }
}

@keyframes flightShadow {
      0% {
            transform: scale(0.8);
            opacity: 0.8;
      }

      25% {
            transform: scale(0.9);
            opacity: 1;
      }

      75% {
            transform: scale(1.1);
            opacity: 0.6;
      }

      to {
            transform: scale(0.8);
            opacity: 0.8;
      }
}

@keyframes cloudFront {
      0% {
            transform: translate(520%);
      }

      to {
            transform: translate(-600%);
      }
}

@keyframes cloudMiddle {
      0% {
            transform: translate(230%);
      }

      to {
            transform: translate(-900%);
      }
}

@keyframes cloudBack {
      0% {
            transform: translate(910%);
      }

      to {
            transform: translate(-1000%);
      }
}



`;
