import React from "react";
import BannerBox from "../../components/Frontend/home/BannerSection";
import PackageBox from "../../components/Frontend/home/PackageBox";
import { hotelBannerDate } from "../../data/banners";
import HotelForm from "../../components/Frontend/hotel/HotelForm";

const HotelPage = () => {
      return (
            <>
                  <BannerBox banners={hotelBannerDate}>
                        <HotelForm
                              width={" 80%"}
                              marginBottom={"30px"}
                              marginTop={"30px"}
                        />
                  </BannerBox>
                  <PackageBox />
            </>
      );
};

export default HotelPage;
