import React from "react";

const ChildIcon = ({ color }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.0"
                        width="20px"
                        height="20px"
                        viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet"
                  >
                        <g
                              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill={color || "#333"}
                              stroke="none"
                        >
                              <path d="M2422 5105 c-297 -67 -506 -345 -489 -650 15 -259 184 -478 437 -566 54 -19 86 -23 190 -23 110 0 134 3 198 26 101 36 172 82 245 155 74 73 119 145 155 245 23 64 26 88 26 198 0 104 -4 136 -23 190 -67 194 -213 341 -403 407 -91 32 -241 40 -336 18z" />
                              <path d="M698 4610 c-163 -28 -291 -161 -319 -329 -12 -76 8 -182 47 -250 35 -59 280 -308 407 -414 242 -200 509 -363 765 -464 l92 -36 0 -1366 c0 -835 4 -1392 10 -1434 13 -88 44 -151 102 -210 73 -73 143 -102 248 -101 72 0 95 4 146 28 77 34 144 103 181 181 l28 60 3 598 3 597 149 0 150 0 0 -542 c1 -299 5 -573 9 -610 16 -125 86 -225 196 -279 183 -90 394 -13 482 176 l28 60 3 1420 2 1421 78 28 c127 48 358 169 502 263 191 126 322 234 500 413 172 172 210 228 231 341 30 166 -59 338 -216 415 -55 27 -69 29 -170 29 -156 0 -187 -17 -368 -209 -272 -287 -526 -465 -827 -582 -194 -75 -363 -104 -600 -104 -237 0 -406 29 -600 104 -301 117 -555 295 -827 582 -153 162 -202 195 -314 214 -35 5 -66 9 -69 9 -3 -1 -26 -5 -52 -9z" />
                        </g>
                  </svg>
            </>
      );
};

export default ChildIcon;
