import React from "react";

const AttachFileIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">attach_file</span>
            </>
      );
};

export default AttachFileIcon;
