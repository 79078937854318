import React from "react";
import { PrivateLayoutContainer } from "./styles";
import Header from "../header";
import Footer from "../footer";

const PrivateLayout = ({ children, header, footer }) => {
      return (
            <>
                  <PrivateLayoutContainer>
                        <Header />
                        {children}
                        {footer && <Footer />}
                  </PrivateLayoutContainer>
            </>
      );
};

export default PrivateLayout;
