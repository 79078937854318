export const flightBannerData = [
      {
            image: "/images/banners/banner-1.png",
            title: "Up to ₹3000 off  <br /> on flight bookings. ",
      },
];
export const homepackages = [
      {
            image: "/images/package/package-img-1.png",
            title: "Thailand Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-2.png",
            title: "Japan Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-3.png",
            title: "Europe Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-1.png",
            title: "Thailand Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-2.png",
            title: "Japan Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-3.png",
            title: "Europe Trip",
            text: "Starting From $100 per person",
      },
];

export const hotelBannerDate = [
      {
            image: "/images/banners/hotel.png",
            title: "Up to 20% off <br/>on hotel bookings. ",
      },
];

export const packagesData = [
      {
            image: "/images/banners/hotel.png",
            title: "20% off on thailand <br/> package bookings. ",
      },
];
