import React, { useEffect, useRef, useState } from "react";

import { Form } from "react-bootstrap";
import {
      AdultChildSection,
      AdultChildTitle,
      AdultSection,
      ChildSection,
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
} from "./styles";
import { FormInfo, FormWrapper } from "../../Frontend/home/TravelerBox/styles";

const HotelTraveler = ({
      room,
      setRoom,
      adult,
      setAdult,
      children,
      setChildren,
      label,
}) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();
      const handleClick = () => {
            setShow(true);
      };
      const handleOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener("click", handleOutside);

            return () => {
                  document.removeEventListener("click", handleOutside);
            };
      }, []);

      const incrementRoom = () => {
            if (room < 5) {
                  setRoom(room + 1);
            }
      };

      const decrementRoom = () => {
            if (room > 1) {
                  setRoom(room - 1);
            }
      };

      const handleIncrementChildren = () => {
            if (children < 4) {
                  setChildren(children + 1);
            }
      };
      const handleDecrementChildren = () => {
            if (children > 0) {
                  setChildren(children - 1);
            }
      };

      const handleIncrementAdult = () => {
            if (adult < 10) {
                  setAdult(adult + 1);
            }
      };
      const handleDecrementAdult = () => {
            if (adult > 1) {
                  setAdult(adult - 1);
            }
      };

      return (
            <>
                  <FormWrapper onClick={handleClick} ref={divRef}>
                        {label && <Form.Label>{label}</Form.Label>}
                        <FormInfo>
                              <span
                                    style={{
                                          fontSize: "13px",
                                    }}
                              >
                                    {room} room, {adult} adults, {children}{" "}
                                    children
                              </span>
                        </FormInfo>
                        <FormAdditional className={show ? "show" : ""}>
                              <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                    <FormAdditionalTitle>
                                          Rooms
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter className="  ">
                                          <FormAdditionalCounterIcon
                                                className="align-items-center justify-content-center substract"
                                                onClick={() => decrementRoom()}
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                <input
                                                      type="number"
                                                      value={room}
                                                      min="1"
                                                      max="5"
                                                      step="1"
                                                      readOnly={true}
                                                      data-role="none"
                                                />
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={incrementRoom}
                                                className="align-items-center justify-content-center add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <AdultChildSection className="d-flex align-items-center justify-content-between px-3 py-2">
                                    <AdultSection>
                                          <AdultChildTitle>
                                                Adults (12+)
                                          </AdultChildTitle>
                                          <FormAdditionalCounter className="">
                                                <FormAdditionalCounterIcon
                                                      className="align-items-center justify-content-center substract"
                                                      onClick={() =>
                                                            handleDecrementAdult()
                                                      }
                                                >
                                                      <span>-</span>
                                                </FormAdditionalCounterIcon>
                                                <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                      <input
                                                            type="number"
                                                            value={adult}
                                                            min="1"
                                                            max="5"
                                                            step="1"
                                                            readOnly={true}
                                                            data-role="none"
                                                      />
                                                </FormAdditionalCounterInput>
                                                <FormAdditionalCounterIcon
                                                      className="align-items-center justify-content-center add"
                                                      onClick={() =>
                                                            handleIncrementAdult()
                                                      }
                                                >
                                                      <span>+</span>
                                                </FormAdditionalCounterIcon>
                                          </FormAdditionalCounter>
                                    </AdultSection>
                                    <ChildSection>
                                          <AdultChildTitle>
                                                Children
                                          </AdultChildTitle>
                                          <FormAdditionalCounter className="  ">
                                                <FormAdditionalCounterIcon
                                                      className="align-items-center justify-content-center substract"
                                                      onClick={() =>
                                                            handleDecrementChildren()
                                                      }
                                                >
                                                      <span>-</span>
                                                </FormAdditionalCounterIcon>
                                                <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                      <input
                                                            type="number"
                                                            value={children}
                                                            min="1"
                                                            max="5"
                                                            step="1"
                                                            readOnly={true}
                                                            data-role="none"
                                                      />
                                                </FormAdditionalCounterInput>
                                                <FormAdditionalCounterIcon
                                                      className="align-items-center justify-content-center add"
                                                      onClick={() =>
                                                            handleIncrementChildren()
                                                      }
                                                >
                                                      <span>+</span>
                                                </FormAdditionalCounterIcon>
                                          </FormAdditionalCounter>
                                    </ChildSection>
                              </AdultChildSection>
                              {/* {values.map((item, index) => ( */}
                              {/* <> */}
                              {/* <Row className=" px-3 mb-3">
                                                {item.childrenAges.map(
                                                      (age, childIndex) => (
                                                            <Col lg={6}>
                                                                  <Form.Select
                                                                        key={
                                                                              childIndex
                                                                        }
                                                                        defaultValue={
                                                                              age
                                                                        }
                                                                        className="p-1"
                                                                        style={{
                                                                              fontSize: "12px",
                                                                        }}
                                                                        onChange={(
                                                                              e
                                                                        ) =>
                                                                              handleChildAgeChange(
                                                                                    index,
                                                                                    childIndex,
                                                                                    e
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  >
                                                                        <option value="">
                                                                              Child
                                                                              Age
                                                                        </option>
                                                                        <option value="< 2 yrs">
                                                                              {
                                                                                    "<"
                                                                              }{" "}
                                                                              2
                                                                              yrs
                                                                        </option>
                                                                        <option value="2 yrs">
                                                                              2
                                                                              yrs
                                                                        </option>
                                                                        <option value="3 yrs">
                                                                              3
                                                                              yrs
                                                                        </option>
                                                                        <option value="4 yrs">
                                                                              4
                                                                              yrs
                                                                        </option>
                                                                        <option value="5 yrs">
                                                                              5
                                                                              yrs
                                                                        </option>
                                                                        <option value="6 yrs">
                                                                              6
                                                                              yrs
                                                                        </option>
                                                                        <option value="7 yrs">
                                                                              7
                                                                              yrs
                                                                        </option>
                                                                        <option value="8 yrs">
                                                                              8
                                                                              yrs
                                                                        </option>
                                                                        <option value="9 yrs">
                                                                              9
                                                                              yrs
                                                                        </option>
                                                                        <option value="10 yrs">
                                                                              10
                                                                              yrs
                                                                        </option>

                                                                        <option value="11 yrs">
                                                                              11
                                                                              yrs
                                                                        </option>
                                                                  </Form.Select>
                                                            </Col>
                                                      )
                                                )}
                                          </Row> */}
                              {/* </>
                              ))} */}
                        </FormAdditional>
                  </FormWrapper>
            </>
      );
};

export default HotelTraveler;
