import React from "react";
import { DashboardContainer } from "../dashboard/styles";
import {
      PassengerDetail,
      PassengerFooter,
      ReviewBox,
      ReviewContainer,
      ReviewContent,
      ReviewHeading,
      ReviewTitle,
} from "./styles";
import { FlightDetails } from "../checkout/styles";
import CommonButton from "../../components/Common/CommonButton";

const ReviewPage = ({ marginBottom }) => {
      return (
            <>
                  <DashboardContainer>
                        <ReviewContainer className="d-flex justify-content-center">
                              <ReviewBox lg={10}>
                                    <ReviewHeading>
                                          <ReviewTitle className="mb-0">
                                                REVIEW YOUR BOOKING
                                          </ReviewTitle>
                                    </ReviewHeading>

                                    <ReviewContent
                                          style={{ padding: "10px 40px" }}
                                    >
                                          <FlightDetails
                                                style={{ marginBottom }}
                                          >
                                                <div className="title mb-2">
                                                      Delhi - Mumbai |{" "}
                                                      <span>Tue-14May2024</span>
                                                </div>
                                                <div className="detail d-flex algin-items-center justify-content-between ">
                                                      <div className="flight__info ">
                                                            <div className="flight__title flight">
                                                                  SpiceJet
                                                            </div>
                                                            <div className="flight__content">
                                                                  SG-8279
                                                            </div>
                                                            <div className="flight__content">
                                                                  ECONOMY
                                                            </div>
                                                      </div>
                                                      <div className="flight__time">
                                                            <div className="flight__title">
                                                                  23:00
                                                            </div>
                                                            <div className="flight__content">
                                                                  New Delhi
                                                                  (DEL)
                                                            </div>
                                                            <div className="flight__content">
                                                                  Tue-14May2024
                                                            </div>
                                                            <div className="flight__content">
                                                                  Terminal - 3
                                                            </div>
                                                      </div>
                                                      <div className="flight__show w-25 d-flex align-items-center flex-column gap-3">
                                                            <div className="flight__duration">
                                                                  02h 10m
                                                            </div>
                                                            <div className="line"></div>
                                                            <div className="flight__refund">
                                                                  REFUNDABLE
                                                            </div>
                                                      </div>
                                                      <div className="flight__arrival">
                                                            <div className="flight__title">
                                                                  01:10
                                                            </div>
                                                            <div className="flight__content">
                                                                  Jaipur (JAI)
                                                            </div>
                                                            <div className="flight__content">
                                                                  Wed-15May2024
                                                            </div>
                                                            <div className="flight__content">
                                                                  Terminal - 1
                                                            </div>
                                                      </div>
                                                </div>
                                          </FlightDetails>
                                          <PassengerDetail
                                                style={{ padding: "10px 40px" }}
                                          >
                                                <div className="passenger__info">
                                                      <div className="passenger__title">
                                                            PASSENGER NAME
                                                      </div>
                                                      <div className="passenger__value"></div>
                                                </div>
                                                <div className="passenger__info">
                                                      <div className="passenger__title">
                                                            GENDER
                                                      </div>
                                                      <div className="passenger__value">
                                                            M
                                                      </div>
                                                </div>
                                                <div className="passenger__info">
                                                      <div className="passenger__title">
                                                            TYPE
                                                      </div>
                                                      <div className="passenger__value">
                                                            ADT
                                                      </div>
                                                </div>
                                                <div className="passenger__info">
                                                      <div className="passenger__title">
                                                            Nationality
                                                      </div>
                                                      <div className="passenger__value">
                                                            INDIA
                                                      </div>
                                                </div>
                                          </PassengerDetail>
                                          <PassengerFooter
                                                className="d-flex align-items-center w-100 justify-content-between"
                                                style={{ padding: "30px 40px" }}
                                          >
                                                <div className="passenger__footer--title">
                                                      COST: 5,363
                                                </div>
                                                <div className="passenger__footer--button">
                                                      <CommonButton
                                                            title={"Pay Now"}
                                                            width={"200px"}
                                                            padding={
                                                                  "10px 30px"
                                                            }
                                                      />
                                                </div>
                                          </PassengerFooter>
                                    </ReviewContent>
                              </ReviewBox>
                        </ReviewContainer>
                  </DashboardContainer>
            </>
      );
};

export default ReviewPage;
