import React from "react";

const AdultIcon = ({ color }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.0"
                        width="20px"
                        height="20px"
                        viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet"
                  >
                        <g
                              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill={color || "#333"}
                              stroke="none"
                        >
                              <path d="M2429 4792 c-213 -56 -359 -250 -359 -478 0 -246 175 -447 421 -485 236 -37 475 117 543 350 9 31 16 91 16 137 0 329 -301 559 -621 476z" />
                              <path d="M2062 3660 c-112 -29 -187 -74 -277 -165 -92 -92 -136 -166 -165 -281 -19 -71 -20 -112 -20 -538 0 -503 2 -527 58 -630 39 -72 120 -149 196 -186 l65 -32 3 -627 c3 -623 3 -626 25 -674 31 -66 105 -142 172 -174 l56 -28 385 0 385 0 56 28 c67 32 141 108 172 174 22 48 22 51 25 674 l3 627 65 32 c76 37 157 114 196 186 56 103 58 127 58 630 0 514 -3 546 -72 677 -43 82 -173 212 -255 255 -129 68 -167 72 -637 71 -381 0 -428 -2 -494 -19z" />
                        </g>
                  </svg>
            </>
      );
};

export default AdultIcon;
