import React from "react";

const GlobeIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">language</span>
            </>
      );
};

export default GlobeIcon;
