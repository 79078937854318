import React from "react";
import { Form } from "react-bootstrap";
import { FormIcon, FormWrapper } from "./styles";
import TravelerBox from "../Frontend/home/TravelerBox";
import HotelBox from "../Frontend/home/HotelBox";
import FormInputWithIcon from "./FormInputWithIcon";

const FormItem = ({
      label,
      type,
      value,
      onChange,
      placeholder,
      width,
      data,
      setData,
      padding,
      state,
      marginRight,
      icon,
      backgroundNone,
      inputPadding,
      border,
      svg,
      required,
}) => {
      return type === "number" ? (
            <TravelerBox
                  data={data}
                  setData={setData}
                  type={"hidden"}
                  label={label}
                  width={width}
            />
      ) : type === "hotel" ? (
            <HotelBox
                  type={"hidden"}
                  data={data}
                  setData={setData}
                  label={label}
            />
      ) : (
            <FormWrapper
                  width={width}
                  padding={padding}
                  marginRight={marginRight}
                  backgroundNone={backgroundNone}
                  inputPadding={inputPadding}
                  border={border}
            >
                  <Form.Group>
                        {label && <Form.Label>{label}</Form.Label>}
                        {type === "icon" ? (
                              <FormInputWithIcon
                                    icon={icon}
                                    background={"#f0f0f0"}
                              />
                        ) : (
                              <Form.Control
                                    type={type}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={placeholder}
                                    disabled={!state}
                                    required={required}
                              />
                        )}
                  </Form.Group>
                  {!(type === "icon") && (
                        <FormIcon>
                              {icon && svg ? icon : <img src={icon} alt="" />}
                        </FormIcon>
                  )}
            </FormWrapper>
      );
};

export default FormItem;
