import styled from "styled-components";

export const RoundtripInfoContent = styled.div`
      display: flex;
      align-items: center;
      gap: 2rem;
      border-right: 1px dashed ${(props) => props.theme.primary};
      padding-right: 10px;
`;

export const RoundtripBookContainer = styled.div`
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 10px;
      left: 0;
      background-color: white;
      z-index: 1;
`;

export const RoundtripInfoLogo = styled.div`
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: 2.5px;
      height: 34px;
      width: 34px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
      }
`;

export const RoundtripNetPrice = styled.div`
      font-size: ${(props) => props.fontSize || "12px"};
`;
