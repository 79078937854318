import React, { useState } from "react";
import {
      FlightDetailButton,
      FlightPriceContainer,
      FlightPriceContent,
      FlightPriceItem,
} from "./style";
import { FormControl } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";

const FlightPrice = ({
      setStatus,
      status,
      totalPriceList,
      setFareId,
      flightInfo,
      returnStatus,
      searchDetail,
      fareId,
      fullFare,
}) => {
      const handlePrice = (item) => {
            setFareId(item);
      };

      const calculateTotalAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        newAmount +
                        currentItem?.ADULT?.fC?.TF *
                              (fullFare ? searchDetail?.adult : 1);
            }
            if (currentItem?.CHILD) {
                  newAmount =
                        newAmount +
                        currentItem?.CHILD?.fC?.TF *
                              (fullFare ? searchDetail?.children : 1);
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        newAmount +
                        currentItem?.INFANT?.fC?.TF *
                              (fullFare ? searchDetail?.infants : 1);
            }
            return convertAmount(convertFloat(newAmount));
      };

      const checkCabinClassPrice = () => {
            return totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );
      };

      const checkRefundable = (item) => {
            if (item?.fd?.ADULT?.rT === 1) {
                  return ", Refundable";
            } else if (item?.fd?.ADULT?.rT === 2) {
                  return ", Partial Refundable";
            } else if (item?.fd?.ADULT?.rT === 0) {
                  return ", Non Refundable";
            }
      };

      return (
            <>
                  <FlightPriceContainer returnStatus={returnStatus}>
                        <FlightPriceContent>
                              {checkCabinClassPrice()
                                    ?.slice(0, 3, 0)
                                    ?.map((item, index) => (
                                          <FlightPriceItem
                                                className={
                                                      item?.id === fareId?.id
                                                            ? "active"
                                                            : ""
                                                }
                                                onClick={() =>
                                                      handlePrice(item)
                                                }
                                                returnStatus={returnStatus}
                                          >
                                                <FormControl
                                                      type="radio"
                                                      value={item?.id}
                                                      name="option"
                                                      checked={
                                                            item?.id ===
                                                            fareId?.id
                                                                  ? true
                                                                  : false
                                                      }
                                                />
                                                <div className="price">
                                                      ₹{" "}
                                                      {calculateTotalAmount(
                                                            item?.fd
                                                      )}{" "}
                                                      <span>
                                                            {
                                                                  item?.fareIdentifier
                                                            }
                                                      </span>{" "}
                                                </div>
                                                <div className="summary">
                                                      {" "}
                                                      {`${item?.fd?.ADULT?.cc}${
                                                            item?.fd?.ADULT?.mI
                                                                  ? ", Free Meal"
                                                                  : ""
                                                      }${checkRefundable(
                                                            item
                                                      )}`}
                                                </div>
                                          </FlightPriceItem>
                                    ))}
                              {/* {totalPriceList?.length > 3 && <>{">"}</>} */}
                        </FlightPriceContent>
                        {flightInfo !== true && (
                              <FlightDetailButton
                                    onClick={() => setStatus(!status)}
                              >
                                    Flight Details
                              </FlightDetailButton>
                        )}
                  </FlightPriceContainer>
            </>
      );
};

export default FlightPrice;
