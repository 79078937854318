import styled from "styled-components";

export const IndicatorContainer = styled.div`
      position: relative;
`;
export const IndicatorItemTitle = styled.h3`
      color: ${(props) => (props.show ? "#FFF" : props.theme.primary)};
      font-size: 18px;
      font-weight: 500;
      background-color: white;
      padding: 10px 0;
      border-radius: 50rem;
      background: ${(props) => (props.show ? props.theme.primary : "#FFF")};
`;
export const IndicatorItemIcon = styled.div`
      width: 66px;
      height: 66px;
      border-radius: 50%;
      border: ${(show) => (show ? "5px #dfe6f0 solid" : "5px #dfe6f0 solid")};
      background: ${(props) => (props.show ? props.theme.primary : "#9cb2cc")};
      line-height: 50px;
      margin: 0 auto;
      position: relative;
      display: block;

      span {
            color: ${(props) => (props.show ? props.theme.white : "#E6ECF2")};
            font-size: 37px;
      }
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0, "opsz" 24;
      }
`;
