import React from "react";

const MaleIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">man</span>
            </>
      );
};

export default MaleIcon;
