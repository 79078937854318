import React from "react";
import { MenuItemContainer } from "./styles";
import { Link } from "react-router-dom";

const MenuItem = ({ title, slug, location, onClick }) => {
      return (
            <>
                  <MenuItemContainer onClick={onClick} active={location}>
                        <Link to={slug}>{title}</Link>
                  </MenuItemContainer>
            </>
      );
};

export default MenuItem;
