import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../frontend/login";
import HomePage from "../frontend/home";
import { HotelDetail, HotelPage, HotelSearch } from "../frontend/hotels";
import PackagesPage from "../frontend/packages";
import Dashboard from "../frontend/dashboard";
import CheckoutPage from "../frontend/checkout";
import ReviewPage from "../frontend/review";
import PackageDetail from "../frontend/package_details";
import FlightSearch from "../frontend/flight/FlightSearch";
import PrivacyPolicy from "../frontend/privacy";
import TermsAndConditions from "../frontend/terms";
import BaggagePolicy from "../frontend/baggage-policy";
import RefundPolicy from "../frontend/refund-policy";
import FlightReview from "../frontend/flight_reviews";
import FlightConfirmation from "../frontend/flight_confirmations";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/" exact element={<HomePage />} />

                        {/* Flight Page */}
                        <Route path="/flight" element={<HomePage />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route
                              path="/flight/search"
                              element={<FlightSearch />}
                        />
                        <Route
                              path="/flight/:optionId/:reviewId"
                              element={<FlightReview />}
                        />

                        {/* Flight Confirmation */}
                        <Route
                              path="/flights/confirmation/:bookingId"
                              element={<FlightConfirmation />}
                        />

                        {/* Hotel Page */}
                        <Route path="/hotel" element={<HotelPage />} />
                        <Route path="/hotel/search" element={<HotelSearch />} />
                        <Route
                              path="/hotel/:hotelId"
                              element={<HotelDetail />}
                        />

                        {/* Package Page */}
                        <Route path="/packages" element={<PackagesPage />} />
                        <Route
                              path="/packages/:packageId"
                              element={<PackageDetail />}
                        />

                        {/* Checkout */}
                        <Route path="/checkout" element={<CheckoutPage />} />
                        <Route path="/review" element={<ReviewPage />} />

                        {/* Privacy Policy */}
                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />

                        {/* Terms & Condition */}
                        <Route
                              path="/terms-and-conditions"
                              element={<TermsAndConditions />}
                        />

                        {/* Refund Policy */}
                        <Route
                              path="/refund-policy"
                              element={<RefundPolicy />}
                        />

                        {/* Baggage Policy */}
                        <Route
                              path="/baggage-policy"
                              element={<BaggagePolicy />}
                        />

                        <Route path="/login" element={<Login />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
