import styled from "styled-components";

export const HotelHeading = styled.h3`
      font-weight: 600;
      margin-bottom: ${(props) => props.marginBottom || "20px"};
      margin-top: 3rem;
`;

export const LocationMap = styled.div`
      border-radius: 10px;
      height: 400px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      overflow: hidden;
`;

export const HotelAddress = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 20px;
`;

export const HotelAddressIcon = styled.div`
      display: flex;
      align-items: center;
      gap: 0.25rem;

      svg {
            font-size: 22px;
            color: #ffa918;
      }
`;

export const HotelFeatureImage = styled.div`
      height: 226px;
      margin-bottom: 20px;
      border-radius: 5px;
      overflow: hidden;

      &:last-child {
            margin-bottom: 0;
      }

      img {
            width: 100%;
            height: 100%;
      }
`;
