import styled from "styled-components";

export const FormCheckboxContainer = styled.div`
      display: flex;
      align-items: center;

      .form-check-input {
            &:checked {
                  background-color: ${(props) => props.theme.primary};
                  border-color: ${(props) => props.theme.primary};
            }
      }
      span {
            color: ${(props) => props.theme.primary};
      }
`;
