import React, { useRef, useState } from "react";
import { OwnershipRegsiterContainer } from "../OwnershipRegsiter/styles";
import CommonButton from "../../../Common/CommonButton";
import {
      Col,
      FormControl,
      Row,
      Form,
      FormLabel,
      FormSelect,
} from "react-bootstrap";
import {
      LoginFormSection,
      LoginInput,
} from "../../../../frontend/login/styles";
import { UserIcon, CompanyIcon, BookIcon } from "../../../../icons";
import CustomFlieInput from "../../../form/CustomFileInput";

const CompanyRegister = ({
      handleRegisterForm,
      visibleBlock,
      companyName,
      setCompanyName,
      ownerName,
      setOwnerName,
      gstNumber,
      setGstNumber,
      setGstFile,
      gstStatus,
      setGstStatus,
}) => {
      const [activeForm, setActiveForm] = useState("login");

      const regFormRef = useRef(null);

      // Function to handle checkbox change
      const handleCheckboxChange = (event) => {
            setGstStatus(event.target.checked);
      };

      return (
            <>
                  <OwnershipRegsiterContainer
                        className="d-flex"
                        show={visibleBlock === "company"}
                  >
                        <LoginFormSection
                              onSubmit={(event) =>
                                    handleRegisterForm(event, "communication")
                              }
                              ref={regFormRef}
                              style={{
                                    transform:
                                          activeForm === "register"
                                                ? "translateX(-450px)"
                                                : "translateX(0px)",
                              }}
                              className="col-lg-10"
                        >
                              <Row className="px-0 g-3">
                                    <Col lg={5}>
                                          <FormLabel>
                                                Company Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>{" "}
                                          </FormLabel>
                                          <LoginInput className="ps-2 ">
                                                <UserIcon />
                                                <FormControl
                                                      type="text"
                                                      value={companyName}
                                                      onChange={(event) =>
                                                            setCompanyName(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </LoginInput>
                                    </Col>
                                    <Col lg={5}>
                                          <FormLabel>
                                                Type of Ownership{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>{" "}
                                          </FormLabel>
                                          <LoginInput className="ps-2 ">
                                                <CompanyIcon />
                                                <FormSelect
                                                      onChange={(event) =>
                                                            setOwnerName(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      defaultValue={ownerName}
                                                >
                                                      <option value="">
                                                            Select One
                                                      </option>
                                                      <option value="Proprietorship">
                                                            Proprietorship
                                                      </option>
                                                      <option value="General Partnership">
                                                            General Partnership
                                                      </option>
                                                      <option value="Private Limited">
                                                            Private Limited
                                                      </option>
                                                      <option value="Limited">
                                                            Limited
                                                      </option>
                                                      <option value="Limited Liability Company (LLC)">
                                                            Limited Liability
                                                            Company (LLC)
                                                      </option>
                                                      <option value="Limited Liability Partnership (LLP)">
                                                            Limited Liability
                                                            Partnership (LLP)
                                                      </option>
                                                </FormSelect>
                                          </LoginInput>
                                    </Col>
                                    <Col lg={2}>
                                          <div className="d-flex gap-4 h-100 align-items-center">
                                                <Form.Check // prettier-ignore
                                                      type="checkbox"
                                                      label="GST"
                                                      checked={gstStatus}
                                                      onChange={
                                                            handleCheckboxChange
                                                      }
                                                      className="mb-0"
                                                />
                                          </div>
                                    </Col>
                                    <Col lg={5}>
                                          <FormLabel>GST Number</FormLabel>
                                          <LoginInput
                                                className="ps-2 "
                                                disabled={!gstStatus}
                                          >
                                                <BookIcon />
                                                <FormControl
                                                      type="text"
                                                      placeholder="GST Number (eg: 888   )"
                                                      disabled={!gstStatus}
                                                      value={gstNumber}
                                                      onChange={(event) =>
                                                            setGstNumber(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </LoginInput>
                                    </Col>

                                    <Col lg={5}>
                                          <FormLabel>
                                                Upload GST Certificate
                                          </FormLabel>
                                          <LoginInput
                                                className="ps-2"
                                                disabled={!gstStatus}
                                          >
                                                <CustomFlieInput
                                                      placeholder={
                                                            "Upload GST Certificate"
                                                      }
                                                      onChange={(event) =>
                                                            setGstFile(
                                                                  event.target
                                                                        .files[0]
                                                            )
                                                      }
                                                      disabled={!gstStatus}
                                                />
                                          </LoginInput>
                                    </Col>
                                    <Col
                                          lg={12}
                                          className="p-0 d-flex align-items-center justify-content-center gap-3 mt-5"
                                    >
                                          <CommonButton
                                                title={"Back"}
                                                button={true}
                                                color={"#555"}
                                                background={"#DFE6F0"}
                                                handleClick={(event) =>
                                                      handleRegisterForm(
                                                            event,
                                                            "owner"
                                                      )
                                                }
                                          />
                                          <CommonButton
                                                title={"Continue"}
                                                button={true}
                                                type={"submit"}
                                          />
                                    </Col>
                              </Row>
                        </LoginFormSection>
                  </OwnershipRegsiterContainer>
            </>
      );
};

export default CompanyRegister;
