import React from "react";

const ArrowForwardIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        arrow_forward_ios
                  </span>
            </>
      );
};

export default ArrowForwardIcon;
