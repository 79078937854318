import React from "react";
import {
      DashboardListContainer,
      DashboardListTitle,
} from "../DashboardList/styles";
import { DepartureListItem, DepartureListWrapper } from "./style";

const DepartureList = ({ lists, departures, searchDetails, handleClick }) => {
      return (
            <>
                  {lists?.map((item, index) => (
                        <DashboardListContainer key={index}>
                              <DashboardListTitle>
                                    {item.title}
                                    {searchDetails?.leaving_from?.city}
                              </DashboardListTitle>
                              <DepartureListWrapper className="list-inline mb-0">
                                    {departures?.map((item, index) => (
                                          <DepartureListItem
                                                key={index}
                                                className={`${
                                                      item?.checked
                                                            ? "active"
                                                            : ""
                                                }`}
                                          >
                                                <input
                                                      type="checkbox"
                                                      class="form-check-input"
                                                      value={item?.value}
                                                      onClick={() =>
                                                            handleClick(index)
                                                      }
                                                />
                                                <div>
                                                      <img
                                                            src={
                                                                  item?.checked
                                                                        ? item?.imageWhite
                                                                        : item?.image
                                                            }
                                                            alt=""
                                                      />
                                                </div>
                                                <div className="text-muted">
                                                      {item.title}
                                                </div>
                                          </DepartureListItem>
                                    ))}
                              </DepartureListWrapper>
                        </DashboardListContainer>
                  ))}
            </>
      );
};

export default DepartureList;
