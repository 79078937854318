import React from "react";

const MobileIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        phone_iphone
                  </span>
            </>
      );
};

export default MobileIcon;
