import React, { useEffect, useState } from "react";
import {
      PriceSummary,
      PriceTravellerItem,
} from "../../../../frontend/checkout/styles";
import { AdultIcon, ChildIcon, InfantIcon } from "../../../../icons";

const PriceSummaryBox = ({
      priceInfo,
      totalAmount,
      setTotalAmount,
      searchQuery,
      serviceList,
}) => {
      const [mealAmount, setMealAmount] = useState(0);
      const [baggageAmount, setBaggageAmount] = useState(0);
      const [otherServiceAmount, setOtherServiceAmount] = useState(0);
      const [seatDetailAmount, setSeatDetailAmount] = useState(0);

      const findingAmount = () => {
            let totalMealAmount = 0;
            let totalBaggageAmount = 0;
            let totalOtherAmount = 0;
            let totalSeatAmount = 0;

            if (serviceList) {
                  Object.entries(serviceList)?.forEach(
                        ([destination, passengers]) => {
                              Object.entries(passengers).forEach(
                                    ([passenger, services]) => {
                                          if (
                                                services?.Meal &&
                                                services.Meal?.amount
                                          ) {
                                                totalMealAmount += parseFloat(
                                                      services.Meal.amount
                                                );
                                          }
                                          if (
                                                services?.Baggage &&
                                                services?.Baggage?.amount
                                          ) {
                                                totalBaggageAmount +=
                                                      parseFloat(
                                                            services.Baggage
                                                                  .amount
                                                      );
                                          }
                                          if (
                                                services?.OtherService &&
                                                services?.OtherService?.amount
                                          ) {
                                                totalOtherAmount += parseFloat(
                                                      services.OtherService
                                                            .amount
                                                );
                                          }
                                          if (
                                                services?.Seat &&
                                                services?.Seat?.amount
                                          ) {
                                                totalSeatAmount += parseFloat(
                                                      services.Seat.amount
                                                );
                                          }
                                    }
                              );
                        }
                  );
            }
            setMealAmount(totalMealAmount);
            setBaggageAmount(totalBaggageAmount);
            setSeatDetailAmount(totalSeatAmount);
            setOtherServiceAmount(totalOtherAmount);
      };

      useEffect(() => {
            const storedTotalAmount = localStorage.getItem("totalAmount");
            if (storedTotalAmount) {
                  setTotalAmount(storedTotalAmount);
            }
            findingAmount();
      }, [serviceList]);

      return (
            <>
                  <PriceSummary className="">
                        <div className="price__box mb-4">
                              <div className="price__heading d-flex algin-item-center mb-3 ">
                                    <div className="price__title w-75">
                                          Fare Summary
                                    </div>
                                    <div className="price__people d-flex gap-3">
                                          <PriceTravellerItem>
                                                <AdultIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {
                                                            searchQuery?.paxInfo
                                                                  ?.ADULT
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                          <PriceTravellerItem>
                                                <ChildIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {
                                                            searchQuery?.paxInfo
                                                                  ?.CHILD
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                          <PriceTravellerItem>
                                                <InfantIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {
                                                            searchQuery?.paxInfo
                                                                  ?.INFANT
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                    </div>
                              </div>
                              <ul className="price__list list-inline mb-0">
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Base Fare
                                          </div>
                                          <div className="price__item--amount">
                                                ₹ {priceInfo?.fC?.BF}
                                          </div>
                                    </li>
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Taxes & Fees
                                          </div>
                                          <div className="price__item--amount">
                                                ₹ {priceInfo?.fC?.TAF}
                                          </div>
                                    </li>
                                    {mealAmount >= 0 && (
                                          <li className="price__item  d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Meal
                                                </div>
                                                <div className="price__item--amount">
                                                      ₹ {mealAmount}
                                                </div>
                                          </li>
                                    )}
                                    {baggageAmount >= 0 && (
                                          <li className="price__item  d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Baggage
                                                </div>
                                                <div className="price__item--amount">
                                                      ₹ {baggageAmount}
                                                </div>
                                          </li>
                                    )}
                                    {otherServiceAmount >= 0 && (
                                          <li className="price__item  d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Other Services
                                                </div>
                                                <div className="price__item--amount">
                                                      ₹ {otherServiceAmount}
                                                </div>
                                          </li>
                                    )}
                                    {seatDetailAmount >= 0 && (
                                          <li className="price__item  d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Seat
                                                </div>
                                                <div className="price__item--amount">
                                                      ₹ {seatDetailAmount}
                                                </div>
                                          </li>
                                    )}
                              </ul>
                              <div className="total d-flex align-items-center mb-0">
                                    <div className="total__title  w-75">
                                          Grand Total
                                    </div>
                                    <div className="total__price">
                                          ₹ {totalAmount}
                                    </div>
                              </div>
                        </div>
                        <div className="support mb-4">
                              Flight support : +91 8766601888
                        </div>
                  </PriceSummary>
            </>
      );
};

export default PriceSummaryBox;
