import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { locations } from "../../data/locations";
import {
      DashboardButton,
      DashboardContainer,
      DashboardContent,
      DashboardMenu,
      DashboardMenuContent,
      DashboardTitle,
      FilterContainer,
} from "../dashboard/styles";
import { Button, Col, Row } from "react-bootstrap";
import HotelCard from "../../components/Frontend/hotel/HotelCard";
import HotelCardSkeleton from "../../components/Frontend/hotel/HotelCardSkeleton";
import DashboardList from "../../components/Dashboard/DashboardList";
import { airlines, departures, slider, stops } from "../../data/dashboard";

const HotelSearch = () => {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);

      const [checkinDate, setCheckinDate] = useState(
            searchParams.get("checkinDate")
      );
      const [nationality, setNationality] = useState(106);
      const [destination, setDestination] = useState(
            searchParams.get("destination")
      );
      const [adult, setAudlt] = useState(searchParams.get("adult"));
      const [checkoutDate, setCheckoutDate] = useState(
            searchParams.get("checkoutDate")
      );

      const [loading, setLoading] = useState(false);
      const [hotels, setHotels] = useState([]);
      const [totalHotels, setTotalHotels] = useState(0);

      const handleData = async () => {
            setLoading(true);
            const location = locations.filter(
                  (item) => item.title == destination
            );
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotel-searchquery-list`,
                        {
                              searchQuery: {
                                    checkinDate: checkinDate,
                                    checkoutDate: checkoutDate,
                                    roomInfo: [
                                          {
                                                numberOfAdults: adult,
                                                numberOfChild: 0,
                                                childAge: [],
                                          },
                                    ],
                                    searchCriteria: {
                                          city: location[0].value,
                                          nationality: nationality,
                                          currency: "INR",
                                    },
                                    searchPreferences: {
                                          ratings: [3, 4, 5],
                                          fsc: true,
                                    },
                              },
                              sync: true,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setInterval(() => {
                              setHotels(response.data.searchResult.his);
                              setTotalHotels(response.data.searchResult.size);
                        }, 1000);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            handleData();
      }, []);

      return (
            <>
                  <DashboardContainer>
                        <DashboardMenu className="">
                              <DashboardTitle className="mb-4">
                                    Filter
                              </DashboardTitle>
                              <DashboardMenuContent>
                                    <FilterContainer>
                                          <DashboardList lists={stops} />
                                          <DashboardList lists={departures} />
                                          <DashboardList lists={slider} />
                                          <DashboardList lists={airlines} />
                                          <DashboardButton>
                                                <Button>See More</Button>
                                          </DashboardButton>
                                    </FilterContainer>
                              </DashboardMenuContent>
                        </DashboardMenu>
                        <DashboardContent>
                              <Row>
                                    <Col lg={12}>
                                          <h4>
                                                {totalHotels} Hotels In{" "}
                                                {destination}
                                          </h4>
                                    </Col>
                              </Row>
                              <Row>
                                    {!loading ? (
                                          <>
                                                <Col lg={12}>
                                                      {hotels?.map(
                                                            (hotel, index) => (
                                                                  <HotelCard
                                                                        hotel={
                                                                              hotel
                                                                        }
                                                                        key={
                                                                              index
                                                                        }
                                                                  />
                                                            )
                                                      )}
                                                </Col>
                                          </>
                                    ) : (
                                          <Col lg={12}>
                                                <HotelCardSkeleton />
                                                <HotelCardSkeleton />
                                                <HotelCardSkeleton />
                                                <HotelCardSkeleton />
                                                <HotelCardSkeleton />
                                          </Col>
                                    )}
                              </Row>
                        </DashboardContent>
                  </DashboardContainer>
            </>
      );
};

export default HotelSearch;
