import { Link } from "react-router-dom";
import styled from "styled-components";

export const SocialMediaIcon = styled(Link)`
      color: ${(props) => props.theme.textColor};
      font-size: 20px;
      transition: all 0.4 ease-in-out;
      &:hover {
            color: ${(props) => props.theme.primary};
      }
`;
