import styled from "styled-components";

export const FlightLoaderContainer = styled.div`
      position: relative;
      width: 100%;
      height: 20rem;
      max-width: 100%;
`;

export const FlightShadow = styled.div`
      position: absolute;
      bottom: 1rem;
      left: calc(54% - 4rem);
      width: 8rem;
      height: 1rem;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.15);
      animation: takeOffShadow 2s ease-in-out,
            flightShadow 5s ease-in-out 2s infinite alternate;
`;
