import styled from "styled-components";

export const SliderPrevious = styled.div`
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 32px;
      font-size: 45px;
      color: #333;
      z-index: 1;
      cursor: pointer;
      background-color: white;
      padding: 0 10px;
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 50rem;
      width: 45px;
      justify-content: center;
      opacity: 0;
      transition: 0.3s ease-in;

      span {
            margin-right: -8px;
      }
`;

export const SliderNext = styled.div`
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 32px;
      font-size: 45px;
      color: #333;
      z-index: 1;
      cursor: pointer;
      background-color: white;
      padding: 0 10px;
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 50rem;
      opacity: 0;
      transition: 0.3s ease-in;
`;

export const SliderContainer = styled.div`
      position: relative;
      width: 100%;
      /* height: 420px; */
      overflow: hidden;
      border-radius: 5px;

      &:hover {
            ${SliderNext}, ${SliderPrevious} {
                  opacity: 1;
                  transition: 0.3s ease-in;
            }
      }
`;

export const SliderContent = styled.div`
      height: ${(props) => props.height || "420px"};
      border-radius: 5px;
`;

export const SliderImage = styled.img`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
`;

export const SliderDotContainer = styled.div`
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      z-index: 9999;
      position: relative;
`;

export const SliderDotItem = styled.div`
      margin: 0 3px;
      cursor: pointer;
      font-size: 20px;

      &.active {
            color: aliceblue;
      }
`;
