import React from "react";

const CountryIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">flag</span>
            </>
      );
};

export default CountryIcon;
