import React from "react";
import {
      BannerContainer,
      BannerContent,
      BannerSection,
      BannerWrapper,
} from "./styles";
import { Container } from "react-bootstrap";
import CommonButton from "../../../Common/CommonButton";

const BannerBox = ({ banners, children }) => {
      return (
            <>
                  <BannerSection>
                        {banners.map((banner, index) => (
                              <BannerContainer
                                    style={{
                                          backgroundImage: `url(${banner.image})`,
                                    }}
                                    key={index}
                              >
                                    <Container className="position-relative h-100">
                                          <BannerWrapper>
                                                <BannerContent>
                                                      <h2
                                                            className="banner__title mb-4"
                                                            style={{
                                                                  color: "#fff",
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                  __html: banner.title,
                                                            }}
                                                      ></h2>
                                                      <CommonButton title="book now" />
                                                </BannerContent>
                                                {children}
                                          </BannerWrapper>
                                    </Container>
                              </BannerContainer>
                        ))}
                  </BannerSection>
            </>
      );
};

export default BannerBox;
