import React from "react";
import { DashboardContainer } from "../dashboard/styles";
import { PrivacyContainer } from "./style";

const PrivacyPolicy = () => {
      return (
            <>
                  <DashboardContainer>
                        <PrivacyContainer>
                              <h3>Privacy Policy</h3>
                              <p>
                                    As a registered member of Aircasa, you will
                                    be entitled to savings and benefits on
                                    airfares, hotel reservations, and car
                                    rentals, among other travel services. In
                                    addition, look forward to receiving monthly
                                    newsletters and exclusive promotions
                                    offering special deals. That's why we've
                                    provided this Privacy Policy, which sets
                                    forth our policies regarding the collection,
                                    use and protection of the personal
                                    information of those using the Aircasa
                                    website. Personal information means
                                    information that can be linked to a specific
                                    individual, such as name, address, telephone
                                    number, e - mail address, and credit card
                                    number. We encourage you to review our
                                    Privacy Policy, and become familiar with it,
                                    but you should know that we do not sell or
                                    rent our customers' personal information to
                                    third parties. Please note that we review
                                    our Privacy Policy from time to time, and we
                                    may make periodic changes to the policy in
                                    connection with that review. Therefore, you
                                    may wish to bookmark this page and/or
                                    periodically review this page to make sure
                                    you have the latest version. Regardless of
                                    later updates, we will abide by the privacy
                                    practices described to you in this Privacy
                                    Policy at the time you provided us with your
                                    personal information.
                              </p>
                              <h3>
                                    What personal information we collect from
                                    you and how we use it?
                              </h3>
                              <p>
                                    When browsing our site, you are required to
                                    log on with your log in Id and password
                                    provided by your administration staff or by
                                    staff of Aircasa Pvt Ltd.
                              </p>
                              <ul>
                                    <li>
                                          <h4>Member Profile</h4>
                                          <p>
                                                As an Aircasa site member,
                                                administration staff can choose
                                                to complete traveler online
                                                profile by providing us with
                                                travel preferences, frequent -
                                                traveler or affinity numbers,
                                                credit card billing information
                                                and other personal information.
                                                This information is primarily
                                                used to assist you in making
                                                reservations quickly without
                                                having to type in the same
                                                information repeatedly. In order
                                                to purchase travel and/or
                                                related services through our
                                                site, user must provide us with
                                                personal information such as
                                                your name, Payment Method
                                                Credit/ Advance Float or credit
                                                card number and expiration date,
                                                credit card billing address,
                                                Date of Birth in case of child,
                                                telephone number, e - mail
                                                address and the name or names of
                                                the person(s) traveling .We may
                                                also ask you for other personal
                                                information, such as your
                                                frequent traveler numbers. We
                                                require this information to
                                                share with the service providers
                                                like airlines, hotels etc to
                                                process, fulfill and confirm
                                                your reservations and
                                                transactions and keep you
                                                informed of each transaction's
                                                status. If you are making a
                                                reservation for one or more
                                                travelers other than yourself,
                                                you must confirm and represent
                                                that each of these other
                                                travelers have agreed, in
                                                advance, that you may disclose
                                                their personal information to
                                                us.
                                          </p>
                                    </li>
                                    <li>
                                          <h4>
                                                Automatic Logging of Session
                                                Data
                                          </h4>
                                          <p>
                                                We automatically log generic
                                                information about your
                                                computer's connection to the
                                                Internet, which we call "session
                                                data", that is anonymous and not
                                                linked to any personal
                                                information. Session data
                                                consists of things such as IP
                                                address, operating system and
                                                type of browser software being
                                                used and the activities
                                                conducted by the user while on
                                                Aircasa website. An IP address
                                                is a number that lets computers
                                                attached to the internet, such
                                                as our web servers, know where
                                                to send data back to the user,
                                                such as the pages of the site
                                                the user wishes to view. We
                                                collect session data because it
                                                helps us analyze such things as
                                                what items visitors are likely
                                                to click on most, the way
                                                visitors are clicking through
                                                the site, how many visitors are
                                                surfing to various pages on the
                                                site, how long visitors to the
                                                site are staying and how often
                                                they are visiting. It also helps
                                                us diagnose problems with our
                                                servers and lets us better
                                                administer our systems. Although
                                                such information does not
                                                identify any visitor personally,
                                                it is possible to determine from
                                                an IP address a visitor's
                                                Internet Service Provider (ISP),
                                                and the approximate geographic
                                                location of his or her point of
                                                connectivity.
                                          </p>
                                    </li>
                              </ul>
                              <h3>
                                    With whom (if anyone) your personal
                                    information is shared?
                              </h3>
                              <p>
                                    When you reserve or purchase travel services
                                    through Aircasa PVT. LTD., we must provide
                                    certain of your personal information to the
                                    airline, hotel, car - rental agency, travel
                                    agency or other involved third party to
                                    enable the successful fulfillment of your
                                    travel arrangements. However, we do not sell
                                    or trade individual customer names or other
                                    personal information to third parties
                                    without the consent of the user. Aircasa
                                    uses non - personally identifiable
                                    information in aggregate form to build
                                    higher quality, more useful online services
                                    by performing statistical analysis of the
                                    collective characteristics and behavior of
                                    our customers and visitors, and by measuring
                                    demographics and interests regarding
                                    specific areas of the Aircasa website.
                                    Information such as credit card details and
                                    net banking details are usually collected
                                    directly by the Payment Gateways and Banks
                                    and not by Aircasa. These details if stored
                                    or retained by us are shared only with
                                    government/statutory/security agencies to
                                    screen for fraudulent activities and with
                                    third parties only for the purpose of Cash
                                    Back & Discounts, Chargebacks, if
                                    applicable.
                              </p>
                              <h3>
                                    What safeguards we have in place to protect
                                    your personal information?
                              </h3>
                              <p>
                                    All payments on the Aircasa site is Verisign
                                    SSL secured. This means all personal
                                    information you provide to Aircasa PVT. LTD.
                                    is transmitted using SSL (Secure Socket
                                    Layer) encryption. SSL is a proven coding
                                    system that lets your browser automatically
                                    encrypt, or scramble, data before you send
                                    it to us. The same process happens when you
                                    make travel purchases on the Aircasa site.
                              </p>
                              <h3>
                                    What other information should I know about
                                    my privacy?
                              </h3>
                              <p>
                                    Aircasa site contains links to other web
                                    sites. Please note that when you click on
                                    one of these links, you are entering another
                                    web site for which Aircasa PVT. LTD. has no
                                    responsibility. We encourage you to read the
                                    privacy statements of all such sites as
                                    their policies may be materially different
                                    from this Privacy Policy. Of course, you are
                                    solely responsible for maintaining the
                                    secrecy of your passwords, and your Aircasa
                                    site membership account information. Please
                                    be very careful, responsible, and alert with
                                    this information, especially whenever you're
                                    online. The Linked Sites are not under the
                                    control of Aircasa and Aircasa is not
                                    responsible for the contents of any Linked
                                    Site, including without limitation any link
                                    contained in a Linked Site, or any changes
                                    or updates to a Linked Site. Aircasa is not
                                    responsible for any form of transmission,
                                    whatsoever, received by the User from any
                                    Linked Site. Aircasa is providing these
                                    links to the User only as a convenience, and
                                    the inclusion of any link does not imply
                                    endorsement by Aircasa or the Website of the
                                    Linked Sites or any association with its
                                    operAircasars or owners including the legal
                                    heirs or assigns thereof. Aircasa is not
                                    responsible for any errors, omissions or
                                    representations on any Linked Site. Aircasa
                                    does not endorse any advertiser on any
                                    Linked Site in any manner. The Users are
                                    requested to verify the accuracy of all
                                    information on their own before undertaking
                                    any reliance on such information. In
                                    addition to the circumstances described
                                    above, Aircasa PVT. LTD. may disclose the
                                    Aircasa site member information if required
                                    to do so by law, court order, as requested
                                    by other government or law enforcement
                                    authority, or in the good faith belief that
                                    disclosure is otherwise necessary or
                                    advisable including, without limitation, to
                                    protect the rights or properties of Aircasa
                                    PVT. LTD. or any or all of its affiliates,
                                    associates, employees, directors or officers
                                    or when we have reason to believe that
                                    disclosing the information is necessary to
                                    identify, contact or bring legal action
                                    against someone who may be causing
                                    interference with our rights or properties,
                                    whether intentionally or otherwise, or when
                                    anyone else could be harmed by such
                                    activities. In addition, if Aircasa PVT.
                                    LTD. or substantially all of its assets are
                                    acquired, our customer information will most
                                    likely also be transferred in connection
                                    with such acquisition This policy is
                                    effective as of January 1st January 2012.
                                    Any material changes in the way Aircasa PVT.
                                    LTD. uses personal information will be
                                    described in future versions of this Privacy
                                    Statement.
                              </p>
                        </PrivacyContainer>
                  </DashboardContainer>
            </>
      );
};

export default PrivacyPolicy;
