// src/ToggleButton.js
import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";

export const rotateBounce = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-180deg) scale(1.2); }

`;
// Styled button with animations
export const ToggleButtonWrapper = styled.button`
      position: relative;
      background-color: ${(props) =>
            props.active ? props.theme.primary : "#f0f0f0"};
      width: 65px;
      height: 31px;
      padding: 15px 32px;
      font-size: 16px;
      border: none;
      border-radius: 40px;
      cursor: pointer;
      animation: ${(props) =>
            props.active &&
            css`
                  ${rotateBounce} 0.5s ease
            `};
      position: relative;
      transition: background-color 0.5s;
      &:after {
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            left: 3px;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: ${(props) =>
                  props.active ? "#f0f0f0" : props.theme.primary};
      }
`;

export const ToogleText = styled.div`
      font-size: 12px;
`;
export const ToogleButtonContainer = styled.div`
      margin-bottom: 8px;
      margin: ${(props) => props.margin};
`;
