import React, { useState } from "react";
import {
      Indicator,
      RegisterContain,
      RegisterContainer,
      RegsiterContent,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import { LoginContainer } from "../login/styles";
import IndicatorItem from "../../components/Frontend/regsiter/IndicatorItem";
import { UserIcon, CompanyIcon, CommunicationIcon } from "../../icons";
import OwnershipRegsiter from "../../components/Frontend/regsiter/OwnershipRegsiter";
import CompanyRegister from "../../components/Frontend/regsiter/CompanyRegister";
import CommunicationRegsiter from "../../components/Frontend/regsiter/CommunicationRegsiter";

const Register = () => {
      const [animate, setAnimate] = useState(false);
      const [solid, setSolid] = useState(false);

      const [animate2, setAnimate2] = useState(false);
      const [solid2, setSolid2] = useState(false);

      const [visibleBlock, setVisibleBlock] = useState("owner");

      const handleOwnerForm = (event, block) => {
            event.preventDefault();
            if (!solid) {
                  setAnimate(true);
                  setTimeout(() => {
                        setAnimate(false);
                        setSolid(true);
                  }, 2000); // Reset animation and make line solid after 2s
            }

            setVisibleBlock(block);
      };
      const handleCompanyForm = (event, block) => {
            event.preventDefault();
            if (!solid2) {
                  setAnimate2(true);
                  setTimeout(() => {
                        setAnimate2(false);
                        setSolid2(true);
                  }, 2000); // Reset animation and make line solid after 2s
            }

            setVisibleBlock(block);
      };

      const [gender, setGender] = useState("");
      const [dateOfBirth, setDateOfBirth] = useState("");

      // Company Details
      const [companyName, setCompanyName] = useState("");
      const [ownerType, setOwnerType] = useState("");
      const [gstStatus, setGstStatus] = useState("");
      const [gstNumber, setGstNumber] = useState("");
      const [gstFile, setGstFile] = useState("");

      return (
            <>
                  <LoginContainer>
                        <RegsiterContent>
                              <RegisterContain>
                                    <Col lg={12}>
                                          <Row className="justify-content-center">
                                                <Col lg={10}>
                                                      <Indicator>
                                                            <Col lg={4}>
                                                                  <IndicatorItem
                                                                        title={
                                                                              "Ownership Details"
                                                                        }
                                                                        icon={
                                                                              <UserIcon />
                                                                        }
                                                                        showIcon={
                                                                              true
                                                                        }
                                                                        animate={
                                                                              animate
                                                                        }
                                                                        solid={
                                                                              solid
                                                                        }
                                                                        visibleBlock={
                                                                              visibleBlock
                                                                        }
                                                                        name={
                                                                              "owner"
                                                                        }
                                                                  />
                                                            </Col>
                                                            <Col lg={4}>
                                                                  <IndicatorItem
                                                                        title={
                                                                              "Company Information"
                                                                        }
                                                                        icon={
                                                                              <CompanyIcon />
                                                                        }
                                                                        showIcon={
                                                                              true
                                                                        }
                                                                        animate={
                                                                              animate2
                                                                        }
                                                                        solid={
                                                                              solid2
                                                                        }
                                                                        visibleBlock={
                                                                              visibleBlock
                                                                        }
                                                                        name={
                                                                              "company"
                                                                        }
                                                                  />
                                                            </Col>
                                                            <Col lg={4}>
                                                                  <IndicatorItem
                                                                        title={
                                                                              "Communication Detail"
                                                                        }
                                                                        icon={
                                                                              <CommunicationIcon />
                                                                        }
                                                                        visibleBlock={
                                                                              visibleBlock
                                                                        }
                                                                        name={
                                                                              "communication"
                                                                        }
                                                                  />
                                                            </Col>
                                                      </Indicator>
                                                </Col>
                                          </Row>
                                          <RegisterContainer>
                                                <OwnershipRegsiter
                                                      handleRegisterForm={
                                                            handleOwnerForm
                                                      }
                                                      visibleBlock={
                                                            visibleBlock
                                                      }
                                                      gender={gender}
                                                      setGender={setGender}
                                                      setDateOfBirth={
                                                            setDateOfBirth
                                                      }
                                                      dateOfBirth={dateOfBirth}
                                                />
                                                <CompanyRegister
                                                      handleRegisterForm={
                                                            handleCompanyForm
                                                      }
                                                      visibleBlock={
                                                            visibleBlock
                                                      }
                                                      companyName={companyName}
                                                      setCompanyName={
                                                            setCompanyName
                                                      }
                                                      ownerName={ownerType}
                                                      setOwnerName={
                                                            setOwnerType
                                                      }
                                                      gstFile={gstFile}
                                                      setGstFile={setGstFile}
                                                      gstNumber={gstNumber}
                                                      setGstNumber={
                                                            setGstNumber
                                                      }
                                                      gstStatus={gstStatus}
                                                      setGstStatus={
                                                            setGstStatus
                                                      }
                                                />
                                                <CommunicationRegsiter
                                                      handleRegisterForm={
                                                            handleCompanyForm
                                                      }
                                                      visibleBlock={
                                                            visibleBlock
                                                      }
                                                      companyName={companyName}
                                                      ownerType={ownerType}
                                                      gstStatus={gstStatus}
                                                      gstNumber={gstNumber}
                                                      gstFile={gstFile}
                                                      gender={gender}
                                                      dateOfBirth={dateOfBirth}
                                                />
                                          </RegisterContainer>
                                    </Col>
                              </RegisterContain>
                        </RegsiterContent>
                  </LoginContainer>
            </>
      );
};

export default Register;
