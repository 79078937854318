import React from "react";

const LocationHomeIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">distance</span>
            </>
      );
};

export default LocationHomeIcon;
