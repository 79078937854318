import React from "react";

const AeroplaneIcon = () => {
      return (
            <>
                  <svg
                        width="26"
                        height="22"
                        viewBox="0 0 26 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path
                              d="M7.30766 21.8821L9.87045 22L18.2008 12.6316L23.1217 12.6607C23.1217 12.6607 25.9476 12.3501 25.9993 11.042C26.051 9.73389 23.2234 9.34726 23.2234 9.34726L18.3326 9.44403L10.5616 0.0158346L7.98992 0L12.2533 9.53596L4.22842 9.84033L1.77618 6.30702L0.283428 6.19925L1.21684 10.6272L0 15.4036L1.62849 15.3794L4.21693 11.8698L12.2245 12.2929L7.30766 21.8821Z"
                              fill="#224082"
                        />
                  </svg>
            </>
      );
};

export default AeroplaneIcon;
