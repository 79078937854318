export const locations = [
      {
            title: "Mumbai",
            value: "699261",
      },
      {
            title: "Badharamal",
            value: "59290",
      },
      {
            title: "Kudal",
            value: "90560",
      },
];

export const airlines = [
      {
            title: "Mumbai (BOM)",
            value: "BOM",
      },
      {
            title: "Delhi (DEL)",
            value: "DEL",
      },
      {
            title: "Jaipur (JAI)",
            value: "JAI",
      },
      {
            title: "Pune (PNQ)",
            value: "PNQ",
      },
      {
            title: "Patna (PAT)",
            value: "PAT",
      },
      {
            title: "Chhenai (MAA)",
            value: "MAA",
      },
];
