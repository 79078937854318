import React from "react";

const CompanyIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">apartment</span>
            </>
      );
};

export default CompanyIcon;
