import React from "react";
import {
      FlightTripPriceContainer,
      FlightTripPriceContent,
      FlightTripPriceItem,
} from "./style";
import { FormControl } from "react-bootstrap";

const FlightTripPrice = ({
      totalPriceList,
      setFareId,
      returnStatus,
      fareId,
      moreFare,
      searchDetail,
}) => {
      const handlePrice = (item) => {
            setFareId(item);
      };

      const calculateTotalAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.ADULT?.fC?.BF * searchDetail?.adult +
                                    currentItem?.ADULT?.fC?.TAF *
                                          searchDetail?.adult
                        );
            }
            if (currentItem?.CHILD) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.CHILD?.fC?.BF *
                                    searchDetail?.children +
                                    currentItem?.CHILD?.fC?.TAF *
                                          searchDetail?.children
                        );
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.INFANT?.fC?.BF *
                                    searchDetail?.infants +
                                    currentItem?.INFANT?.fC?.TAF *
                                          searchDetail?.infants
                        );
            }
            return newAmount;
      };

      return (
            <>
                  <FlightTripPriceContainer returnStatus={returnStatus}>
                        <FlightTripPriceContent>
                              {totalPriceList
                                    ?.slice(
                                          0,
                                          moreFare ? totalPriceList?.length : 2,
                                          0
                                    )
                                    ?.map((item, index) => (
                                          <FlightTripPriceItem
                                                className={
                                                      item?.id === fareId?.id
                                                            ? "active"
                                                            : ""
                                                }
                                                key={index}
                                                onClick={() =>
                                                      handlePrice(item)
                                                }
                                                returnStatus={returnStatus}
                                          >
                                                <FormControl
                                                      type="radio"
                                                      value={item?.id}
                                                      name="option"
                                                      checked={
                                                            item?.id ===
                                                            fareId?.id
                                                                  ? true
                                                                  : false
                                                      }
                                                />
                                                <div className="price">
                                                      ₹{" "}
                                                      {calculateTotalAmount(
                                                            item?.fd
                                                      )}{" "}
                                                      <span>
                                                            {
                                                                  item?.fareIdentifier
                                                            }
                                                      </span>{" "}
                                                </div>
                                                <div className="summary">
                                                      {" "}
                                                      {item?.fd?.ADULT?.cc},
                                                      {item?.fd?.ADULT?.mI &&
                                                            "Free Meal,"}{" "}
                                                      {item?.fd?.ADULT?.rT ===
                                                            1 && "Refundable"}
                                                </div>
                                          </FlightTripPriceItem>
                                    ))}
                        </FlightTripPriceContent>
                  </FlightTripPriceContainer>
            </>
      );
};

export default FlightTripPrice;
