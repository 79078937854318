import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
      FlightInfoContainer,
      FlightInfoContent,
      FlightInfoDuration,
      FlightInfoLogo,
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "../../../Dashboard/FlightInfo/styles";
import { NumberFormatBase } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import FlightTripPrice from "../FlightTripPrice";
import { FlightLayoutPrice } from "./style";
import { FlightTripDetailButton } from "../FlightTripPrice/style";
import FlightTripAccordion from "../FlightTripAccordion";

const FlightLayout = ({ item, netFare, fareId, setFareId, searchDetail }) => {
      const previousSearch = useLocation().pathname;
      const previousQuery = useLocation().search;
      const previousLink = `${previousSearch}${previousQuery}`;

      const [status, setStatus] = useState(false);
      const [moreFare, setMoreFare] = useState(false);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const arrivalTime = (sI) => {
            const totalSi = sI.length;
            const lastSi = sI[totalSi - 1];
            return lastSi;
      };

      const checkPrice = (prices) => {
            const newPrice = prices.find(
                  (newItem) => newItem?.id === fareId?.id
            );
            if (newPrice) {
                  return newPrice;
            }
      };

      const calculateTotalAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.ADULT?.fC?.BF * searchDetail?.adult +
                                    currentItem?.ADULT?.fC?.TAF *
                                          searchDetail?.adult
                        );
            }
            if (currentItem?.CHILD) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.CHILD?.fC?.BF *
                                    searchDetail?.children +
                                    currentItem?.CHILD?.fC?.TAF *
                                          searchDetail?.children
                        );
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.INFANT?.fC?.BF *
                                    searchDetail?.infants +
                                    currentItem?.INFANT?.fC?.TAF *
                                          searchDetail?.infants
                        );
            }
            return newAmount;
      };

      const calculateTotalNetAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.ADULT?.fC?.NF * searchDetail?.adult
                        );
            }
            if (currentItem?.CHILD) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.CHILD?.fC?.NF *
                                    searchDetail?.children
                        );
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.INFANT?.fC?.NF *
                                    searchDetail?.infants
                        );
            }
            return newAmount;
      };

      return (
            <>
                  <Form>
                        <FlightInfoContainer padding="12px">
                              <FlightInfoContent>
                                    <FlightInfoTitle className="gap-2">
                                          <FlightInfoLogo>
                                                <img
                                                      src={`/images/AirlinesLogo/${item?.sI[0]?.fD?.aI?.code}.png`}
                                                      alt={
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.name
                                                      }
                                                />
                                          </FlightInfoLogo>
                                          <div className="title">
                                                <h4 className="pb-0 mb-0">
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.name
                                                      }
                                                </h4>
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                >
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.code
                                                      }{" "}
                                                      {item?.sI[0]?.fD?.fN}
                                                </div>
                                          </div>
                                    </FlightInfoTitle>
                                    <FlightInfoTime className="d-flex flex-column justify-content-center">
                                          <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                {formatTime(item?.sI[0]?.dt)} -{" "}
                                                {formatTime(
                                                      arrivalTime(item?.sI)?.at
                                                )}
                                          </FlightInfoTime>

                                          <div
                                                className="place d-flex gap-1 align-items-center"
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {searchDetail?.leavingFrom}
                                                <span
                                                      className="material-symbols-outlined"
                                                      style={{
                                                            fontSize: "15px",
                                                      }}
                                                >
                                                      east
                                                </span>
                                                {searchDetail?.destination}
                                          </div>
                                    </FlightInfoTime>
                                    <FlightInfoDuration>
                                          {`${
                                                calculateDuration(
                                                      item?.sI[0]?.duration
                                                ).hours
                                          }h ${
                                                calculateDuration(
                                                      item?.sI[0]?.duration
                                                ).minutes
                                          }m`}

                                          <div
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {item?.sI?.length === 1
                                                      ? "Non-stop"
                                                      : "1 Stop(s)"}
                                          </div>
                                    </FlightInfoDuration>
                                    <FlightInfoPrice>
                                          <div className="flight__info-price">
                                                ₹
                                                <NumberFormatBase
                                                      prefix={""}
                                                      thousandsGroupStyle="lakh"
                                                      thousandSeparator={true}
                                                      displayType={"text"}
                                                      value={
                                                            checkPrice(
                                                                  item?.totalPriceList
                                                            )
                                                                  ? calculateTotalAmount(
                                                                          checkPrice(
                                                                                item?.totalPriceList
                                                                          )?.fd
                                                                    )
                                                                  : calculateTotalAmount(
                                                                          item
                                                                                ?.totalPriceList[0]
                                                                                ?.fd
                                                                    )
                                                      }
                                                />
                                                {netFare && (
                                                      <span className="flight__info-net text-muted">{` | NET ${
                                                            checkPrice(
                                                                  item?.totalPriceList
                                                            )
                                                                  ? calculateTotalNetAmount(
                                                                          checkPrice(
                                                                                item?.totalPriceList
                                                                          )?.fd
                                                                    )
                                                                  : calculateTotalNetAmount(
                                                                          item
                                                                                ?.totalPriceList[0]
                                                                                ?.fd
                                                                    )
                                                      }`}</span>
                                                )}
                                          </div>
                                          {item?.totalPriceList[0]?.fd?.ADULT
                                                ?.sR <= 5 && (
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                      className="text-danger"
                                                >
                                                      {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                </div>
                                          )}
                                    </FlightInfoPrice>
                              </FlightInfoContent>
                              <FlightTripPrice
                                    setStatus={setStatus}
                                    status={status}
                                    setFareId={setFareId}
                                    fareId={fareId}
                                    totalPriceList={item?.totalPriceList}
                                    flightInfo={true}
                                    returnStatus={true}
                                    setMoreFare={setMoreFare}
                                    moreFare={moreFare}
                                    searchDetail={searchDetail}
                              />
                              <FlightLayoutPrice>
                                    {item?.totalPriceList?.length > 2 && (
                                          <FlightTripDetailButton
                                                fontSize="12px"
                                                onClick={() =>
                                                      setMoreFare(!moreFare)
                                                }
                                                type="button"
                                                className={`${
                                                      moreFare ? "active" : ""
                                                }`}
                                          >
                                                {!moreFare ? "More" : "Hide"}{" "}
                                                Fare
                                          </FlightTripDetailButton>
                                    )}

                                    <FlightTripDetailButton
                                          onClick={() => setStatus(!status)}
                                          type="button"
                                          className={`${
                                                status ? "active" : ""
                                          }`}
                                    >
                                          Flight Details
                                    </FlightTripDetailButton>
                              </FlightLayoutPrice>
                              {status && (
                                    <div className="bg-gray w-100">
                                          <FlightTripAccordion
                                                fareId={
                                                      checkPrice(
                                                            item?.totalPriceList
                                                      )
                                                            ? checkPrice(
                                                                    item?.totalPriceList
                                                              )
                                                            : item
                                                                    ?.totalPriceList[0]
                                                }
                                                items={item}
                                                setFareId={setFareId}
                                                searchDetail={searchDetail}
                                          />
                                    </div>
                              )}
                        </FlightInfoContainer>
                  </Form>
            </>
      );
};

export default FlightLayout;
