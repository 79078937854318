import React, { useState } from "react";
import { CustomFileInputContainer } from "./styles";
import AttachFileIcon from "../../../icons/AttachFileIcon";
import { CommonLineClamp } from "../../../styles/components/common/CommonStyle";

const CustomFlieInput = ({ placeholder, disabled }) => {
      const [fileName, setFileName] = useState("");

      const handleFileChange = (event) => {
            if (event.target.files.length > 0) {
                  setFileName(event.target.files[0].name);
            } else {
                  setFileName("");
            }
      };

      return (
            <>
                  <CustomFileInputContainer className="w-100">
                        <CommonLineClamp width={"100%"}>
                              <div className="file-input-wrapper w-100">
                                    <input
                                          type="file"
                                          id="file-input"
                                          className="file-input"
                                          hidden
                                          onChange={handleFileChange}
                                          disabled={disabled}
                                    />
                                    <label
                                          htmlFor="file-input"
                                          className="custom-button d-flex align-items-center gap-1 w-100"
                                          disabled={disabled}
                                    >
                                          <AttachFileIcon />
                                          {fileName || placeholder}
                                    </label>
                              </div>
                        </CommonLineClamp>
                  </CustomFileInputContainer>
            </>
      );
};

export default CustomFlieInput;
