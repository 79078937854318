import React from "react";
import {
      HotelItem,
      HotelItemContainer,
      HotelItemImage,
      HotelItemInfo,
      HotelItemOffer,
      HotelItemSummary,
      HotelItemTitle,
      HotelOfferItem,
      HotelSubItem,
      HotelSubItemPrice,
} from "./style";
import { Fade } from "react-reveal";
import { MapIcon } from "../../../../icons";
import HotelRating from "../HotelRating";
import { Link } from "react-router-dom";

const HotelCard = ({ hotel }) => {
      return (
            <>
                  <Fade>
                        <HotelItemContainer>
                              <HotelItem>
                                    <div className="d-flex">
                                          <HotelItemImage>
                                                <img
                                                      src={hotel.img[0].url}
                                                      alt=""
                                                />
                                          </HotelItemImage>
                                          <HotelItemInfo>
                                                <HotelItemTitle>
                                                      {hotel.name}
                                                      <HotelRating
                                                            num={hotel.rt}
                                                      />
                                                </HotelItemTitle>
                                                <HotelItemSummary>
                                                      <MapIcon /> {hotel.ad.adr}
                                                      , {hotel.ad.city.name}
                                                </HotelItemSummary>
                                                <HotelItemOffer>
                                                      <HotelOfferItem>
                                                            {
                                                                  hotel.pops[0]
                                                                        .fc[0]
                                                            }
                                                      </HotelOfferItem>
                                                      <HotelOfferItem>
                                                            {hotel.pt}
                                                      </HotelOfferItem>
                                                </HotelItemOffer>
                                          </HotelItemInfo>
                                    </div>
                                    <div className="mt-2 pe-2">
                                          <Link
                                                to={`/hotel/${hotel.id}`}
                                                className="border rounded-pill inline-block px-3 py-1"
                                          >
                                                Book Now
                                          </Link>
                                    </div>
                              </HotelItem>
                              {hotel.ops &&
                                    hotel.ops?.map((item, index) => (
                                          <HotelSubItem key={index}>
                                                <div>
                                                      <div>
                                                            {item.ris[0] &&
                                                                  item.ris[0]
                                                                        .rc}
                                                      </div>
                                                      {/* <HotelItemOffer>
                                                            <HotelOfferItem>
                                                                  {item
                                                                        .ris[0] &&
                                                                        item
                                                                              .ris[0]
                                                                              .mb}
                                                            </HotelOfferItem>
                                                      </HotelItemOffer> */}
                                                </div>
                                                <HotelSubItemPrice>
                                                      INR:{" ₹ "}
                                                      {item.ris[0] &&
                                                            item.ris[0].tp}
                                                </HotelSubItemPrice>
                                          </HotelSubItem>
                                    ))}
                        </HotelItemContainer>
                  </Fade>
            </>
      );
};

export default HotelCard;
