import React from "react";
import FlightInfo from "../../../Dashboard/FlightInfo";

const OnewayFlight = ({ flights, netFare, searchDetail, fullFare }) => {
      return (
            <>
                  {flights?.map((item, index) => (
                        <FlightInfo
                              item={item}
                              key={index}
                              netFare={netFare}
                              fullFare={fullFare}
                              searchDetail={searchDetail}
                              flights={flights}
                        />
                  ))}
            </>
      );
};

export default OnewayFlight;
