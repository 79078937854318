import React from "react";
import { FlightFooterContainer } from "./style";
import CommonButton from "../../../Common/CommonButton";
import { DoubleIcon } from "../../../../icons";

const FlightFooter = ({ loading, handleBack, loadingBack }) => {
      return (
            <>
                  <FlightFooterContainer>
                        <CommonButton
                              title={"Back"}
                              leftIcon={
                                    <DoubleIcon
                                          transform="rotate(180deg)"
                                          color="#FFF"
                                    />
                              }
                              padding={"8px 16px"}
                              type="button"
                              button={true}
                              handleClick={handleBack}
                              loading={loadingBack}
                        />
                        <CommonButton
                              title={"Continue Booking"}
                              icon={<DoubleIcon color="#FFF" />}
                              padding={"8px 30px"}
                              type="submit"
                              button={true}
                              loading={loading}
                        />
                  </FlightFooterContainer>
            </>
      );
};

export default FlightFooter;
