const getCurrentDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
};
export default getCurrentDate;

export const convertAmount = (amount) => {
      return parseFloat(amount).toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
      });
};

export const convertFloat = (amount) => {
      return parseFloat(amount).toFixed(2);
};
