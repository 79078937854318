import { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PublicRouter from "../router/PublicRouter";
import { GlobalStyle } from "../styles/components/globalStyle";
import "./App.css";

import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/components/themes";

import Header from "../layout/header";
import ScrollToTop from "../components/ScrollToTop";
import Login from "../frontend/login";
import PrivateLayout from "../layout/PrivateLayout";
import Register from "../frontend/register";
import { Toaster } from "react-hot-toast";

import Preloader from "../components/Common/Preloader";

function App() {
      const [loading, setLoading] = useState(true);
      const currentLocation = useLocation().pathname;
      const [footer, setFooter] = useState(true);

      const login = localStorage.getItem("isLoggedIn");

      useEffect(() => {
            setTimeout(() => {
                  setLoading(false);
            }, 2500);
      }, [currentLocation]);

      return (
            <>
                  {/* <Progress.Component
				style={{ background: "#99999  978", height: "5px" }}
				thumbStyle={{
					background: "rgb(232, 107, 25)",
					height: "5px",
				}}
			/> */}
                  <ThemeProvider theme={lightTheme}>
                        <GlobalStyle />
                        {/* {loading && <Preloader />} */}
                        <ScrollToTop />
                        <Toaster position="top-right" />
                        <Suspense fallback="Loading">
                              {login === null ? (
                                    <>
                                          <Header location={login} />
                                          <Routes>
                                                <Route
                                                      path="/"
                                                      element={<Login />}
                                                />
                                                <Route
                                                      path="/register"
                                                      element={<Register />}
                                                />
                                          </Routes>
                                    </>
                              ) : (
                                    <PrivateLayout footer={footer}>
                                          <PublicRouter />
                                    </PrivateLayout>
                              )}
                        </Suspense>
                  </ThemeProvider>
            </>
      );
}

export default App;
