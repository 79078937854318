import React, { useEffect, useState } from "react";
import { PackageBoxContainer } from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import CommonTitle from "../../../Common/CommonTitle";
import PackageItem from "./PackageItem";

import axios from "axios";
import { Skeleton } from "@mui/material";
import toast from "react-hot-toast";

const PackageBox = () => {
      const [loading, setLoading] = useState(false);
      const [packages, setPackages] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/packages`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setPackages(response.data.packages);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <PackageBoxContainer>
                        <Container>
                              <CommonTitle title={"Packages"} />
                              <Row className="g-5">
                                    {!loading ? (
                                          packages.map((item, index) => (
                                                <Col lg={4} key={index}>
                                                      <PackageItem
                                                      data={item}
                                                      />
                                                </Col>
                                          ))
                                    ) : (
                                          <>
                                                <Col lg={4}>
                                                      <Skeleton
                                                            height="380px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </Col>
                                                <Col lg={4}>
                                                      <Skeleton
                                                            height="380px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </Col>
                                                <Col lg={4}>
                                                      <Skeleton
                                                            height="380px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </Container>
                  </PackageBoxContainer>
            </>
      );
};

export default PackageBox;
