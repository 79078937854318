import React, { useEffect, useRef, useState } from "react";
import {
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
      FormInfo,
      FormWrapper,
} from "../TravelerBox/styles";

import { Form } from "react-bootstrap";
import CustomSelect from "../../../custom/CustomSelect";

const FlightTraveler = ({
      adult,
      setAdult,
      children,
      setChildren,
      infant,
      setInfant,
      className,
      setClassName,
      label,
      width,
}) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();

      const handleClick = () => {
            setShow(true);
      };

      const handleOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener("click", handleOutside);

            return () => {
                  document.removeEventListener("click", handleOutside);
            };
      }, []);

      const increment = (key) => {
            if (key === "infants") {
                  if (infant < adult) {
                        setInfant(parseInt(infant) + 1);
                  }
            } else if (key === "adults") {
                  if (adult >= 1) {
                        setAdult(parseInt(adult) + 1);
                  }
            } else {
                  if (children < adult * 2) {
                        setChildren(parseInt(children) + 1);
                  }
            }
      };

      const decrement = (key) => {
            if (key === "adults") {
                  if (adult > 1) {
                        setAdult(adult - 1);
                  }
            } else if (key === "children") {
                  if (children > 0) {
                        setChildren(children - 1);
                  }
            } else {
                  if (infant >= adult) {
                        setInfant(infant - 1);
                  }
            }
      };

      const getTotalTravelers = () => {
            return parseInt(adult) + parseInt(children) + parseInt(infant);
      };

      const options = [
            { value: "ECONOMY", label: "Economy" },
            { value: "PREMIUM_ECONOMY", label: "Premium Economy" },
            { value: "BUSINESS", label: "Business" },
            { value: "FIRST", label: "First" },
      ];

      return (
            <>
                  <FormWrapper onClick={handleClick} ref={divRef} width={width}>
                        {label && <Form.Label>{label}</Form.Label>}

                        <FormInfo>
                              {getTotalTravelers()} {className?.label}
                              <span style={{ fontSize: "13px" }}></span>
                        </FormInfo>
                        <FormAdditional className={show ? "show" : ""}>
                              <FormAdditionalHeader>
                                    <FormAdditionalTitle>
                                          Adults
                                          <span
                                                className="d-block "
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#777777",
                                                }}
                                          >
                                                Above 12 years
                                          </span>
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                className="substract"
                                                onClick={() =>
                                                      decrement("adults")
                                                }
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                {parseInt(adult)}
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={() =>
                                                      increment("adults")
                                                }
                                                className="add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <FormAdditionalHeader>
                                    <FormAdditionalTitle>
                                          Children
                                          <span
                                                className="d-block "
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#777777",
                                                }}
                                          >
                                                2 - 12 years
                                          </span>
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                className="substract"
                                                onClick={() =>
                                                      decrement("children")
                                                }
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                {parseInt(children)}
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={() =>
                                                      increment("children")
                                                }
                                                className="add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <FormAdditionalHeader>
                                    <FormAdditionalTitle>
                                          Infants
                                          <span
                                                className="d-block "
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#777777",
                                                }}
                                          >
                                                Below 2 years
                                          </span>
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                className="substract"
                                                onClick={() =>
                                                      decrement("infants")
                                                }
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                {parseInt(infant)}
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={() =>
                                                      increment("infants")
                                                }
                                                className="add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              <FormAdditionalHeader>
                                    <CustomSelect
                                          options={options}
                                          onSelect={setClassName}
                                          label={"Economy"}
                                          selectedWidth={"100%"}
                                    />
                              </FormAdditionalHeader>
                        </FormAdditional>
                  </FormWrapper>
            </>
      );
};

export default FlightTraveler;
