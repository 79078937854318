import { Button } from "react-bootstrap";
import styled from "styled-components";

export const FlightPriceContainer = styled.div`
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      padding-top: 16px;
      align-items: center;
      border-top: 1px dashed ${(props) => props.theme.primary};
      border-bottom: ${(props) =>
            props.returnStatus ? "1px dashed" + props.theme.primary : ""};
      padding-bottom: ${(props) => (props.returnStatus ? "16px" : "")};
`;

export const FlightPriceContent = styled.div`
      display: flex;
      gap: 10px;
      align-content: center;
      overflow: hidden;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
            display: none;
      }
`;

export const FlightPriceItem = styled.div`
      background-color: #f5f5f5;
      padding: 2px 10px;
      font-size: 14px;
      border-radius: 5px;
      min-width: ${props => props.returnStatus ? "calc(50% - 8px)" : 'fit-content'};
      cursor: pointer;
      position: relative;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      scroll-snap-align: start;
      flex-shrink: 0;

      &.active {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primary};
      }

      .price {
            font-weight: 600;

            span {
                  font-size: 10px;
            }
      }

      .summary {
            font-size: 11px;
      }

      input {
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: -1;
            position: absolute;
      }
`;

export const FlightDetailButton = styled(Button)`
      font-size: ${(props) => props.fontSize || "14px"};
      color: #224082;
      text-transform: uppercase;
      min-width: fit-content;
      text-align: center;
`;
