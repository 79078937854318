import React from "react";
import { Col, FormControl, FormLabel } from "react-bootstrap";

const FormInput = ({
      classes,
      title,
      handleChange,
      required,
      placeholder,
      type,
      value,
      self,
      name,
}) => {
      return (
            <>
                  <Col lg={classes || 6}>
                        <FormLabel>{title}</FormLabel>
                        <FormControl
                              type={type || "text"}
                              placeholder={placeholder}
                              required={required}
                              value={value}
                              name={name}
                              onChange={
                                    self
                                          ? self
                                          : (event) =>
                                                  handleChange(
                                                        event.target.value
                                                  )
                              }
                        />
                  </Col>
            </>
      );
};

export default FormInput;
