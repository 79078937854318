import styled from "styled-components";

export const FormAdditional = styled.div`
      position: absolute;
      z-index: 15001;
      top: 75px;
      left: -15px;
      opacity: 0;
      display: none;
      min-width: 240px;
      border: 1px solid #eef0f2;
      background-color: #fff;
      color: #333;
      font-size: 13px;
      font-weight: normal;
      box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
      border-radius: 3px;

      &:after {
            background: #ffffff;
            -webkit-clip-path: polygon(0 100%, 49% 0, 100% 100%);
            clip-path: polygon(0 100%, 49% 0, 100% 100%);
            content: "";
            height: 15px;
            left: 100px;
            position: absolute;
            top: -9px;
            width: 26px;
            z-index: 10;
      }

      &.show {
            display: block;
            opacity: 1;
      }
`;
export const FormAdditionalHeader = styled.div``;
export const FormAdditionalTitle = styled.div`
      margin-bottom: 5px;
`;
export const FormAdditionalCounter = styled.div`
      display: flex;
`;

export const FormAdditionalCounterIcon = styled.div`
      width: 24px;
      height: 24px;
      line-height: 24px;
      border: 1px solid #ccc;
      border-color: #e0e0e0;
      border-radius: 4px 0 0 4px;
      line-height: 32px;
      background: #fafafa;
      display: inline-flex;
      text-align: center;
      cursor: pointer;
      -webkit-user-select: none;
      vertical-align: middle;
      &.add {
            border-radius: 0px 4px 4px 0px;
      }
`;
export const FormAdditionalCounterInput = styled.div`
      display: inline-block;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      height: 24px;

      input {
            width: 40px !important;
            height: 19px;
            font-size: 12px;
            text-align: center;
            display: inline-flex;
            border: 0;
            background: transparent;
            padding: 0;
            border-radius: 0;

            &:focus-visible {
                  outline: 0;
            }
      }
`;

export const AdultChildSection = styled.div`
      border-top: 1px dashed rgba(33, 37, 41, 0.3);
`;
export const AdultChildTitle = styled.div`
      margin-bottom: 9px;
`;
export const AdultSection = styled.div``;
export const ChildSection = styled.div``;
