import styled from "styled-components";

export const FlightTabContainer = styled.div`
      border: none;
      margin-top: 1.2rem;
      background-color: #f5f5f5;
      padding-top: 10px;
      padding-bottom: 10px;
`;

export const FlightTabButton = styled.button`
      background-color: transparent;
      border: none;
      padding: 4px 16px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 16px;
      transition: color 0.3s ease-in;

      &:hover {
            color: ${(props) => props.theme.primary};
            border-bottom: 2px solid ${(props) => props.theme.primary};
      }

      &.active {
            border: 0;
            color: ${(props) => props.theme.primary};
            border-bottom: 2px solid ${(props) => props.theme.primary};

            &::after {
                  content: none;
            }
      }

      @media screen and (max-width: 600px) {
            min-width: max-content;
      }
`;

export const FlightTabHeader = styled.div`
      @media screen and (max-width: 600px) {
            overflow-x: scroll;
            display: flex;

            &::-webkit-scrollbar {
                  width: 0;
            }
      }
`;

export const FlightTabContent = styled.div`
      /* margin-top: 20px; */

      padding: 20px;
`;

export const FlightTabPanel = styled.div`
      display: ${(props) => (props.display ? props.display : "none")};
      font-size: 16px;

      &.active {
            display: block;
      }

      ul {
            margin-top: 10px;
            li {
                  list-style: disc;
            }
      }

      @media screen and (max-width: 600px) {
            h2,
            h3,
            h1 {
                  font-size: 24px;
                  margin-bottom: 0px;
            }
      }
`;
