import React from "react";
import {
      FlightTripDetailAirline,
      FlightTripDetailCategory,
      FlightTripDetailContainer,
      FlightTripDetailInfo,
      FlightTripLayoverContainer,
      FlightTripLayoverContent,
      FlightTripLayoverLine,
      FlightTripLayoverTitle,
} from "../FlightTripDetails/style";

const BaggageTripInfo = ({ items }) => {
      return (
            <>
                  <FlightTripDetailContainer>
                        {items?.sI?.map((item, index) => (
                              <FlightTripDetailCategory>
                                    {index === 0 &&
                                          `${item?.da?.city} to
                                                      ${item?.aa?.city}`}
                              </FlightTripDetailCategory>
                        ))}
                        <FlightTripDetailInfo>
                              {items?.sI?.map((item, index) => (
                                    <>
                                          <FlightTripDetailAirline>
                                                <div>
                                                      <strong>Sector</strong>
                                                      <div>
                                                            {item?.da?.code} -{" "}
                                                            {item?.aa?.code}
                                                      </div>
                                                </div>
                                                <div>
                                                      <strong>Check in</strong>
                                                      <div>
                                                            {
                                                                  items
                                                                        ?.totalPriceList[
                                                                        index
                                                                  ]?.fd?.ADULT
                                                                        ?.bI?.iB
                                                            }
                                                            {" /"}
                                                            <span>
                                                                  {" person"}
                                                            </span>
                                                      </div>
                                                </div>
                                                <div>
                                                      <strong>Cabin</strong>
                                                      <div>
                                                            {
                                                                  items
                                                                        ?.totalPriceList[
                                                                        index
                                                                  ]?.fd?.ADULT
                                                                        ?.bI?.cB
                                                            }
                                                            {" /"}
                                                            <span>
                                                                  {" person"}
                                                            </span>
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                          {items?.sI?.length > 1 &&
                                                items?.sI?.length !==
                                                      index + 1 && (
                                                      <FlightTripLayoverContainer>
                                                            <FlightTripLayoverLine />
                                                            <FlightTripLayoverContent>
                                                                  <FlightTripLayoverTitle
                                                                        fontWeight="600"
                                                                        color="#224082"
                                                                  >
                                                                        Plane
                                                                        Change
                                                                  </FlightTripLayoverTitle>
                                                            </FlightTripLayoverContent>
                                                      </FlightTripLayoverContainer>
                                                )}
                                    </>
                              ))}
                        </FlightTripDetailInfo>
                  </FlightTripDetailContainer>
            </>
      );
};

export default BaggageTripInfo;
