import styled, { css } from "styled-components";
export const smoothTransition = styled(css)`
      -ms-transition: color 0.4s ease, background-color 0.4s ease,
            border-color 0.4s ease, box-shadow 0.4s ease;
      -o-transition: color 0.4s ease, background-color 0.4s ease,
            border-color 0.4s ease, box-shadow 0.4s ease;
      -moz-transition: color 0.4s ease, background-color 0.4s ease,
            border-color 0.4s ease, box-shadow 0.4s ease;
      -webkit-transition: color 0.4s ease, background-color 0.4s ease,
            border-color 0.4s ease, box-shadow 0.4s ease;
      transition: color 0.4s ease, background-color 0.4s ease,
            border-color 0.4s ease, box-shadow 0.4s ease;
`;

export const CommonContainer = css`
      padding: 0 3rem;
`;
