import styled from "styled-components";

export const FileInputWrapper = styled.div`
      position: relative;
      width: 100%;

      input {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
      }
`;
