import styled from "styled-components";

export const FlightConfirmationHeader = styled.div`
      display: flex;
      gap: 10px;

      .confirmation__header-img {
            height: 60px;
            width: 60px;

            img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
            }
      }

      .confirmation__header-title {
            font-size: 24px;
            font-weight: 500;
      }

      .confirmation__header-subtitle {
            font-size: 18px;
      }
`;
