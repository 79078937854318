import React from "react";
import FlightDetailBox from "../../checkout/FlightDetailBox";
import TravellersDetailBox from "../../checkout/TravellersDetailBox/index";
import FlightReviewDetail from "../FlightReviewDetail";
import FlightReviewPayment from "../FlightReviewPayment";

const FlightReviewOptions = ({
      options,
      currentOption,
      setOptions,
      setCurrentOption,
      tripInfos,
      optionId,
      reviewId,
      searchQuery,
      bookingId,
      conditions,
      serviceList,
      setServiceList,
      setDestinationDetails,
      destinationDetails,
      totalAmount,
      setTotalAmount,
}) => {
      return (
            <>
                  {optionId === "itinerary" && (
                        <>
                              <FlightDetailBox
                                    tripInfos={tripInfos}
                                    options={options}
                                    setOptions={setOptions}
                                    currentOption={currentOption}
                                    setCurrentOption={setCurrentOption}
                                    marginBottom={"20px"}
                                    reviewId={reviewId}
                              />
                        </>
                  )}

                  {optionId === "travellers" && (
                        <TravellersDetailBox
                              bookingId={bookingId}
                              searchQuery={searchQuery}
                              marginBottom={"30px"}
                              reviewId={reviewId}
                              options={options}
                              tripInfos={tripInfos}
                              setOptions={setOptions}
                              currentOption={currentOption}
                              setCurrentOption={setCurrentOption}
                              conditions={conditions}
                              serviceList={serviceList}
                              setServiceList={setServiceList}
                              destinationDetails={destinationDetails}
                              setDestinationDetails={setDestinationDetails}
                              totalAmount={totalAmount}
                              setTotalAmount={setTotalAmount}
                        />
                  )}
                  {optionId === "review" && (
                        <FlightReviewDetail
                              tripInfos={tripInfos}
                              options={options}
                              setOptions={setOptions}
                              currentOption={currentOption}
                              setCurrentOption={setCurrentOption}
                              reviewId={reviewId}
                        />
                  )}
                  {optionId === "payments" && (
                        <FlightReviewPayment
                              tripInfos={tripInfos}
                              bookingId={bookingId}
                              options={options}
                              setOptions={setOptions}
                              currentOption={currentOption}
                              setCurrentOption={setCurrentOption}
                              reviewId={reviewId}
                              totalAmount={totalAmount}
                              searchQuery={searchQuery}
                              destinationDetails={destinationDetails}
                        />
                  )}
            </>
      );
};

export default FlightReviewOptions;
