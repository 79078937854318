import React, { useEffect, useState } from "react";
import {
      FlightTripDetailAirline,
      FlightTripDetailCategory,
      FlightTripDetailContainer,
      FlightTripDetailInfo,
} from "../FlightTripDetails/style";

const FlightTripFare = ({ fareId, searchDetail }) => {
      const calculateTotalAmount = () => {
            let newAmount = 0;
            if (fareId?.fd?.ADULT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              fareId?.fd?.ADULT?.fC?.BF * searchDetail?.adult +
                                    fareId?.fd?.ADULT?.fC?.TAF *
                                          searchDetail?.adult
                        );
            }
            if (fareId?.fd?.CHILD) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              fareId?.fd?.CHILD?.fC?.BF *
                                    searchDetail?.children +
                                    fareId?.fd?.CHILD?.fC?.TAF *
                                          searchDetail?.children
                        );
            }
            if (fareId?.fd?.INFANT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              fareId?.fd?.INFANT?.fC?.BF *
                                    searchDetail?.infants +
                                    fareId?.fd?.INFANT?.fC?.TAF *
                                          searchDetail?.infants
                        );
            }
            return newAmount;
      };

      return (
            <>
                  <FlightTripDetailContainer>
                        <FlightTripDetailCategory>
                              Fare breakup
                        </FlightTripDetailCategory>
                        <FlightTripDetailInfo>
                              <FlightTripDetailAirline>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                TYPE
                                          </div>
                                    </div>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                FARE
                                          </div>
                                    </div>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                TOTAL
                                          </div>
                                    </div>
                              </FlightTripDetailAirline>
                              {fareId?.fd?.ADULT && (
                                    <>
                                          <FlightTripDetailAirline>
                                                <div className="flight__trip-title">
                                                      Fare Details for ADULT
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Base Price</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.ADULT
                                                                        ?.fC?.BF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.adult
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.ADULT
                                                                  ?.fC?.BF *
                                                                  searchDetail?.adult}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline marginBottom="10px">
                                                <div className="flight__price-content">
                                                      <div>Taxes & Fees</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.ADULT
                                                                        ?.fC
                                                                        ?.TAF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.adult
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.ADULT
                                                                  ?.fC?.TAF *
                                                                  searchDetail?.adult}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                    </>
                              )}
                              {fareId?.fd?.CHILD && (
                                    <>
                                          <FlightTripDetailAirline>
                                                <div className="flight__trip-title">
                                                      Fare Details for CHILD
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Base Price</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.CHILD
                                                                        ?.fC?.BF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.children
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.CHILD
                                                                  ?.fC?.BF *
                                                                  searchDetail?.children}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline marginBottom="10px">
                                                <div className="flight__price-content">
                                                      <div>Taxes & Fees</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.CHILD
                                                                        ?.fC
                                                                        ?.TAF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.children
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.CHILD
                                                                  ?.fC?.TAF *
                                                                  searchDetail?.children}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                    </>
                              )}
                              {fareId?.fd?.INFANT && (
                                    <>
                                          <FlightTripDetailAirline>
                                                <div className="flight__trip-title">
                                                      Fare Details for INFANT
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Base Price</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.INFANT
                                                                        ?.fC?.BF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.infants
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.INFANT
                                                                  ?.fC?.BF *
                                                                  searchDetail?.infants}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Taxes & Fees</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.INFANT
                                                                        ?.fC
                                                                        ?.TAF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.infants
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.INFANT
                                                                  ?.fC?.TAF *
                                                                  searchDetail?.infants}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                          <FlightTripDetailAirline
                                                border={`1px solid ${(props) =>
                                                      props.theme.primary}`}
                                                className="pt-2 mt-2"
                                          >
                                                <div className="flight__price-content">
                                                      <div className="flight__price-header">
                                                            Total
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div className="flight__price-header">
                                                            ₹{" "}
                                                            {calculateTotalAmount()}
                                                      </div>
                                                </div>
                                          </FlightTripDetailAirline>
                                    </>
                              )}
                        </FlightTripDetailInfo>
                  </FlightTripDetailContainer>
            </>
      );
};

export default FlightTripFare;
