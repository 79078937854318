import styled from "styled-components";

export const FlightInfoRangeContainer = styled.div`
      border-bottom: 1px solid #9f9f9f;
      padding: 20px 0px;
`;
export const FlightInfoRangeContent = styled.div`
      width: 100%;
`;
export const FlightInfoRangeDate = styled.div`
      font-size: 12px;
      color: ${(props) => (props.active ? props.theme.primary : "#666666")};
`;
export const FlightInfoRangePrice = styled.div`
      color: #333333;
`;
