import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
      FlightDetailCategory,
      FlightDetailContainer,
} from "../../../Dashboard/FlightDetails/style";
import { DoubleIcon } from "../../../../icons";
import CommonButton from "../../../Common/CommonButton";
import { PriceSummary } from "../../../../frontend/checkout/styles";
import { FormCheck } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

const FlightReviewPayment = ({
      currentOption,
      setCurrentOption,
      options,
      setOptions,
      reviewId,
      totalAmount,
      tripInfos,
      bookingId,
      searchQuery,
      destinationDetails,
}) => {
      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);

      const navigate = useNavigate();
      const gstDetail = JSON.parse(localStorage.getItem("gstDetails"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));
      const travellers = JSON.parse(localStorage.getItem("travellers"));
      const services = JSON.parse(localStorage.getItem("services"));

      const flightDetails = searchQuery?.routeInfos[0];

      const checkService = () => {
            return services[
                  `${flightDetails?.fromCityOrAirport?.cityCode}-${flightDetails?.toCityOrAirport?.cityCode}`
            ];
      };

      const handleOption = async () => {
            setLoading(true);
            let travellerDetails;

            if (travellers) {
                  travellerDetails = travellers?.map((item) => {
                        const baggageCode =
                              checkService()[item?.ptName]?.Baggage?.code;
                        const mealCode =
                              checkService()[item?.ptName]?.Meal?.code;
                        const seatCode =
                              checkService()[item?.ptName]?.Seat?.code;

                        return {
                              ti: item?.title,
                              fN: item?.firstName,
                              lN: item?.lastName,
                              pt: item?.pt,

                              ...(baggageCode && {
                                    ssrBaggageInfos: [
                                          {
                                                key: tripInfos[0]?.sI[0]?.id,
                                                code: baggageCode,
                                          },
                                    ],
                              }),
                              ...(mealCode && {
                                    ssrMealInfos: [
                                          {
                                                key: tripInfos[0]?.sI[0]?.id,
                                                code: mealCode,
                                          },
                                    ],
                              }),
                              ...(seatCode && {
                                    ssrSeatInfos: [
                                          {
                                                key: tripInfos[0]?.sI[0]?.id,
                                                code: seatCode,
                                          },
                                    ],
                              }),
                        };
                  });
            }

            const data = {
                  bookingId: bookingId,
                  travellerInfo: travellerDetails,
                  paymentInfos: [
                        {
                              amount: totalAmount,
                        },
                  ],
                  ...(gstDetail && {
                        gstInfo: {
                              gstNumber: gstDetail?.registrationNumber,
                              email: gstDetail?.email,
                              registeredName: gstDetail?.companyName,
                        },
                  }),
                  deliveryInfo: {
                        emails: [contactDetails?.emailAddress],
                        contacts: [contactDetails?.phoneNumber],
                  },
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/air/book`,
                        data,
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              setLoading(false);
                              if (response.data.status?.success === true) {
                                    navigate(
                                          `/flights/confirmation/${response?.data?.bookingId}`
                                    );
                              }
                        }, 3000);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleBack = () => {
            setLoadingBack(true);
            let optionItem = currentOption.index - 1;
            setTimeout(() => {
                  setLoadingBack(false);
                  const updateOptions = options.map((item, i) => {
                        if (i <= optionItem) {
                              return { ...item, status: true };
                        }
                        return { ...item, status: false };
                  });
                  setOptions(updateOptions);
                  setCurrentOption({
                        id: updateOptions[optionItem].id,
                        index: optionItem,
                  });
                  navigate(
                        `/flight/${updateOptions[optionItem].id}/${reviewId}`
                  );
            }, 3000);
      };

      return (
            <>
                  <FlightDetailContainer>
                        <FlightDetailCategory className="mb-4">
                              Payment Information
                        </FlightDetailCategory>
                        <PriceSummary>
                              <ul className="price__list list-inline mb-0">
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Total Trip Charge
                                          </div>
                                          <div className="price__item--amount">
                                                ₹ {totalAmount}
                                          </div>
                                    </li>
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title d-flex gap-2 w-75">
                                                <FormCheck /> Wallet Balance
                                          </div>
                                          <div className="price__item--amount">
                                                ₹ 0
                                          </div>
                                    </li>
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Pay Online
                                          </div>
                                          <div className="price__item--amount">
                                                ₹ {totalAmount}
                                          </div>
                                    </li>
                              </ul>
                        </PriceSummary>
                  </FlightDetailContainer>

                  <div className="price__button d-flex w-100 mt-4 justify-content-between align-items-center mt-0">
                        <CommonButton
                              title={"Back"}
                              leftIcon={
                                    <DoubleIcon
                                          transform="rotate(180deg)"
                                          color="#FFF"
                                    />
                              }
                              padding={"8px 16px"}
                              type="button"
                              button={true}
                              handleClick={handleBack}
                              loading={loadingBack}
                        />
                        <CommonButton
                              title={"Pay And Book Now"}
                              icon={<DoubleIcon color="#FFF" />}
                              padding={"8px 30px"}
                              type="submit"
                              button={true}
                              handleClick={handleOption}
                              loading={loading}
                        />
                  </div>
            </>
      );
};

export default FlightReviewPayment;
