import React from "react";
import {
      FlightTripAirline,
      FlightTripAirlineTime,
      FlightTripAirlineTitle,
      FlightTripDetailAirline,
      FlightTripDetailCategory,
      FlightTripDetailContainer,
      FlightTripDetailInfo,
      FlightTripDetailTitle,
      FlightTripLayoverContainer,
      FlightTripLayoverContent,
      FlightTripLayoverLine,
      FlightTripLayoverTitle,
      FlightTripRefund,
} from "./style";
import { format } from "date-fns";
import { FlightInfoLogo } from "../../../Dashboard/FlightInfo/styles";

const FlightTripDetails = ({ items }) => {
      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE - dd MMM yyyy");
      };

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const calculateTimeDifference = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            // Calculate the difference in milliseconds
            const differenceInMs = Math.abs(date1 - date2);

            // Convert the difference to minutes
            const totalMinutes = Math.floor(differenceInMs / 60000);

            // Calculate hours and minutes using the existing function
            return calculateDuration(totalMinutes);
      };

      return (
            <>
                  <FlightTripDetailContainer>
                        <FlightTripDetailCategory>
                              Departing Flight
                        </FlightTripDetailCategory>
                        <FlightTripDetailInfo>
                              {items?.sI?.map((item, index) => (
                                    <>
                                          {index === 0 && (
                                                <FlightTripDetailTitle>
                                                      {item?.da?.city} -{" "}
                                                      {item?.aa?.city} |{" "}
                                                      <span>
                                                            {convertDate(
                                                                  item?.at
                                                            )}
                                                      </span>
                                                </FlightTripDetailTitle>
                                          )}
                                          <FlightTripDetailAirline>
                                                <FlightTripAirline>
                                                      <FlightInfoLogo>
                                                            <img
                                                                  src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                  alt={
                                                                        item?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            />
                                                      </FlightInfoLogo>
                                                      <FlightTripAirlineTitle>
                                                            <div>
                                                                  {
                                                                        item?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            </div>
                                                            <span>
                                                                  {
                                                                        item?.fD
                                                                              ?.aI
                                                                              ?.code
                                                                  }{" "}
                                                                  {item?.fD?.fN}
                                                            </span>
                                                            {/* <span>ECONOMY</span> */}
                                                      </FlightTripAirlineTitle>
                                                </FlightTripAirline>
                                                <FlightTripAirlineTime>
                                                      <div>
                                                            {formatTime(
                                                                  item?.dt
                                                            )}
                                                      </div>
                                                      <span>
                                                            {item?.da?.city} (
                                                            {item?.da?.code})
                                                      </span>
                                                      <span>
                                                            {convertDate(
                                                                  item?.dt
                                                            )}
                                                      </span>
                                                      <span>
                                                            {item?.da?.terminal}
                                                      </span>
                                                </FlightTripAirlineTime>
                                                <FlightTripRefund>
                                                      <div className="flight__duration">
                                                            {`${
                                                                  calculateDuration(
                                                                        item?.duration
                                                                  ).hours
                                                            }h ${
                                                                  calculateDuration(
                                                                        item?.duration
                                                                  ).minutes
                                                            }m`}
                                                      </div>
                                                      <div className="line"></div>
                                                      {/* <div class="flight__refund">
                                                            REFUNDABLE
                                                      </div> */}
                                                </FlightTripRefund>
                                                <FlightTripAirlineTime>
                                                      <div>
                                                            {formatTime(
                                                                  item?.at
                                                            )}
                                                      </div>
                                                      <span>
                                                            {item?.aa?.city} (
                                                            {item?.aa?.code})
                                                      </span>
                                                      <span>
                                                            {convertDate(
                                                                  item?.at
                                                            )}
                                                      </span>
                                                      <span>
                                                            {item?.aa?.terminal}
                                                      </span>
                                                </FlightTripAirlineTime>
                                          </FlightTripDetailAirline>
                                          {items?.sI?.length > 1 &&
                                                items?.sI.length !==
                                                      index + 1 && (
                                                      <FlightTripLayoverContainer>
                                                            <FlightTripLayoverLine />
                                                            <FlightTripLayoverContent>
                                                                  <FlightTripLayoverTitle
                                                                        fontWeight="600"
                                                                        color="#224082"
                                                                  >
                                                                        Plane
                                                                        Change
                                                                  </FlightTripLayoverTitle>
                                                                  <FlightTripLayoverTitle
                                                                        fontSize="0.875rem"
                                                                        color="#555"
                                                                  >
                                                                        <span>
                                                                              Layover
                                                                              at
                                                                              {` `}
                                                                              {
                                                                                    item
                                                                                          ?.aa
                                                                                          ?.city
                                                                              }
                                                                              ,
                                                                              {` ${
                                                                                    calculateTimeDifference(
                                                                                          item?.at,
                                                                                          items
                                                                                                ?.sI[
                                                                                                index +
                                                                                                      1
                                                                                          ]
                                                                                                ?.dt
                                                                                    )
                                                                                          .hours
                                                                              }h ${
                                                                                    calculateTimeDifference(
                                                                                          item?.at,
                                                                                          items
                                                                                                ?.sI[
                                                                                                index +
                                                                                                      1
                                                                                          ]
                                                                                                ?.dt
                                                                                    )
                                                                                          .minutes
                                                                              }m`}
                                                                        </span>
                                                                  </FlightTripLayoverTitle>
                                                            </FlightTripLayoverContent>
                                                      </FlightTripLayoverContainer>
                                                )}
                                    </>
                              ))}
                        </FlightTripDetailInfo>
                  </FlightTripDetailContainer>
            </>
      );
};

export default FlightTripDetails;
