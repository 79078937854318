import React from "react";
import { DashboardContainer } from "../dashboard/styles";
import { PrivacyContainer } from "../privacy/style";

const TermsAndConditions = () => {
      return (
            <>
                  <DashboardContainer>
                        <PrivacyContainer>
                              <h3>Terms Of Service</h3>
                              <p>
                                    This web site is published and maintained by
                                    Aircasa Pvt Ltd Travel Services Private
                                    Limited , a company incorporated and
                                    existing in accordance with the laws of the
                                    Republic of India. When you access, browse
                                    or use this Site you accept, without
                                    limitation or qualification, the terms and
                                    conditions set forth below. When you access
                                    any sub-site (whether belonging to an
                                    'associate' of Aircasa Pvt Ltd or otherwise)
                                    through this site, then such sub-site may
                                    have its own terms and conditions of use
                                    which is specific to such sub-site.
                                    Sub-sites may contain such additional terms
                                    and conditions of use as may be set out in
                                    such sub-site.
                              </p>
                              <p>
                                    These Terms and Conditions of Use and any
                                    additional terms posted on this Site
                                    together constitute the entire agreement
                                    between Aircasa Pvt Ltd and you with respect
                                    to your use of this Site.
                              </p>
                              <p>
                                    You represent and warrant you have the
                                    necessary authority, whether implied or
                                    express, from your clients and the traveller
                                    to purchase air tickets, train tickets,
                                    booking of hotels, and other products and
                                    services offered on Trip Jack’s portal. You
                                    will inform the customers about the charges,
                                    benefits and the Terms and Conditions before
                                    making any booking.
                              </p>
                              <h3>Site and Its Contents</h3>
                              <p>
                                    This Site is only for your personal use. You
                                    shall not distribute, exchange, modify, sell
                                    or transmit anything you copy from this
                                    Site, including but not limited to any text,
                                    images, audio and video, for any business,
                                    commercial or public purpose.
                              </p>
                              <p>
                                    As long as you comply with the terms of
                                    these Terms and Conditions of Use, Aircasa
                                    Pvt Ltd grants you a non-exclusive,
                                    non-transferable, limited right to enter,
                                    view and use this Site. You agree not to
                                    interrupt or attempt to interrupt the
                                    operation of this Site in any way.
                              </p>
                              <p>
                                    Access to certain areas of the Site may only
                                    be available to registered members. To
                                    become a registered member, you may be
                                    required to answer certain questions.
                                    Answers to such questions may be
                                    mandAircasary and/or optional. You represent
                                    and warrant that all information you supply
                                    to us, about yourself, and others, is true
                                    and accurate.
                              </p>
                              <h3>Ownership</h3>
                              <p>
                                    All materials on this Site, including but
                                    not limited to audio, images, software,
                                    text, icons and such like (the "Content"),
                                    are protected by copyright under
                                    international conventions and copyright
                                    laws. You cannot use the Content, except as
                                    specified herein. You agree to follow all
                                    instructions on this Site limiting the way
                                    you may use the Content.
                              </p>
                              <p>
                                    There are a number of proprietary logos,
                                    service marks and trademarks found on this
                                    Site whether owned/used by Aircasa Pvt Ltd
                                    or otherwise. By displaying them on this
                                    Site, Aircasa Pvt Ltd is not granting you
                                    any license to utilize those proprietary
                                    logos, service marks, or trademarks. Any
                                    unauthorized use of the Content may violate
                                    copyright laws, trademark laws, the laws of
                                    privacy and publicity, and civil and
                                    criminal statutes.
                              </p>
                              <p>
                                    You may download such copy/copies of the
                                    Content to be used only by you for your
                                    personal use at home unless the subsite you
                                    are accessing states that you may not. If
                                    you download any Content from this Site, you
                                    shall not remove any copyright or trademark
                                    notices or other notices that go with it.
                              </p>
                              <h3>Others' rights</h3>
                              <p>
                                    If this Site contains bulletin boards, chat
                                    rooms, access to mailing lists or other
                                    message or communication facilities, you
                                    agree to use the same only to send and
                                    receive messages and materials that are
                                    proper and related thereto. By way of
                                    example and not as a limitation, you agree
                                    that when using the Site or any facility
                                    available herefrom, you shall not do any of
                                    the following:
                              </p>
                              <ul>
                                    <li>
                                          Defame, abuse, harass, stalk, threaten
                                          or otherwise violate the legal rights
                                          (such as rights of privacy and
                                          publicity) of others
                                    </li>
                                    <li>
                                          Publish, post, distribute or
                                          disseminate any defamAircasary,
                                          infringing, obscene, indecent or
                                          unlawful material or information
                                    </li>
                                    <li>
                                          Upload or attach files that contain
                                          software or other material protected
                                          by intellectual property laws (or by
                                          rights of privacy and publicity)
                                          unless the User owns or controls the
                                          rights thereto or has received all
                                          consents therefor as may be required
                                          by law
                                    </li>
                                    <li>
                                          Upload or attach files that contain
                                          viruses, corrupted files or any other
                                          similar software or programs that may
                                          damage the operation of another's
                                          computer
                                    </li>
                                    <li>
                                          Delete any author attributions, legal
                                          notices or proprietary designations or
                                          labels in any file that is uploaded
                                    </li>
                                    <li>
                                          Falsify the origin or source of
                                          software or other material contained
                                          in a file that is uploaded
                                    </li>
                                    <li>
                                          Advertise or offer to sell any goods
                                          or services, or conduct or forward
                                          surveys, contests or chain letters, or
                                          download any file posted by another
                                          user of a Forum that the User knows,
                                          or reasonably should know, cannot be
                                          legally distributed in such manner.
                                    </li>
                              </ul>
                              <h3>User's Material</h3>
                              <p>
                                    You are prohibited from posting or
                                    transmitting any defamAircasary, libelous,
                                    obscene, pornographic, profane, threatening
                                    or unlawful material or any material that
                                    could constitute or encourage conduct that
                                    would be considered a criminal offense or
                                    give rise to civil liability, or otherwise
                                    violate any law.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd assumes no liability or
                                    responsibility arising from the contents of
                                    any communications containing any
                                    defamAircasary, erroneous, inaccurate,
                                    libelous, obscene or profane material.
                                    Aircasa Pvt Ltd may change, edit, or remove
                                    any user material or conversations that are
                                    illegal, indecent, obscene or offensive, or
                                    that violates Aircasa Pvt Ltd's policies in
                                    any way.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd will fully cooperate with
                                    any law enforcement authorities or court
                                    order requesting or directing Aircasa Pvt
                                    Ltd to disclose the identity of anyone
                                    posting such materials.
                              </p>
                              <h3>Aircasa Pvt Ltd Rights</h3>
                              <p>
                                    If you send any communications or materials
                                    to the Site by electronic mail or otherwise,
                                    including any comments, data, questions,
                                    suggestions or the like, all such
                                    communications are, and will be treated by
                                    Aircasa Pvt Ltd, as non-confidential.
                              </p>
                              <p>
                                    You hereby give up any and all claim that
                                    any use of such material violates any of
                                    your rights including moral rights, privacy
                                    rights, proprietary or other property
                                    rights, publicity rights, rights to credit
                                    for material or ideas, or any other right,
                                    including the right to approve the way
                                    Aircasa Pvt Ltd uses such material.
                              </p>
                              <p>
                                    Any material submitted to this Site may be
                                    adapted, broadcast, changed, copied,
                                    disclosed, licensed, performed, posted,
                                    published, sold, transmitted or used by
                                    Aircasa Pvt Ltd anywhere in the world, in
                                    any medium, forever.
                              </p>
                              <h3>Transmitted Material</h3>
                              <p>
                                    Internet transmissions are never completely
                                    private or secure. You understand that any
                                    message or information you send to this Site
                                    may be read or intercepted by others unless
                                    there is a special notice that a particular
                                    message (for example, credit card
                                    information) is encrypted (send in code).
                                    Sending a message to Aircasa Pvt Ltd does
                                    not cause Aircasa Pvt Ltd to have any
                                    special responsibility to you.
                              </p>
                              <p>
                                    The copyright in the contents of this
                                    website belong to Aircasa Pvt Ltd.
                                    Accordingly Aircasa Pvt Ltd reserves all
                                    rights. Copying of part or all the contents
                                    of this website without permission of
                                    Aircasa Pvt Ltd is prohibited except to the
                                    extent that such copying/printing is
                                    necessary for the purposes of availing of
                                    the paid services provided.
                              </p>
                              <h3>Contests and interactions</h3>
                              <p>
                                    This Site may contain contests that require
                                    you to send in material or information about
                                    yourself or offer prizes. Each contest has
                                    its own rules, which you must read and agree
                                    to before you participate.
                              </p>
                              <h3>Disclaimer</h3>
                              <p>
                                    The material in this Site could include
                                    technical inaccuracies or typographical
                                    errors. Aircasa Pvt Ltd may make changes or
                                    improvements at any time.
                              </p>
                              <p>
                                    The materials on this site are provided on
                                    an 'As Is' basis, without warranties of any
                                    kind either expressed or implied. Tot the
                                    fullest extent permissible pursuant to
                                    applicable law, Aircasa Pvt Ltd disclaims
                                    all warranties of merchantibility and
                                    fitness for a particular purpose.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd does not warrant that the
                                    functions contained in this site will be
                                    uninterrupted or error free, that defects
                                    will be corrected, or that this site or the
                                    servers that make it available are free of
                                    viruses or other harmful components, but
                                    shall endeavour to ensure your fullest
                                    satisfaction.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd does not warrant or make any
                                    representations regarding the use of or the
                                    result of the use of the material on the
                                    site in terms of their correctness,
                                    accuracy, reliability, or otherwise, insofar
                                    as such material is derived from other
                                    service providers such as airlines, hotel
                                    owners and tour operAircasars.
                              </p>
                              <p>
                                    You acknowledge that this Website is
                                    provided only on the basis set out in these
                                    terms and conditions. Your uninterrupted
                                    access or use of this Website on this basis
                                    may be prevented by certain factors outside
                                    our reasonable control including, without
                                    limitation, the unavailability,
                                    inoperability or interruption of the
                                    Internet or other telecommunications
                                    services or as a result of any maintenance
                                    or other service work carried out on this
                                    Website. Aircasa Pvt Ltd does not accept any
                                    responsibility and will not be liable for
                                    any loss or damage whatsoever arising out of
                                    or in connection with any ability/inability
                                    to access or to use the Site.
                              </p>
                              <p>
                                    You also acknowledge that through this Site,
                                    Aircasa Pvt Ltd merely provides intermediary
                                    services in order to facilitate highest
                                    quality services to you. Aircasa Pvt Ltd is
                                    not the last-mile service provider to you
                                    and therefore, Aircasa Pvt Ltd shall not be
                                    or deemed to be responsible for any lack or
                                    deficiency of services provided by any
                                    person (airline, travel/tour operAircasar,
                                    hotel, facility or similar agency) you shall
                                    engage or hire or appoint pursuant to or
                                    resulting from, the material available in
                                    this Site.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd will not be liable to you or
                                    to any other person for any direct,
                                    indirect, incidental, punitive or
                                    consequential loss, damage, cost or expense
                                    of any kind whatsoever and howsoever caused
                                    from out of your usage of this Site.
                              </p>
                              <p>
                                    Notwithstanding anything else to the
                                    contrary contained elsewhere herein or
                                    otherwise at law, Aircasa Pvt Ltd's
                                    liability (whether by way of indemnification
                                    to you or otherwise) shall be restricted to
                                    a maximum of INR 1000 (Indian Rupees One
                                    Thousand only).
                              </p>
                              <h3>Availability</h3>
                              <p>
                                    The products and services displayed on the
                                    Site may not be available for purchase in
                                    your particular country or locality. The
                                    reference to such products and services on
                                    the Site does not imply or warrant that
                                    these products or services will be available
                                    at any time in your particular geographical
                                    location. You should check with your local
                                    Aircasa Pvt Ltd authorized representative
                                    for the availability of specific products
                                    and services in your area.
                              </p>
                              <h3>Terms and Conditions of Use</h3>
                              <p>
                                    Aircasa Pvt Ltd may add to, change or remove
                                    any part of these Terms and Conditions of
                                    Use at any time, without notice. Any changes
                                    to these Terms and Conditions of Use or any
                                    terms posted on this Site apply as soon as
                                    they are posted. By continuing to use this
                                    Site after any changes are posted, you are
                                    indicating your acceptance of those changes.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd may add, change,
                                    discontinue, remove or suspend any other
                                    Content posted on this Site, including
                                    features and specifications of products
                                    described or depicted on the Site,
                                    temporarily or permanently, at any time,
                                    without notice and without liability.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd reserves the right to
                                    undertake all necessary steps to ensure that
                                    the security, safety and integrity of
                                    Aircasa Pvt Ltd's systems as well as its
                                    clients' interests are and remain,
                                    well-protected.
                              </p>
                              <p>
                                    Towards this end, Aircasa Pvt Ltd may take
                                    various steps to verify and confirm the
                                    authenticity, enforceability and validity of
                                    orders placed by you.
                              </p>
                              <p>
                                    If Aircasa Pvt Ltd, in its sole and
                                    exclusive discretion, concludes that the
                                    said orders are not or do not reasonably
                                    appear to be, authentic, enforceable or
                                    valid, then Aircasa Pvt Ltd may cancel the
                                    said orders at any time up to 5 hours before
                                    the scheduled time of departure of the
                                    relevant flight or 5 hours before the
                                    expected date of visit to any property
                                    booked through Aircasa Pvt Ltd.
                              </p>
                              <h3>General Provisions</h3>
                              <p>
                                    You may travel to certain destinations which
                                    involve greater risks than others, entirely
                                    at your risk as to cost and consequences.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd requests you to consult your
                                    local authorities and evaluate travel
                                    prohibitions, warning, announcements, and
                                    advisories issued by them before booking
                                    travel to certain international
                                    destinations.
                              </p>
                              <p>
                                    By offering for sale travel to particular
                                    international destinations, Aircasa Pvt Ltd
                                    does not represent or warrant that travel to
                                    such point is advisable or without risk.
                                    Aircasa Pvt Ltd does not accept liability
                                    for damages, losses, or delays that may
                                    result from improper documents for entry,
                                    exit, length of stay, or from travel to such
                                    destinations.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd reserves its exclusive right
                                    in its sole discretion to alter, limit or
                                    discontinue the Site or any material posted
                                    herein, in any respect. Aircasa Pvt Ltd
                                    shall have no obligation to take the needs
                                    of any User into consideration in connection
                                    therewith.
                              </p>
                              <p>
                                    Aircasa Pvt Ltd reserves its right to deny
                                    in its sole discretion any user access to
                                    this Site or any portion hereof without
                                    notice.
                              </p>
                              <p>
                                    No waiver by Aircasa Pvt Ltd of any
                                    provision of these Terms and Conditions
                                    shall be binding except as set forth in
                                    writing and signed by its duly authorized
                                    representative.
                              </p>
                              <p>
                                    If any dispute arises between you and
                                    Aircasa Pvt Ltd during your use of the Site
                                    or thereafter, in connection with and
                                    arising from your use or attempt to use this
                                    Site, the dispute shall be referred to
                                    arbitration. The place of arbitration shall
                                    be Delhi. The arbitration proceedings shall
                                    be in the English language.
                              </p>
                              <p>
                                    The said arbitration proceedings shall be
                                    governed and construed in accordance with
                                    the Arbitration and Conciliation Act, 1996
                                    and modifications thereof as in force at the
                                    relevant time.
                              </p>
                              <p>
                                    These terms and conditions are governed by
                                    and shall be construed in accordance with
                                    the laws of the Republic of India and any
                                    dispute shall exclusively be subject to the
                                    jurisdiction of the appropriate Courts
                                    situated at Mumbai, India.
                              </p>
                              <h3>Aircasa Terms & Conditions</h3>
                              <p>
                                    The following general terms and conditions
                                    apply, however regional circumstances and
                                    regulations, contractual obligations to
                                    suppliers, and matters relating to location,
                                    product type and supply logistics may cause
                                    these to be varied slightly for specific
                                    products, or specific destinations. These
                                    amendments and alterations are shown in the
                                    Terms And Conditions sections of each web
                                    site, and should be read in conjunction with
                                    the Terms And Conditions shown below.
                              </p>
                              <ul>
                                    <li>
                                          Travel arrangements and prices
                                          detailed on our web sites are provided
                                          and coordinated by Aircasa Pvt Ltd.
                                    </li>
                                    <li>
                                          Aircasa has taken due care and
                                          responsibility to verify and check all
                                          information on our web sites as at the
                                          time of compilation, however, as this
                                          information is supplied by the
                                          relevant airlines & GDS in our
                                          brochures, it accepts no
                                          responsibility for any inaccuracy or
                                          misdescription contained in the
                                          publications.
                                    </li>
                                    <li>
                                          Sales of services as contained in our
                                          publications are made by Aircasa only
                                          as agent for the person, business or
                                          company providing the services. The
                                          standards of air booking and other
                                          services chosen are based on various
                                          factors, which are generally accepted
                                          as indicative of a certain class.
                                    </li>
                                    <li>
                                          Aircasa does not accept responsibility
                                          for any change in prices or variation
                                          of services as shown, and all services
                                          and prices are subject to change
                                          without notice.
                                    </li>
                                    <li>
                                          Aircasa does not accept any liability
                                          of whatever nature for the acts,
                                          omissions or default, whether
                                          negligent or otherwise, of those
                                          service providers in connections with
                                          your convention pursuant to a contract
                                          between them and yourselves and over
                                          whom we have no direct control. We do
                                          not accept liability in contract or in
                                          tort (actionable wrong) for any
                                          injury, damage, loss, delay,
                                          additional expenses or inconvenience
                                          caused directly or indirectly by force
                                          majeure or other events which are
                                          beyond our control, or which are not
                                          preventable by reasonable diligence on
                                          our part including, but not limited to
                                          war, civil disturbance, fire, floods,
                                          unusually severe weather, acts of God,
                                          acts of Government or of any other
                                          authorities, accidents to or failure
                                          of machinery or equipment or
                                          industrial action.
                                    </li>
                                    <li>
                                          It is essential that you check with
                                          us, or your travel agent, for any
                                          changes or variations to the
                                          information shown on our web sites.
                                          This will allow us to advise you, or
                                          your agent, of any such changes that
                                          we are aware of either before you book
                                          your holiday, or prior to your
                                          departure.
                                    </li>
                              </ul>
                              <h3>Terms and Conditions of Booking</h3>
                              <p>
                                    <strong>Rates and Price Variations:</strong>{" "}
                                    We reserve the right to vary prices and
                                    rates in the event of changes in exchange
                                    rates or price rises made by wholesalers or
                                    other suppliers. If the cost of any service
                                    increases due to exchange rate fluctuations,
                                    price increases, tax changes or any other
                                    reason, you are required to pay the increase
                                    when notified by us or you may cancel the
                                    booking which may result in cancellation
                                    fees. We are not liable in any way if any
                                    increase occurs. Rates quoted are
                                    appropriate to the particular product at the
                                    time of quoting and these rates may change
                                    prior to the travel date. All prices are
                                    subject to availability and can be withdrawn
                                    or varied without notice.
                              </p>
                              <p>
                                    <strong>Final Payment:</strong> Final
                                    payment must be paid immediately when
                                    requested prior to travel date. No vouchers
                                    will be issued until final payment is
                                    received in our office. Final payment
                                    conditions for certain air, Car, Hotel and
                                    tour products may vary, these are shown on
                                    the individual pages. We will advise you in
                                    writing of these conditions at the time of
                                    booking. Please note the final payment may
                                    vary from the original booking price or
                                    quote if the product is subject to exchange
                                    rate fluctuations or price rises by
                                    wholesalers or other suppliers.
                              </p>
                              <p>
                                    <strong>Card Fees:</strong> Please note that
                                    a card fee will be applied automatically to
                                    credit card payment amounts:
                                    <p>
                                          This fee covers a range of costs
                                          associated with processing bookings
                                          paid for by credit card including the
                                          merchant fees of the various credit
                                          card companies, payment processing
                                          costs, administration costs, the cost
                                          of maintaining IT systems used for
                                          payment security to minimise credit
                                          card fraud, credit card chargebacks
                                          and associated fees.
                                    </p>
                              </p>
                              <p>
                                    <strong>
                                          Payments made by cheque or direct
                                          deposit do not attract any fees.
                                    </strong>
                              </p>
                              <p>
                                    <strong>ADM POLICY:</strong>
                                    <p>
                                          Any ADM arising due to the below
                                          mentioned reasons will have to be
                                          borne by the Travel Partner
                                    </p>
                                    <ul>
                                          <li>GDS Misuse</li>
                                          <li>
                                                Fictitious Names or Fake Name
                                                Like Test Names / Wrong Names
                                          </li>
                                          <li>Duplicate Bookings</li>
                                          <li>
                                                Churning for same segment /
                                                Flight / Date
                                          </li>
                                          <li>
                                                Hold Bookings must be released
                                                or issued before Time Limit to
                                                avoid Agent Debit Memo (ADM)
                                          </li>
                                          <li>
                                                In Case the Booking is aborted
                                                at the time of Hold or Booking -
                                                Please dont issue tickets
                                                multiple times, Please contact
                                                our call centre for further
                                                action - ADM's will be borne by
                                                the agent if not guided by this
                                                policy
                                          </li>
                                    </ul>
                              </p>
                              <p>
                                    <strong>
                                          Payment Processing Terms & Conditions:
                                    </strong>{" "}
                                    By providing your credit card details and
                                    accepting our Terms & Conditions, you
                                    authorise Aircasa to arrange for funds to be
                                    debited from your nominated credit card, in
                                    accordance with the terms & conditions of
                                    the Direct Debit Request Service Agreement
                                    as amended from time to time.
                              </p>
                              <p>
                                    Your bank or credit card provider may apply
                                    currency conversion fees. Credit Cards are
                                    required to secure bookings if you are
                                    travelling within 14 days.
                              </p>
                              <p>
                                    <strong>
                                          Standard Cancellation Policy:
                                    </strong>
                                    <ul>
                                          <li>
                                                Cancellation with in 12 Hours of
                                                Scheduled Departure Time must be
                                                cancelled with the Airline
                                                directly, Aircasa will not be
                                                liable in any way if the
                                                passenger is No Show.
                                          </li>
                                          <li>
                                                All Bookings cancelled may
                                                attract charges levied by
                                                airlines.
                                          </li>
                                    </ul>
                              </p>
                              <p>
                                    Cancellations must be in the form of
                                    Amendment on the portal.
                              </p>
                              <p>
                                    If a credit has been approved it is valid
                                    for 6 months from the date the cancellation
                                    was made.
                              </p>
                              <p>
                                    <strong>Flight Cancellation Policy:</strong>
                                    <ul>
                                          <li>
                                                Flights booked on this website
                                                are governed by the terms and
                                                conditions of the airfare you
                                                purchased and are determined by
                                                the Airline (not Aircasa).
                                          </li>
                                          <li>
                                                In most cases, airfares are
                                                fully non-refundable and
                                                non-transferable.
                                          </li>
                                          <li>
                                                Airline charges or part or full
                                                cancellation fees may apply to
                                                your particular airfare.
                                          </li>
                                          <li>
                                                A Travel Consultant will help
                                                you wherever possible within
                                                these terms and conditions.
                                          </li>
                                    </ul>
                              </p>
                              <p>
                                    <strong>
                                          Special Cancellation Conditions:
                                    </strong>{" "}
                                    Certain air,car,hotel and tour products will
                                    apply additional cancellation charges. These
                                    cancellation conditions and costs are
                                    located under the pricing on the individual
                                    pages and will be clearly advised to you in
                                    writing at time of booking.
                              </p>
                              <p>
                                    <strong>Amendment Fees:</strong> Any
                                    amendments made to confirmed bookings will
                                    incur a fee; The fees are charged per
                                    amendment. This is in addition to any fees
                                    that may be levied by the supplier or
                                    airline.
                              </p>
                              <p>
                                    <strong>
                                          Credit Card Chargeback Fees:
                                    </strong>{" "}
                                    Any fees charged to Aircasa by our credit
                                    card payment provider arising from a
                                    chargeback or a disputed charge on the
                                    cardholder's credit card will be charged to
                                    the cardholder. This fee is non-refundable.
                              </p>
                              <p>
                                    <strong>
                                          Change Of Itinerary After booking Has
                                          Commenced:
                                    </strong>{" "}
                                    Any alteration or cancellation of services
                                    after your booking has commenced can incur
                                    penalties. There is no refund for unused
                                    services.
                              </p>
                              <p>
                                    <strong>Refunds:</strong> All refund
                                    requests must be in writing, and made direct
                                    to us or through the Agent from whom the
                                    travel arrangements were purchased. Claims
                                    must be made prior to departure time.
                                    Refunds will not be made for bookings
                                    cancelled due to inclement weather or
                                    illness. These must be claimed through your
                                    travel Insurance. No refunds will be made
                                    for services once travel arrangements have
                                    commenced. No guarantee is provided or
                                    warranted that any refund will be available.
                                    if payment done twice for one transaction,
                                    the one transaction amount will be refunded
                                    as Deposit in Aircasa Account within 07 to
                                    10 working days. The refund will be
                                    processed only after it is received from the
                                    airline/supplier in the company's bank
                                    account.
                              </p>
                              <p>
                                    <strong>Reporting of Incidents:</strong> Any
                                    abnormal incidents including injuries,
                                    service problems, cancellation of a service
                                    or dissatisfaction must be reported to
                                    Aircasa during the event to allow us an
                                    opportunity to rectify the situation or
                                    provide assistance.
                              </p>
                              <h3>Visas</h3>
                              <p>
                                    The Travel Partner assures that any visa
                                    applications submitted are only genuine
                                    cases, and takes full responsibility of the
                                    client / passengers / traveler.
                              </p>
                              <p>
                                    The Travel Partner ensures to adhere to all
                                    requirements imposed by the Immigration and
                                    consulate of all the countries through your
                                    office with regards to the documentation for
                                    the Visa application.
                              </p>
                              <p>
                                    The Travel Partner irrevocably indemnifies,
                                    keep indemnified, saved and harmless M/s
                                    Trip Jack Pvt Ltd, its officers, employees,
                                    agents, from and against all cost, charges,
                                    claims, damages and the like that may arise
                                    or be attributable to any or all such
                                    passengers defaulting in their terms &
                                    conditions for grant of Visa to visit the
                                    required countries and whose applications
                                    are submitted through us.
                              </p>
                              <p>
                                    The Travel Partner agrees that it has agreed
                                    for Trip Jack Pvt Ltd to carry out
                                    processing of Visa application for the
                                    different countries.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd’s role is limited to
                                    accepting the visa application and required
                                    documentation, forwarding the application to
                                    the consulate and immigration authorities
                                    for consideration of visa issuance and
                                    communication on the application.
                              </p>
                              <p>
                                    Visa Applications are considered on
                                    individual merit, the consulate and the
                                    Immigration Department has the right to seek
                                    additional information at any stage of
                                    processing.
                              </p>
                              <p>
                                    Immigration Authority and the consulate
                                    reserves the right to approve or refuse a
                                    visa without assigning any reason
                                    whatsoever.
                              </p>
                              <p>
                                    The decision to grant or refuse a visa is
                                    the sole prerogative of Immigration
                                    Department and consulate of the respective
                                    countries. Trip Jack Pvt Ltd cannot have any
                                    influence on the same issue.
                              </p>
                              <p>
                                    Once Visa applied is chargeable irrespective
                                    of Visa grant or rejection by the
                                    immigration / consulate.
                              </p>
                              <p>
                                    It is the Travel Partner’s responsibility to
                                    verify the documents correctly before
                                    sharing it or submitting it to Trip Jack Pvt
                                    Ltd i.e. (Passport front and back page
                                    should be same, photograph, additional
                                    documents for Minor, observation page on
                                    passport, and any other forms or documents
                                    required by the immigration / consulate)
                              </p>
                              <p>
                                    It is the Travel Partner’s responsibility to
                                    check the visa category correctly before
                                    sending any requests.
                              </p>
                              <p>
                                    For OK to Board message for the required
                                    countries, the Travel Partner needs to
                                    advise Trip Jack Pvt Ltd of the PNR atleast
                                    72 – 48 hours prior to the Guest Departure
                                    during normal working days and 96 – 72 hours
                                    prior to weekends; i.e Friday and Saturday.
                              </p>
                              <p>
                                    It will be the Travel Partner’s
                                    responsibility to verify and reconfirm the
                                    message with airlines before 24 hours prior.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd has complete rights to
                                    reject any application as per its quality
                                    check norms and profile of the passenger.
                              </p>
                              <p>
                                    Both parties agrees to all terms and
                                    conditions narrated in this agreement.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd hereby declares that they
                                    have no role whatsoever in the assessment of
                                    a visa application, which is the sole
                                    prerogative of the government of the
                                    particular countries’ consulate and
                                    Immigration department. Therefore, Trip Jack
                                    Pvt Ltd will not, in any manner, be liable
                                    or responsible for any delay in the
                                    processing or rejection of any visa
                                    applications.
                              </p>
                              <p>
                                    Issuance and Approval of a visa is solely
                                    regulated by government of the particular
                                    country applying the visa and governed by
                                    their rules and regulations that may be
                                    amended from time to time.
                              </p>
                              <p>
                                    Once applied, Visa fee is nonrefundable
                                    under any circumstances whatsoever.
                              </p>
                              <p>
                                    In case of rejection of visa application,
                                    the applicant will be informed. No
                                    correspondence will be entertained, no visa
                                    fees will be refunded and no reasons will be
                                    required to be given.
                              </p>
                              <p>
                                    It is understood that whenever the
                                    processing of the visa application is
                                    prevented, delayed, restricted or interfered
                                    with for any reasons whatsoever by the
                                    consulate and Immigration authorities to
                                    process visitor application, then Trip Jack
                                    Pvt Ltd shall not be liable to any visitor
                                    for any loss or damage which may be suffered
                                    as result of such causes and Trip Jack Ltd
                                    shall be discharged of all its obligations
                                    hereunder.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd shall not be liable for
                                    any losses or damages, which the guest may
                                    suffer arising from delay in processing or
                                    receiving the visa.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd shall take all reasonable
                                    measures to ensure that information provided
                                    by the guarantor/visitor in its application
                                    form shall remain confidential. However Trip
                                    Jack Pvt Ltd shall not be liable for any
                                    unauthorized access by any means to that
                                    information.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd will not be liable for
                                    fine to be paid for the passenger if he/she
                                    was involved in any criminal offences.
                              </p>
                              <p>
                                    To allow or revoke an applicant from
                                    entering the country will be decided by the
                                    immigration officers at the port of entry.
                                    Trip Jack Pvt Ltd will not be responsible
                                    for the decision of the immigration officer.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd will not be liable for
                                    delayed approvals in case of systems errors
                                    or any other technical issues at the
                                    Consulate or at the Immigration.
                              </p>
                              <p>
                                    Trip Jack Pvt Ltd will not be liable for any
                                    damages, loss and delay of documents in
                                    courier services, any third party or during
                                    any natural calamities. Courier charges are
                                    not included in visa charges.
                              </p>
                              <p>
                                    To defend personal information and to avoid
                                    illegal access, we have put in place proper
                                    safety measures and certifications. While no
                                    system is full-proof, including ours, we
                                    will continue using internet security
                                    procedures to make sure that your data
                                    remains secure with us. By opening,
                                    browsing, using this site for transactions
                                    or storing any data/information, the Travel
                                    Partner agrees to fulfil with the latest
                                    revised privacy policy in effect at such
                                    time. If the Travel Partner uses some social
                                    networking or other service which maintains
                                    your information, it is governed by their
                                    terms of use and privacy policy. While Trip
                                    Jack Pvt Ltd utilizes necessary security
                                    measures, it is limited by technology used
                                    for different processes by Trip Jack Pvt Ltd
                                    and/or by its partners. Trip Jack Pvt Ltd is
                                    not in any way accountable for any data loss
                                    happening from malicious attempts to
                                    compromise its or partners/suppliers
                                    systems. The Travel Partner agrees with such
                                    limitation and experience.
                              </p>
                              <p>
                                    The Travel Partner hereby declares and
                                    undertakes to you that it shall ensure that
                                    all its’ passengers strictly adhere to the
                                    Flight schedule submitted at the time of
                                    submission of the Visa application form. The
                                    Travel Partner takes full responsibility of
                                    all passengers for whom it submits the
                                    visas. Also, the Travel Partner assures that
                                    all passengers will depart from the country
                                    within the validity of the Visa period,
                                    however if any of its passengers do overstay
                                    or are absconding on sponsored visa, then
                                    the Travel Partner is liable to pay Trip
                                    Jack Pvt Ltd the stipulated penalty decided
                                    by the consulate/ immigration authorities
                                    within 5 working days of notification by
                                    Trip Jack as per the rate of exchange on
                                    that day. Also, the Travel Partner confirms
                                    that the visitor will not work anywhere or
                                    violate specially UAE/Oman/Bahrain/Qatar and
                                    sponsored visas for all other countries law
                                    while under Trip Jack Pvt Ltd sponsorship.
                                    Should the person found guilty, in
                                    whatsoever situation, the Travel Partner has
                                    to bear the penalty if it comes from
                                    consulate/ immigration authorities/
                                    suppliers /local authorities to Trip Jack
                                    Pvt Ltd. The Travel Partner shall also bear
                                    the penalty even If any of such liabilities
                                    are routed via third party suppliers/
                                    associates/ or other parties through which
                                    the transaction is routed.
                              </p>
                              <h3>General</h3>
                              <p>
                                    <strong>Validity of Rates:</strong>Prices
                                    are valid for the dates indicated.
                              </p>
                              <p>
                                    <strong>Currency:</strong> All rates are
                                    quoted in Indian Rupees, or as otherwise
                                    indicated.
                              </p>
                              <p>
                                    <strong>Payment Options:</strong> Aircasa
                                    offers a secure server for your credit card
                                    payments. Alternatively, you can fax through
                                    your credit card details to us, send a
                                    cheque or make a direct deposit.
                              </p>
                              <p>
                                    <strong>Eticket:</strong> Eticket details
                                    will be email to the address you gave us
                                    when you made your reservation within 5
                                    minutes also you get the Eticket copy
                                    through Print Eticket tab on top right
                                    corner .
                              </p>
                              <p>
                                    <strong>Accommodation:</strong>{" "}
                                    Accommodation is as specified in the
                                    accommodation section of our web sites. All
                                    costs are shown in Australian dollars,
                                    unless otherwise stated. We reserve the
                                    right to offer alternative accommodation of
                                    a similar standard in the case the
                                    accommodation operAircasar overbooks their
                                    property or for any reason beyond our
                                    control. All rates are subject to change
                                    without notice. Not all properties provide
                                    instant availability and in these cases
                                    Aircasa checks availability once we receive
                                    the booking request.
                              </p>
                              <p>
                                    <strong>Flights:</strong> Airfares show
                                    instant availability and fares at the time
                                    of quoting however we will not proceed with
                                    booking flights until your accommodation is
                                    confirmed. This is to avoid strict fees
                                    charged by airlines for flight amendments.
                                    This may result in fare and/or availability
                                    changes.Check In / Check Out Times: These
                                    vary according to operAircasar. Generally
                                    your accommodation check in is 2pm and check
                                    out 10am. Please note that most properties
                                    will require a credit card authorisation on
                                    check-in and may need to see photo
                                    identification. In the instance that you
                                    don't have a credit card usually a cash bond
                                    will be required.
                              </p>
                              <p>
                                    <strong>Single Supplement Costs:</strong> If
                                    not shown in your selected accommodation
                                    cost, this is available on application.
                              </p>
                              <p>
                                    <strong>Accommodation Ratings:</strong> Are
                                    based on our own inspections and some in
                                    conjunction with our hotel supplier. Our
                                    ratings are for guidance only and are not to
                                    be construed as AAA endorsement.
                              </p>
                              <p>
                                    <strong>Group And Incentive Travel:</strong>{" "}
                                    As group and conference rates are specially
                                    contracted rates specific for that
                                    particular group, they have different terms
                                    and conditions to standard holidays. If you
                                    are booking a group or incentive, please
                                    ensure that these conditions are explained
                                    to you.
                              </p>
                              <p>
                                    All maps, photographs, illustrations and
                                    computer based graphics are included for
                                    general purposes only and are not always
                                    indicative of the subject matter. Hotel room
                                    photographs (graphics) may not be specific
                                    to the actual room occupied. Maps are not to
                                    scale.
                              </p>
                              <p>
                                    <strong>Quotations:</strong> All quotations
                                    are subject to availability of services
                                    quoted and are not guaranteed until the
                                    booking is confirmed and deposit received.
                                    Quotes are valid for 48 hours from issue
                                    date.
                              </p>
                              <h3>Inclusions and Exclusions</h3>
                              <p>
                                    <strong>Prices DO Include:</strong>{" "}
                                    Accommodation, transfers, tours/cruises, car
                                    hire, government taxes and any other
                                    inclusions as specified in writing on the
                                    relevant web pages for each suppliers
                                    product.
                              </p>
                              <p>
                                    <strong>Prices DO NOT Include:</strong> Any
                                    costs incurred en route to and from the
                                    destination, meals (unless specified),
                                    alcoholic beverages, telephone calls, items
                                    of a personal nature, and personal services
                                    not specified as being included in costs on
                                    the relevant web pages for each suppliers
                                    product. Rates do not include Marine Park
                                    taxes, as this must be paid direct to the
                                    operAircasars.
                              </p>
                              <h3>Site Copyright</h3>
                              <p>
                                    The copyright on all original/custom
                                    artwork, maps, navigation elements,
                                    presentation methods and design elements
                                    included in our web sites is held
                                    exclusively by Aircasa. Copyright on all
                                    descriptive text is held by Aircasa.
                                    Copyright on selected photographic images is
                                    also held by Aircasa.
                              </p>
                              <p>
                                    Photographic images are reproduced with the
                                    permission of Aircasa and the product and/or
                                    service providers represented on the web
                                    site, or as shown where necessary to satisfy
                                    the copyright obligations associated with
                                    their electronic reproduction.
                              </p>
                              <p>
                                    where joint copyright is held over
                                    proprietary text or images, Aircasa reserves
                                    the right to pursue all copyright
                                    infringements on behalf of the primary
                                    copyright holder. Where the copyright for a
                                    photographic image is held exclusively by a
                                    third party under license, Aircasa reserves
                                    the right to vigorously protect the
                                    copyright of those images on behalf of the
                                    third party.
                              </p>
                              <p>
                                    All HTML files used in Aircasa web sites are
                                    declared to be proprietary software product
                                    developed for use by Aircasa only, and as
                                    such are subject to international
                                    intellectual property conventions.
                              </p>
                              <p>
                                    All custom graphics used are declared to be
                                    works of art created for use by Aircasa
                                    only, and as such are subject to
                                    international intellectual property
                                    conventions. Image files, HTML documents,
                                    and text files are not to be relocated to
                                    another server, or duplicated for commercial
                                    purposes without the express written
                                    permission of Aircasa.
                              </p>
                              <p>
                                    Aircasa, trading under its subsidiary
                                    business names, is a member of various
                                    Indian Regional Tourism Authorities, and as
                                    such exercises the right to reproduce images
                                    made available by these various
                                    organizations to their members for the
                                    purpose of generic and/or specific tourism
                                    promotion.
                              </p>
                              <p>
                                    Please contact us if you have any further
                                    queries about our security, privacy or data
                                    handling procedures.
                              </p>
                              <h3>Terms and Conditions of Online Payments</h3>
                              <p>
                                    <strong>
                                          Payments/Top Ups/ Paid for Order:
                                    </strong>{" "}
                                    Please note that any transaction done
                                    through any Online Payment by our registered
                                    agents or walking customer comes to an
                                    agent. In case of Walk-in customer swiping
                                    the card for a booking or Wallet update,
                                    Card holder is fully responsible for it and
                                    if any dispute/Chargeback raised will be
                                    taken care by the Aircasa registered agent.
                              </p>
                              <p>
                                    <strong>Wallet Update:</strong> If any
                                    transaction through any online payment mode
                                    and payment get success however booking
                                    fails than that transaction amount
                                    (Excluding Payment gateway charges) will be
                                    credited to Aircasa Agent ID wallet.
                                    <div>
                                          In-case of processing refund, based on
                                          standing instruction from the
                                          Trip-jack Agent it will be refunded
                                          through respective payment mode used
                                          while transaction.
                                    </div>
                              </p>
                              <p>
                                    <strong>Payment Gateway Fees:</strong>{" "}
                                    Please note that any payment Gateway fee
                                    will be applied automatically to the online
                                    payment option. Payment gateway charges will
                                    be Non-refundable in any circumstances.
                              </p>
                              <p>
                                    <strong>
                                          Payment Processing Terms & Conditions:
                                    </strong>{" "}
                                    By providing your credit card details and
                                    accepting our Terms & Conditions, you
                                    authorise Aircasa to arrange for funds to be
                                    debited from your nominated credit card, in
                                    accordance with the terms & conditions of
                                    the Direct Debit Request Service Agreement
                                    as amended from time to time.
                              </p>
                              <p>
                                    Your bank or credit card provider may apply
                                    currency conversion fees. Credit Cards are
                                    required to secure bookings if you are
                                    travelling within 14 days.
                              </p>
                              <p>
                                    <strong>
                                          Credit Card Chargeback Fees:
                                    </strong>{" "}
                                    Any fees charged to Aircasa by our credit
                                    card payment provider arising from a
                                    chargeback or a disputed charge on the
                                    cardholder's credit card will be charged to
                                    the cardholder or registered agency. his fee
                                    is non-refundable.
                              </p>
                              <p>
                                    <strong>Outstanding:</strong> If any
                                    outstanding is due, its unpaid after several
                                    reminders, Aircasa has rights to recover by
                                    future flown tickets.
                              </p>
                              <p>
                                    <strong>Payment Options:</strong> Aircasa
                                    offers a secure server for your credit card
                                    payments.
                              </p>
                              <h3>Promotion</h3>
                              <p>
                                    Aircasa will use your personal information
                                    to send you communications related to
                                    transactions, bookings, promotions, offers,
                                    surveys, new products or services etc. using
                                    emails, text messages, instant messaging
                                    platforms such as WhatsApp, etc., calls, IVR
                                    etc.
                              </p>
                        </PrivacyContainer>
                  </DashboardContainer>
            </>
      );
};

export default TermsAndConditions;
