import React, { useRef, useState } from "react";

import {
      FlightInfoRangeContainer,
      FlightInfoRangeContent,
      FlightInfoRangeDate,
      FlightInfoRangePrice,
} from "./styles";

const FLightInfoRange = ({ flightRanges }) => {
      return (
            <>
                  <FlightInfoRangeContainer className="d-flex align-items-center pt-5">
                        {/* <Swiper
					slidesPerView={3}
					spaceBetween={10}
					pagination={{
						clickable: true,
					}}
					modules={[Pagination]}
				>
					<SwiperSlide>
						<div>Slide 1</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Slide 2</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Slide 2</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Slide 2</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Slide 2</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Slide 2</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Slide 2</div>
					</SwiperSlide>
				</Swiper> */}
                        {flightRanges.map((item, index) => (
                              <FlightInfoRangeContent key={index}>
                                    <FlightInfoRangeDate>
                                          {item.day}
                                    </FlightInfoRangeDate>
                                    <FlightInfoRangePrice>
                                          {item.price}
                                    </FlightInfoRangePrice>
                              </FlightInfoRangeContent>
                        ))}
                  </FlightInfoRangeContainer>
            </>
      );
};

export default FLightInfoRange;
