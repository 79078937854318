import React, { useState } from "react";
import RoundtripLayout from "../RoundtripLayout";
import { RoundtripContainer } from "./style";
import RoundtripBook from "../RoundtripBook";

const RoundtripFlight = ({ flights, returnFlights, netFare, searchDetail }) => {
      const [fareId, setFareId] = useState({});
      const [returnFare, setReturnFare] = useState({});

      return (
            <>
                  {flights?.length > 0 && (
                        <>
                              <RoundtripContainer>
                                    <RoundtripLayout
                                          flights={flights}
                                          netFare={netFare}
                                          fareId={fareId}
                                          setFareId={setFareId}
                                          totalFlights={
                                                flights[0]?.totalPriceList[0]
                                          }
                                          searchDetail={searchDetail}
                                    />
                                    <RoundtripLayout
                                          flights={returnFlights}
                                          netFare={netFare}
                                          setFareId={setReturnFare}
                                          fareId={returnFare}
                                          searchDetail={searchDetail}
                                    />
                              </RoundtripContainer>
                              <RoundtripBook
                                    fareId={fareId}
                                    flights={flights}
                                    returnFlights={returnFlights}
                                    returnFare={returnFare}
                                    searchDetail={searchDetail}
                                    netFare={netFare}
                              />
                        </>
                  )}
            </>
      );
};

export default RoundtripFlight;
