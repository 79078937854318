import { Link } from "react-router-dom";
import styled from "styled-components";
export const PackageCard = styled(Link)``;
export const PackageWrapper = styled.div`
      border: 1px solid ${(props) => props.theme.textColor};
      padding: 16px;
      border-radius: 20px;
      display: block;
      max-width: 100%;
      background-color: ${(props) => props.theme.white};
      &:hover {
            img {
                  transform: scale(1.1);
            }
      }
`;
export const PackageImage = styled.div`
      overflow: hidden;
      height: 350px;
      border-radius: 20px;
      border: 1px solid ${(props) => props.theme.textColor};

      img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            border-radius: 20px;
            transition: transform 0.3s linear 0s;
      }
`;
export const PackageContent = styled.div`
      margin-top: 10px;
      text-align: center;
      color: ${(props) => props.theme.textColor};
`;

export const PackageTitle = styled.h3`
      font-size: 19px;
      text-transform: capitalize;
      font-weight: 400;
      padding-bottom: 5px;
      transition: all 0.4s ease-in-out;
      &:hover {
            color: ${(props) => props.theme.primary};
      }
`;

export const PackageText = styled.p`
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 400;
`;
