import styled from "styled-components";

export const DashboardListContainer = styled.div`
      padding-bottom: 30px;
`;
export const DashboardListTitle = styled.h3`
      font-size: 15px;
      margin-bottom: 15px;
`;
export const DashboardListItem = styled.li`
      label {
            font-size: 13px;
      }
`;

export const DashboardListWrapper = styled.ul``;
export const DashboardListValue = styled.div`
      font-size: 13px;
      color: #666666;
`;
