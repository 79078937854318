import React, { useCallback, useEffect, useState } from "react";
import DepartureList from "../../../Dashboard/Departure";
import FilterDeparture from "../FilterDeparture";
import FilterAirlines from "../FilterAirlines";
import DashboardList from "../../../Dashboard/DashboardList";

const OnewayFilter = ({
      flights,
      searchDetails,
      setFilteredFlights,
      lists,
      arrivalLists,
      stopLists,
}) => {
      const [airlinesList, setAirlinesList] = useState([]);
      const [departures, setDepartures] = useState([]);
      const [arrivals, setArrivals] = useState([]);
      const [stops, setStops] = useState([]);

      const findItems = useCallback((items) => {
            let flightItems = items.find((item) => item?.fD);
            return flightItems?.fD;
      }, []);

      // Initialize airlines
      useEffect(() => {
            const airlineMap = flights.reduce((acc, flight) => {
                  let newItem = findItems(flight?.sI);
                  if (newItem) {
                        const code = newItem.aI.code;
                        if (!acc[code]) {
                              acc[code] = {
                                    ...newItem,
                                    totalFlights: 0,
                                    checked: false,
                              };
                        }
                        acc[code].totalFlights += 1;
                  }
                  return acc;
            }, {});

            setAirlinesList(Object.values(airlineMap));
      }, [flights, findItems]);

      // Initialize departures, arrivals, and stops
      useEffect(() => {
            setDepartures(lists[0]?.items || []);
            setArrivals(arrivalLists[0]?.items || []);
            setStops(stopLists[0]?.items || []); // Initialize stops
      }, [lists, arrivalLists, stopLists]);

      // Handle click events
      const handleAirlineClick = (index) => {
            setAirlinesList((prevList) =>
                  prevList.map((airline, i) =>
                        i === index
                              ? { ...airline, checked: !airline.checked }
                              : airline
                  )
            );
      };

      const handleDepartureClick = (index) => {
            setDepartures((prevList) =>
                  prevList.map((departure, i) =>
                        i === index
                              ? { ...departure, checked: !departure.checked }
                              : departure
                  )
            );
      };

      const handleStopClick = (index) => {
            setStops((prevList) =>
                  prevList.map((stop, i) =>
                        i === index ? { ...stop, checked: !stop.checked } : stop
                  )
            );
      };

      const handleArrivalClick = (index) => {
            setArrivals((prevList) =>
                  prevList.map((arrival, i) =>
                        i === index
                              ? { ...arrival, checked: !arrival.checked }
                              : arrival
                  )
            );
      };

      // Calculate stop counts
      const calculateStopCounts = (flightsList) => {
            let stopCounts = { 0: 0, 1: 0, "1+": 0 };

            flightsList.forEach((flight) => {
                  const totalStops = flight.sI.reduce(
                        (sum, segment) => sum + segment.stops,
                        0
                  );
                  if (totalStops === 0) {
                        stopCounts[0]++;
                  } else if (totalStops === 1) {
                        stopCounts[1]++;
                  } else {
                        stopCounts["1+"]++;
                  }
            });

            return stopCounts;
      };

      // Filter flights and update stops
      useEffect(() => {
            const checkedDeparture = departures
                  .filter((item) => item.checked)
                  .map((item) => item.title);

            let flightsFilteredByTime = flights;

            if (checkedDeparture.length > 0) {
                  flightsFilteredByTime = flights.filter((flight) => {
                        const flightSegments = flight.sI;
                        return flightSegments.some((segment) => {
                              const dt = new Date(segment.dt);
                              const hours = dt.getHours();

                              return checkedDeparture.some((timeSlot) => {
                                    if (timeSlot === "00 - 06" && hours < 6)
                                          return true;
                                    if (
                                          timeSlot === "06 - 12" &&
                                          hours >= 6 &&
                                          hours < 12
                                    )
                                          return true;
                                    if (
                                          timeSlot === "12 - 18" &&
                                          hours >= 12 &&
                                          hours < 18
                                    )
                                          return true;
                                    if (
                                          timeSlot === "18 - 00" &&
                                          hours >= 18
                                    )
                                          return true;
                                    return false;
                              });
                        });
                  });
            }

            const checkedArrival = arrivals
                  .filter((item) => item.checked)
                  .map((item) => item.title);

            let flightsFilteredByArrival = flightsFilteredByTime;

            if (checkedArrival.length > 0) {
                  flightsFilteredByArrival = flightsFilteredByTime.filter(
                        (flight) => {
                              const flightSegments = flight.sI;
                              return flightSegments.some((segment) => {
                                    const at = new Date(segment.at);
                                    const hours = at.getHours();

                                    return checkedArrival.some((timeSlot) => {
                                          if (
                                                timeSlot === "00 - 06" &&
                                                hours < 6
                                          )
                                                return true;
                                          if (
                                                timeSlot === "06 - 12" &&
                                                hours >= 6 &&
                                                hours < 12
                                          )
                                                return true;
                                          if (
                                                timeSlot === "12 - 18" &&
                                                hours >= 12 &&
                                                hours < 18
                                          )
                                                return true;
                                          if (
                                                timeSlot === "18 - 00" &&
                                                hours >= 18
                                          )
                                                return true;
                                          return false;
                                    });
                              });
                        }
                  );
            }

            const checkedAirlines = airlinesList
                  .filter((airline) => airline.checked)
                  .map((airline) => airline.aI.code);

            let finalFilteredFlights = flightsFilteredByArrival;

            if (checkedAirlines.length > 0) {
                  finalFilteredFlights = flightsFilteredByArrival.filter(
                        (flight) =>
                              flight?.sI?.some((segment) =>
                                    checkedAirlines.includes(
                                          segment?.fD?.aI?.code
                                    )
                              )
                  );
            }

            // Calculate stop counts and update stop values
            const stopCounts = calculateStopCounts(finalFilteredFlights);

            // Update stops only if there's a change
            setStops((prevStops) => {
                  const updatedStops = prevStops.map((stop) => ({
                        ...stop,
                        value:
                              stop.id === 1
                                    ? stopCounts[0]
                                    : stop.id === 2
                                    ? stopCounts[1]
                                    : stopCounts["1+"],
                  }));

                  // Only update if there's an actual change
                  return JSON.stringify(updatedStops) !==
                        JSON.stringify(prevStops)
                        ? updatedStops
                        : prevStops;
            });

            // Filter flights based on selected stops
            const checkedStops = stops
                  .filter((item) => item.checked)
                  .map((item) => item.title);

            let finalFlightsWithStops = finalFilteredFlights;

            if (checkedStops.length > 0) {
                  finalFlightsWithStops = finalFilteredFlights.filter(
                        (flight) => {
                              const totalStops = flight.sI.reduce(
                                    (sum, segment) => sum + segment.stops,
                                    0
                              );

                              return checkedStops.some((stopType) => {
                                    if (
                                          stopType === "Non-stop" &&
                                          totalStops === 0
                                    )
                                          return true;
                                    if (
                                          stopType === "1 Stop" &&
                                          totalStops === 1
                                    )
                                          return true;
                                    if (
                                          stopType === "1+ Stops" &&
                                          totalStops > 1
                                    )
                                          return true;
                                    return false;
                              });
                        }
                  );
            }

            setFilteredFlights(
                  checkedAirlines.length === 0 &&
                        checkedDeparture.length === 0 &&
                        checkedArrival.length === 0 &&
                        checkedStops.length === 0
                        ? flights
                        : finalFlightsWithStops
            );
      }, [airlinesList, departures, arrivals, flights, stops]);

      return (
            <>
                  <DashboardList
                        lists={stopLists}
                        stops={stops}
                        handleClick={handleStopClick}
                  />
                  <DepartureList
                        lists={lists}
                        departures={departures}
                        searchDetails={searchDetails}
                        handleClick={handleDepartureClick}
                  />
                  <FilterDeparture
                        lists={arrivalLists}
                        searchDetails={searchDetails}
                        arrivals={arrivals}
                        handleClick={handleArrivalClick}
                  />
                  <FilterAirlines
                        airlinesList={airlinesList}
                        handleClick={handleAirlineClick}
                  />
            </>
      );
};

export default OnewayFilter;
