import React from "react";

const MapIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">location_on</span>
            </>
      );
};

export default MapIcon;
