import React from "react";
import { Col, FormCheck, Row } from "react-bootstrap";
import { FormInput } from "../../forms";

const FrequentForm = ({ handleInputChange, travellers, index }) => {
      return (
            <>
                  <Col lg={12}>
                        <FormCheck
                              checked={travellers[index]?.frequentStatus}
                              onChange={(event) =>
                                    handleInputChange(
                                          index,
                                          event,
                                          "SELECT",
                                          !travellers[index]?.frequentStatus,
                                          "frequentStatus"
                                    )
                              }
                              label="Frequent Flyer (optional)"
                        />
                  </Col>
                  {travellers[index]?.frequentStatus && (
                        <Col lg={12}>
                              <Row>
                                    <FormInput
                                          title="Frequent Flyer Airline"
                                          required={
                                                travellers[index]
                                                      ?.frequentStatus
                                          }
                                          placeholder="IX"
                                          self={(event) =>
                                                handleInputChange(
                                                      index,
                                                      event,
                                                      "",
                                                      "",
                                                      ""
                                                )
                                          }
                                          name="frequentAirline"
                                          value={
                                                travellers[index]
                                                      ?.frequentAirline
                                          }
                                    />
                                    <FormInput
                                          title="Frequent Flyer Number"
                                          required={
                                                travellers[index]
                                                      ?.frequentStatus
                                          }
                                          placeholder="FI Number"
                                          self={(event) =>
                                                handleInputChange(
                                                      index,
                                                      event,
                                                      "",
                                                      "",
                                                      ""
                                                )
                                          }
                                          name="frequentNumber"
                                          value={
                                                travellers[index]
                                                      ?.frequentNumber
                                          }
                                    />
                              </Row>
                        </Col>
                  )}
            </>
      );
};

export default FrequentForm;
