import React, { useEffect, useState } from "react";
import {
      FlightInfoButton,
      FlightInfoContainer,
      FlightInfoContent,
      FlightInfoDuration,
      FlightInfoLogo,
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "./styles";
import CommonButton from "../../Common/CommonButton";
import FlightAccordion from "../FlightAccordion";
import FlightPrice from "../FlightPrice";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";

const FlightInfo = ({ flights, item, netFare, searchDetail, fullFare }) => {
      const previousSearch = useLocation().pathname;
      const previousQuery = useLocation().search;
      const previousLink = `${previousSearch}${previousQuery}`;

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const arrivalTime = (sI) => {
            const totalSi = sI.length;
            const lastSi = sI[totalSi - 1];
            return lastSi;
      };

      const [status, setStatus] = useState(false);
      const [fareId, setFareId] = useState([]);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const checkCabinClassPrice = () => {
            return item?.totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );
      };

      useEffect(() => {
            setFareId(checkCabinClassPrice()[0]);
      }, [flights]);

      const handleSubmit = (event) => {
            event.preventDefault();
            setLoading(true);
            const optionId = event.target.elements.option.value;

            localStorage.setItem("previousSearch", previousLink);
            localStorage.setItem("reviewId", optionId);

            localStorage.removeItem("contactDetails");
            localStorage.removeItem("gstDetails");
            localStorage.removeItem("travellers");
            localStorage.removeItem("services");
            localStorage.removeItem("totalAmount");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/travellers/${optionId}`);
            }, 3000);
      };

      const calculateTotalAmount = () => {
            let newAmount = 0;
            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.ADULT?.fC?.TF *
                              (fullFare ? searchDetail?.adult : 1);
            }
            if (fareId?.fd?.CHILD) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.CHILD?.fC?.TF *
                              (fullFare ? searchDetail?.children : 1);
            }
            if (fareId?.fd?.INFANT) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.INFANT?.fC?.TF *
                              (fullFare ? searchDetail?.infants : 1);
            }

            return convertAmount(convertFloat(newAmount));
      };

      const calculateTotalNetAmount = () => {
            let newAmount = 0;
            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.ADULT?.fC?.NF *
                              (fullFare ? searchDetail?.adult : 1);
            }

            if (fareId?.fd?.CHILD) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.CHILD?.fC?.NF *
                              (fullFare ? searchDetail?.children : 1);
            }
            if (fareId?.fd?.INFANT) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.INFANT?.fC?.NF *
                              (fullFare ? searchDetail?.infants : 1);
            }
            return convertAmount(convertFloat(newAmount));
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <FlightInfoContainer status={status}>
                              <FlightInfoContent>
                                    <FlightInfoTitle className="gap-2">
                                          <FlightInfoLogo>
                                                <img
                                                      src={`/images/AirlinesLogo/${item?.sI[0]?.fD?.aI?.code}.png`}
                                                      alt={
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.name
                                                      }
                                                />
                                          </FlightInfoLogo>
                                          <div className="title">
                                                <h4 className="pb-0 mb-0">
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.name
                                                      }
                                                </h4>
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                >
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.code
                                                      }{" "}
                                                      {item?.sI[0]?.fD?.fN}
                                                </div>
                                          </div>
                                    </FlightInfoTitle>

                                    <FlightInfoTime>
                                          {formatTime(item?.sI[0]?.dt)}
                                          <div
                                                className="place d-flex gap-1 align-items-center"
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {searchDetail?.leavingFrom}
                                                <span
                                                      className="material-symbols-outlined"
                                                      style={{
                                                            fontSize: "15px",
                                                      }}
                                                >
                                                      east
                                                </span>
                                                {searchDetail?.destination}
                                          </div>
                                    </FlightInfoTime>

                                    <FlightInfoTime>
                                          {formatTime(
                                                arrivalTime(item?.sI)?.at
                                          )}
                                    </FlightInfoTime>

                                    <FlightInfoDuration>
                                          {`${
                                                calculateDuration(
                                                      item?.sI[0]?.duration
                                                ).hours
                                          }h ${
                                                calculateDuration(
                                                      item?.sI[0]?.duration
                                                ).minutes
                                          }m`}

                                          <div
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {item?.sI?.length === 1
                                                      ? "Non-stop"
                                                      : "1 Stop(s)"}
                                          </div>
                                    </FlightInfoDuration>

                                    <FlightInfoPrice>
                                          <div className="flight__info-price">
                                                {"₹ "}
                                                {calculateTotalAmount()}
                                                {netFare && (
                                                      <span className="flight__info-net text-muted">{` | NET ${calculateTotalNetAmount()}`}</span>
                                                )}
                                          </div>
                                          {fareId?.fd?.ADULT?.sR <= 5 && (
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                      className="text-danger"
                                                >
                                                      {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                </div>
                                          )}
                                    </FlightInfoPrice>

                                    <FlightInfoButton>
                                          <CommonButton
                                                title={"Book Now"}
                                                slug={"/checkout"}
                                                padding={"10px 20px"}
                                                fontSize={"13px"}
                                                width={"90px"}
                                                button={true}
                                                type="submit"
                                                loading={loading}
                                          />
                                    </FlightInfoButton>
                              </FlightInfoContent>
                              <FlightPrice
                                    setStatus={setStatus}
                                    status={status}
                                    setFareId={setFareId}
                                    fullFare={fullFare}
                                    fareId={fareId}
                                    totalPriceList={item?.totalPriceList}
                                    searchDetail={searchDetail}
                              />
                              {status && (
                                    <div className="bg-gray w-100">
                                          <FlightAccordion
                                                fareId={fareId}
                                                items={item}
                                                setFareId={setFareId}
                                                searchDetail={searchDetail}
                                          />
                                    </div>
                              )}
                        </FlightInfoContainer>
                  </Form>
            </>
      );
};

export default FlightInfo;
