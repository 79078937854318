import React from "react";
import { FooterButtonContainer } from "./styles";

const FooterButton = ({ title }) => {
	return (
		<>
			<FooterButtonContainer>{title}</FooterButtonContainer>
		</>
	);
};

export default FooterButton;
