import React, { useContext, useRef, useState } from "react";

import { OwnershipRegsiterContainer } from "../OwnershipRegsiter/styles";
import {
      LoginFormSection,
      LoginInput,
} from "../../../../frontend/login/styles";
import { Col, FormLabel, FormSelect, Row } from "react-bootstrap";

import CommonButton from "../../../Common/CommonButton";
import {
      MapIcon,
      CountryIcon,
      LocationHomeIcon,
      PhoneIcon,
      MobileIcon,
      MailIcon,
      DotIcon,
      GlobeIcon,
      PinIcon,
      UserIcon,
} from "../../../../icons";
import FileInput from "../../../form/FileInput";
import FormInput from "../../../form/FormInput";
import axios from "axios";
import toast from "react-hot-toast";
import { FormContext } from "../../../../context/FormContext";

const CommunicationRegsiter = ({
      handleRegisterForm,
      visibleBlock,
      gender,
      dateOfBirth,
      companyName,
      ownerType,
      gstStatus,
      gstNumber,
      gstFile,
}) => {
      const { formData, setFormData } = useContext(FormContext);
      const [activeForm, setActiveForm] = useState("login");

      const handlePhoneNumber = (value) => {
            setFormData({ ...formData, ["phoneNumber"]: value });
      };

      const handleEmail = (value) => {
            setFormData({ ...formData, ["email"]: value });
      };

      const regFormRef = useRef(null);
      const [loading, setLoading] = useState(false);

      // Communication details
      const [officeAddress, setOfficeAddress] = useState("");
      const [country, setCountry] = useState("");
      const [state, setState] = useState("");
      const [city, setCity] = useState("");
      const [mobileNumber, setMobileNumber] = useState("");
      const [alternativeMobileNumber, setAlternativeMobileNumber] =
            useState("");
      const [panNumber, setPanNumber] = useState("");
      const [panFile, setPanFile] = useState("");
      const [addressProofType, setAddressProofType] = useState("");
      const [addressProofFront, setAddressProofFront] = useState("");
      const [addressProofBack, setAddressProofBack] = useState("");
      const [officeAddressProof, setOfficeAddressProof] = useState("");
      const [pincode, setPincode] = useState("");
      const [website, setWebsite] = useState("");

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = new FormData();
            data.append("first_name", formData.firstName);
            data.append("last_name", formData.lastName);
            data.append("email", formData.email);
            data.append("phone_number", formData.phoneNumber);
            data.append("password", formData.password);
            data.append("password_confirmation", formData.password);
            data.append("office_address", officeAddress);
            data.append("country", country);
            data.append("state", state);
            data.append("city", city);
            data.append("mobile_number", mobileNumber);
            data.append("alternative_mobile_number", alternativeMobileNumber);
            data.append("pan_no", panNumber);
            data.append("pan_file", panFile);
            data.append("address_proof_type", addressProofType);
            data.append("address_front", addressProofFront);
            data.append("address_back", addressProofBack);
            data.append("office_address_proof", officeAddressProof);
            data.append("pincode", pincode);
            data.append("website", website);
            data.append("date_of_birth", dateOfBirth);
            data.append("gender", gender);
            data.append("gst_status", gstStatus);
            data.append("gst_number", gstNumber);
            data.append("gst_file", gstFile);
            data.append("company_name", companyName);
            data.append("owner_type", ownerType);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/register`,
                        data,
                        {
                              header: {
                                    apikey: process.env.REACT_APP_API_KEY,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                                    setFormData({
                                          country: "",
                                          phoneNumber: "",
                                          firstName: "",
                                          lastName: "",
                                          email: "",
                                          password: "",
                                    });
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message) {
                                          if (
                                                response.data.message.first_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .first_name
                                                );
                                          }
                                          if (response.data.message.last_name) {
                                                toast.error(
                                                      response.data.message
                                                            .last_name
                                                );
                                          }
                                          if (response.data.message.password) {
                                                toast.error(
                                                      response.data.message
                                                            .password
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .phone_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .company_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .company_name
                                                );
                                          }
                                          if (
                                                response.data.message.owner_type
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .owner_type
                                                );
                                          }
                                          if (response.data.message.gst_file) {
                                                toast.error(
                                                      response.data.message
                                                            .gst_file
                                                );
                                          }
                                          if (
                                                response.data.message.gst_status
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .gst_status
                                                );
                                          }
                                          if (
                                                response.data.message.gst_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .gst_number
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .office_address
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .office_address
                                                );
                                          }
                                          if (response.data.message.country) {
                                                toast.error(
                                                      response.data.message
                                                            .country
                                                );
                                          }
                                          if (response.data.message.state) {
                                                toast.error(
                                                      response.data.message
                                                            .state
                                                );
                                          }
                                          if (response.data.message.city) {
                                                toast.error(
                                                      response.data.message.city
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .mobile_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .mobile_number
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .alternative_mobile_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .alternative_mobile_number
                                                );
                                          }
                                          if (response.data.message.pan_no) {
                                                toast.error(
                                                      response.data.message
                                                            .pan_no
                                                );
                                          }
                                          if (response.data.message.pan_file) {
                                                toast.error(
                                                      response.data.message
                                                            .pan_file
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .address_proof_type
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .address_proof_type
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .address_front
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .address_front
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .address_back
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .address_back
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .office_address_proof
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .office_address_proof
                                                );
                                          }
                                          if (response.data.message.pincode) {
                                                toast.error(
                                                      response.data.message
                                                            .pincode
                                                );
                                          }
                                          if (response.data.message.website) {
                                                toast.error(
                                                      response.data.message
                                                            .website
                                                );
                                          }
                                    }
                              }
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setLoading(false);
                        }, 3000);
                  });
      };

      return (
            <>
                  <OwnershipRegsiterContainer
                        className="d-flex justify-content-center"
                        show={visibleBlock === "communication"}
                  >
                        <LoginFormSection
                              onSubmit={handleForm}
                              ref={regFormRef}
                              style={{
                                    transform:
                                          activeForm === "register"
                                                ? "translateX(-450px)"
                                                : "translateX(0px)",
                              }}
                              className="col-lg-10"
                        >
                              <Row className="px-0 g-3">
                                    <FormInput
                                          title="Office Address"
                                          icon={<LocationHomeIcon />}
                                          required={true}
                                          value={officeAddress}
                                          handleChange={setOfficeAddress}
                                    />
                                    <FormInput
                                          title="Country"
                                          icon={<CountryIcon />}
                                          required={true}
                                          value={country}
                                          handleChange={setCountry}
                                    />
                                    <FormInput
                                          title="State"
                                          icon={<MapIcon />}
                                          required={true}
                                          value={state}
                                          handleChange={setState}
                                    />
                                    <FormInput
                                          title="City/District"
                                          icon={<MapIcon />}
                                          required={true}
                                          value={city}
                                          handleChange={setCity}
                                    />
                                    <FormInput
                                          title="Phone Number"
                                          icon={<PhoneIcon />}
                                          required={true}
                                          value={formData.phoneNumber}
                                          handleChange={handlePhoneNumber}
                                    />
                                    <FormInput
                                          title="Mobile No."
                                          icon={<MobileIcon />}
                                          value={mobileNumber}
                                          handleChange={setMobileNumber}
                                    />
                                    <FormInput
                                          title="Alternative Mobile No."
                                          icon={<MobileIcon />}
                                          value={alternativeMobileNumber}
                                          handleChange={
                                                setAlternativeMobileNumber
                                          }
                                    />
                                    <FormInput
                                          title="Email Address"
                                          icon={<MailIcon />}
                                          required={true}
                                          handleChange={handleEmail}
                                          value={formData.email}
                                    />
                                    <FormInput
                                          title="PAN No."
                                          icon={<UserIcon />}
                                          required={true}
                                          value={panNumber}
                                          handleChange={setPanNumber}
                                    />
                                    <FileInput
                                          title="Upload PAN Card"
                                          required={true}
                                          handleFile={setPanFile}
                                          placeholder="Upload PAN Card"
                                    />
                                    <Col lg={4}>
                                          <FormLabel>
                                                Address Proof Type{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <LoginInput className="ps-2 ">
                                                <DotIcon />
                                                <FormSelect
                                                      onChange={(event) =>
                                                            setAddressProofType(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                >
                                                      <option value="">
                                                            Select One
                                                      </option>
                                                      <option value="Adhar Card">
                                                            Adhar Card
                                                      </option>
                                                </FormSelect>
                                          </LoginInput>
                                    </Col>
                                    <Col lg={4}>
                                          <Row>
                                                <FileInput
                                                      title="Address Front"
                                                      required={true}
                                                      classes={6}
                                                      handleFile={
                                                            setAddressProofFront
                                                      }
                                                      placeholder="Address Front"
                                                />
                                                <FileInput
                                                      title="Address Back"
                                                      required={true}
                                                      classes={6}
                                                      handleFile={
                                                            setAddressProofBack
                                                      }
                                                      placeholder="Address Back"
                                                />
                                          </Row>
                                    </Col>
                                    <FileInput
                                          title="Office Address Proof"
                                          required={true}
                                          handleFile={setOfficeAddressProof}
                                          placeholder="Office Address Proof"
                                    />
                                    <FormInput
                                          title="Pincode"
                                          icon={<PinIcon />}
                                          value={pincode}
                                          required={true}
                                          handleChange={setPincode}
                                    />
                                    <FormInput
                                          title="Website"
                                          icon={<GlobeIcon />}
                                          value={website}
                                          handleChange={setWebsite}
                                    />
                                    <Col
                                          lg={12}
                                          className="p-0 d-flex align-items-center justify-content-center gap-3 mt-4"
                                    >
                                          <CommonButton
                                                title={"Back"}
                                                button={true}
                                                color={"#555"}
                                                background={"#DFE6F0"}
                                                handleClick={(event) =>
                                                      handleRegisterForm(
                                                            event,
                                                            "company"
                                                      )
                                                }
                                          />
                                          <CommonButton
                                                title={"Submit"}
                                                button={true}
                                                type={"submit"}
                                                loading={loading}
                                          />
                                    </Col>
                              </Row>
                        </LoginFormSection>
                  </OwnershipRegsiterContainer>
            </>
      );
};

export default CommunicationRegsiter;
