import styled from "styled-components";

export const HotelLocation = styled.div`
      position: absolute;
      top: calc(100% + 10px);
      background-color: white;
      width: 150%;
      z-index: 99999999999;
      border-radius: 5px;
      max-height: 270px;
      overflow-x: scroll;
      box-shadow: rgb(0 0 0 / 25%) 0px 0px 0px 1px;

      ul {
            padding: 0;
            margin: 0;

            li {
                  list-style: none;
                  padding: 10px 20px;
                  cursor: pointer;
                  border-bottom: 1px dashed ${(props) => props.theme.primary};

                  &:hover {
                        background-color: #f0f0f0;
                  }

                  &:first-child {
                        border-top-right-radius: 5px;
                        border-top-left-radius: 5px;
                  }

                  &:last-child {
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border-bottom: 0;
                  }

                  .airline__name {
                        font-size: 13px;
                  }
            }
      }
`;

export const AirlineContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const AirlineInfo = styled.div`
      .airline__destination {
            font-size: 16px;
      }
`;

export const AirlineCode = styled.div`
      font-size: 16px;
      font-weight: 600;
`;
