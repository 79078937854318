import { Container } from "react-bootstrap";
import styled from "styled-components";

export const FooterContainer = styled.footer`
      padding: 2rem 0rem;
      border-top: 1px solid ${(props) => props.theme.primary};
`;
export const FooterContent = styled(Container)`
      padding: 2rem 0rem;
`;
export const FooterLogo = styled.div`
      margin-bottom: 1rem;
      img {
            width: 160px;
            height: auto;
            object-fit: contain;
      }
`;
export const FooterBlock = styled.div``;

export const FooterText = styled.div`
      font-size: 15px;
      max-width: 300px;
      color: ${(props) => props.theme.textColor};
      margin-bottom: 1rem;
`;
export const FooterSocial = styled.div`
      display: flex;
      align-items: center;
      gap: 0.8rem;
`;
export const FooterList = styled.ul`
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style-type: none;
      margin: 0;
      padding: 0;
`;
export const FooterListItem = styled.li`
      font-size: 15px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 1rem;
      a {
            display: block;

            color: ${(props) => props.theme.textColor};
            transition: all 0.4s ease-in-out;
            &:hover {
                  color: ${(props) => props.theme.primary};
            }
      }
`;
export const FooterListIcon = styled.div`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.textColor};
      display: flex;
      justify-content: center;
      align-items: center;
      img {
            width: 18px;
            height: 18px;
      }
`;

export const FooterCopyRight = styled.div`
      text-align: center;
`;
export const FooterCopyRightText = styled.p``;
