import React from "react";
import BannerBox from "../../components/Frontend/home/BannerSection";
import PackageBox from "../../components/Frontend/home/PackageBox";
import {
	homepackages,
	packagesData,
} from "../../data/banners";
import PackageForm from "../../components/Frontend/package/PackageForm";

const PackagesPage = () => {
	return (
		<>
			<BannerBox banners={packagesData}>
				<PackageForm
					width={" 80%"}
					marginBottom={"30px"}
					marginTop={"30px"}
					wrap={false}
				/>
			</BannerBox>
			<PackageBox packagesData={homepackages} />
		</>
	);
};

export default PackagesPage;
