import React, { useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import {
      B2BIcon,
      LoginAction,
      LoginAdditional,
      LoginButton,
      LoginButtonContainer,
      LoginContainer,
      LoginDetail,
      LoginDetailContent,
      LoginFormContainer,
} from "./styles";
import { Navigate } from "react-router-dom";
import SignupForm from "./SignupForm";
import LoginForm from "./LoginForm";

const Login = () => {
      const [activeForm, setActiveForm] = useState("login");

      const login = localStorage.getItem("isLoggedIn");

      if (login) {
            return <Navigate to="/" />;
      }

      const handleRegister = () => {
            setActiveForm("register");
      };

      const handleLogin = () => {
            setActiveForm("login");
      };

      return (
            <>
                  <LoginContainer style={{ height: "100vh" }}>
                        <Container className="h-100" fluid>
                              <Row className="h-100">
                                    <LoginDetail lg={6}>
                                          <LoginDetailContent lg={7}>
                                                <LoginAction>
                                                      <B2BIcon>
                                                            <img
                                                                  src={
                                                                        "/images/svg/b2bicon.svg"
                                                                  }
                                                            />
                                                      </B2BIcon>
                                                      <LoginButtonContainer
                                                            activeForm={
                                                                  activeForm
                                                            }
                                                      >
                                                            <LoginButton
                                                                  onClick={
                                                                        handleLogin
                                                                  }
                                                                  className="p-3"
                                                            >
                                                                  Login
                                                            </LoginButton>
                                                            <LoginButton
                                                                  onClick={
                                                                        handleRegister
                                                                  }
                                                                  className="p-3"
                                                            >
                                                                  Sign Up
                                                            </LoginButton>
                                                      </LoginButtonContainer>
                                                </LoginAction>
                                                <LoginFormContainer>
                                                      <LoginForm
                                                            activeForm={
                                                                  activeForm
                                                            }
                                                      />
                                                      <SignupForm
                                                            activeForm={
                                                                  activeForm
                                                            }
                                                      />
                                                </LoginFormContainer>
                                          </LoginDetailContent>
                                    </LoginDetail>
                                    <Col lg={6}>
                                          <LoginAdditional>
                                                <Row className="g-5 grid gap-0 row-gap-3"></Row>
                                          </LoginAdditional>
                                    </Col>
                              </Row>
                        </Container>
                  </LoginContainer>
            </>
      );
};

export default Login;
