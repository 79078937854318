import React from "react";
import { CommonTitleContainer } from "./styles";

const CommonTitle = ({ title }) => {
      return (
            <>
                  <CommonTitleContainer>
                        <h2>{title}</h2>
                  </CommonTitleContainer>
            </>
      );
};

export default CommonTitle;
