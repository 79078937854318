import React, { useEffect, useState } from "react";
import {
      RoomAccount,
      RoomButton,
      RoomDescription,
      RoomImage,
      RoomInfo,
      RoomItem,
      RoomPrice,
      RoomTitle,
} from "../HotelRoom/style";
import { HotelImageSlider } from "../../../components/sliders";
import {
      HotelItemOffer,
      HotelOfferItem,
} from "../../../components/Frontend/hotel/HotelCard/style";
import axios from "axios";
import toast from "react-hot-toast";

const parseRoomDetails = (details) => {
      return details
            .split(" - ")
            .map((item) => item.trim())
            .filter((item) => item);
};

const RoomItemBox = ({ item, hotel }) => {
      const [loading, setLoading] = useState(false);
      const [reviews, setReviews] = useState([]);
      const handleImage = (data) => {
            const images = data?.filter(
                  (item) => (item.sz === "XL" || item.sz === "XXL") && item
            );

            return images;
      };

      const loadData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotel-review`,
                        {
                              hotelId: hotel?.id,
                              optionId: item?.id,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setLoading(false);
                        setReviews(response.data);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <RoomItem>
                        <div className="d-flex">
                              <RoomInfo>
                                    {item?.ris[0]?.imgs && (
                                          <RoomImage>
                                                <HotelImageSlider
                                                      images={handleImage(
                                                            item?.ris[0]?.imgs
                                                      )?.slice(0, 8)}
                                                      height="160px"
                                                />
                                          </RoomImage>
                                    )}
                                    <div>
                                          <RoomTitle>
                                                {item?.ris[0]?.rc}
                                          </RoomTitle>
                                    </div>
                              </RoomInfo>

                              <RoomDescription>
                                    <HotelItemOffer
                                          marginBottom="10px"
                                          marginTop="0"
                                    >
                                          {item?.ris[0]?.rexb?.BENEFIT[0]
                                                ?.values?.length > 0 &&
                                                item?.ris[0]?.rexb?.BENEFIT[0]?.values?.map(
                                                      (data) => (
                                                            <HotelOfferItem>
                                                                  {data}
                                                            </HotelOfferItem>
                                                      )
                                                )}
                                    </HotelItemOffer>
                                    {item?.ris[0].des &&
                                          parseRoomDetails(
                                                item?.ris[0].des
                                          ).map((detail, index) => (
                                                <li key={index}>{detail}</li>
                                          ))}
                              </RoomDescription>
                              <RoomAccount>
                                    <RoomPrice>₹ {item?.tp}</RoomPrice>
                                    <RoomButton>Book Now</RoomButton>
                                    {!loading
                                          ? reviews?.conditions?.isBA
                                                ? "blocking"
                                                : "unblocking"
                                          : "Loading"}
                              </RoomAccount>
                        </div>
                        {/* <img src={images[0]?.url} alt="" /> */}
                  </RoomItem>
            </>
      );
};

export default RoomItemBox;
