import React from "react";
import {
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
      FlightLayoverContainer,
      FlightLayoverContent,
      FlightLayoverLine,
      FlightLayoverTitle,
} from "../FlightDetails/style";

const BaggageInformation = ({ items }) => {
      return (
            <>
                  <FlightDetailContainer>
                        {items?.sI?.map((item, index) => (
                              <FlightDetailCategory>
                                    {index === 0 &&
                                          `${item?.da?.city} to
                                                      ${item?.aa?.city}`}
                              </FlightDetailCategory>
                        ))}
                        <FlightDetailInfo>
                              {items?.sI?.map((item, index) => (
                                    <>
                                          <FlightDetailAirline>
                                                <div>
                                                      <strong>Sector</strong>
                                                      <div>
                                                            {item?.da?.code} -{" "}
                                                            {item?.aa?.code}
                                                      </div>
                                                </div>
                                                <div>
                                                      <strong>Check in</strong>
                                                      <div>
                                                            {
                                                                  items
                                                                        ?.totalPriceList[
                                                                        index
                                                                  ]?.fd?.ADULT
                                                                        ?.bI?.iB
                                                            }
                                                            {" /"}
                                                            <span>
                                                                  {" person"}
                                                            </span>
                                                      </div>
                                                </div>
                                                <div>
                                                      <strong>Cabin</strong>
                                                      <div>
                                                            {
                                                                  items
                                                                        ?.totalPriceList[
                                                                        index
                                                                  ]?.fd?.ADULT
                                                                        ?.bI?.cB
                                                            }
                                                            {" /"}
                                                            <span>
                                                                  {" person"}
                                                            </span>
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                          {items?.sI?.length > 1 &&
                                                items?.sI?.length !==
                                                      index + 1 && (
                                                      <FlightLayoverContainer>
                                                            <FlightLayoverLine />
                                                            <FlightLayoverContent>
                                                                  <FlightLayoverTitle
                                                                        fontWeight="600"
                                                                        color="#224082"
                                                                  >
                                                                        Plane
                                                                        Change
                                                                  </FlightLayoverTitle>
                                                            </FlightLayoverContent>
                                                      </FlightLayoverContainer>
                                                )}
                                    </>
                              ))}
                        </FlightDetailInfo>
                  </FlightDetailContainer>
            </>
      );
};

export default BaggageInformation;
