import React from "react";
import {
      AnimatedIcon,
      AnimatedPlan,
      ButtomIcon,
      MiddleIcon,
} from "../../../icons";
import { FlightLoaderContainer, FlightShadow } from "./style";

const FlightLoader = () => {
      return (
            <>
                  <FlightLoaderContainer>
                        <AnimatedIcon />
                        <MiddleIcon />
                        <AnimatedPlan />
                        <FlightShadow />
                        <ButtomIcon />
                        {/* <div className="plane__text">
                              DEL - Kathmandu
                        </div> */}
                  </FlightLoaderContainer>
            </>
      );
};

export default FlightLoader;
