import React from "react";
import { LoginInput } from "../../../frontend/login/styles";
import { FormControl } from "react-bootstrap";

const FormInputWithIcon = ({
      icon,
      type,
      handleRegisterChange,
      handleType,
      border,
      background,
      placeholder,
}) => {
      return (
            <>
                  <LoginInput className="ps-2 " style={{ border, background }}>
                        {icon}
                        <FormControl
                              name="phoneNumber"
                              type={type}
                              onChange={handleRegisterChange}
                              placeholder={placeholder}
                              onClick={handleType}
                              onBlur={handleType}
                        />
                  </LoginInput>
            </>
      );
};

export default FormInputWithIcon;
