import React from "react";
import BannerBox from "../../components/Frontend/home/BannerSection";
import { flightBannerData } from "../../data/banners";
import PackageBox from "../../components/Frontend/home/PackageBox";
import SearchForm from "../../components/Frontend/home/SearchForm";

const HomePage = () => {
      return (
            <>
                  <BannerBox banners={flightBannerData}>
                        <SearchForm />
                  </BannerBox>
                  <PackageBox />
            </>
      );
};

export default HomePage;
