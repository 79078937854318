import React from "react";
import { FormCheckboxContainer } from "./styles";

const FormCheckbox = ({ icon, name, label, checked, handleChange }) => {
      return (
            <>
                  <FormCheckboxContainer>
                        <input
                              className="form-check-input"
                              type="radio"
                              name={name}
                              value={label}
                              id="flexRadioDefault2"
                              checked={checked}
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                        />
                        {icon && icon}
                        <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                        >
                              {label}
                        </label>
                  </FormCheckboxContainer>
            </>
      );
};

export default FormCheckbox;
