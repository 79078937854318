import styled from "styled-components";

export const CustomFileInputContainer = styled.div`
      padding-right: 8px;
      .file-input {
            display: none;
      }

      .custom-button {
            border: 0;
            padding: 10px 0px;
            width: 100%;
            background-color: transparent;
            font-size: 14px;
            color: #9f9f9f;

            &:hover {
                  cursor: pointer;
            }
      }
`;
