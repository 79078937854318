import styled from "styled-components";

export const PrivacyContainer = styled.div`
      background-color: #ffffff99;
      padding: 15px 20px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

      h3 {
            border-bottom: 1px dashed ${(props) => props.theme.primary};
            padding-bottom: 10px;
            margin-bottom: 20px;
            margin-top: 30px;

            &:first-child {
                  margin-top: 0;
            }
      }
`;
