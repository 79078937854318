import React from "react";
import {
      FlightItemContainer,
      FlightItemItem,
      FlightItemTitle,
      FlightItemValue,
      FlightItemWrapper,
} from "./style";
import { Form } from "react-bootstrap";
import { FlightInfoLogo } from "../../../Dashboard/FlightInfo/styles";

const FlightItem = ({ title, items, handleClick }) => {
      const FlightImage = ({ image, title }) => {
            return (
                  <>
                        <FlightInfoLogo width="24px" height="24px">
                              <img src={image} alt={title} />
                        </FlightInfoLogo>
                        <span>{title}</span>
                  </>
            );
      };

      return (
            <>
                  <FlightItemContainer>
                        <FlightItemTitle>{title}</FlightItemTitle>
                        <FlightItemWrapper className="list-inline mb-0">
                              {items?.length > 0 &&
                                    items?.map((item, index) => (
                                          <>
                                                <FlightItemItem key={index}>
                                                      <Form.Check // prettier-ignore
                                                            type="checkbox"
                                                            checked={
                                                                  item?.checked
                                                            }
                                                            onClick={() =>
                                                                  handleClick(
                                                                        index
                                                                  )
                                                            }
                                                            label={
                                                                  <FlightImage
                                                                        title={
                                                                              item
                                                                                    ?.aI
                                                                                    ?.name
                                                                        }
                                                                        image={`/images/AirlinesLogo/${item?.aI?.code}.png`}
                                                                  />
                                                            }
                                                            className="mb-0"
                                                      />
                                                      <FlightItemValue>
                                                            {item?.totalFlights}
                                                      </FlightItemValue>
                                                </FlightItemItem>
                                          </>
                                    ))}
                        </FlightItemWrapper>
                  </FlightItemContainer>
            </>
      );
};

export default FlightItem;
