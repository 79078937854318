import { Container, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

export const RegisterContainer = styled.div``;

export const RegisterContain = styled(Container)`
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      flex-direction: column;
`;

export const RegsiterContent = styled.div`
      padding-top: 160px;
      height: 100%;

      h2 {
            font-size: 20px;
            font-weight: 300;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 70px;
      }
`;
export const RegisterTitle = styled.div`
      margin: 0 auto;
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
      h1 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            color: ${(props) => props.theme.primary};
            display: table;
            vertical-align: middle;
      }
      span {
            color: ${(props) => props.theme.primary};
      }
`;

export const UserSection = styled.div``;

export const Indicator = styled(Row)``;

//animated svg

export const SvgContainer = styled.div`
      position: relative;
`;
export const SvgWrapper = styled.div`
      position: absolute;
      left: 200px;
      top: -160px;
`;

export const SvgArrow = styled.div`
      position: absolute;
      right: 11px;
      top: 137px;
      font-size: 20px;
      transform: rotate(44deg);
      span {
            color: ${(props) => props.theme.primary};
      }
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
`;
export const draw = keyframes`
  from {
    stroke-dasharray: 5, 5;
  }
  to {
    stroke-dasharray: 300, 0;
  }
`;

export const Svg = styled.svg`
      width: 300px;
      height: 200px;
      cursor: pointer;
`;

export const Path = styled.path`
      stroke: ${(props) => props.theme.primary};
      stroke-width: 1;
      fill: none;
      stroke-dasharray: ${({ animate }) => (animate ? "300, 0" : "5, 5")};
      animation: ${({ animate }) => (animate ? draw : "none")} 2s forwards;
`;

export const Polygon = styled.polygon`
      fill: black;
`;

export const RegisterUserId = styled.div`
      position: relative;
      z-index: 100;
      margin-bottom: 70px;
      border-radius: 4px;
`;
