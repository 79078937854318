import React from "react";

const PinIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">pin</span>
            </>
      );
};

export default PinIcon;
