import styled from "styled-components";

export const FlightTripDetailContainer = styled.div`
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      background: #fff;
      border-radius: 10px;
      padding: 20px 20px;
`;

export const FlightTripDetailCategory = styled.div`
      /* margin-left: -20px; */
      font-size: 13px;
      background: #224082;
      display: inline-block;
      color: #fff;
      border-bottom-right-radius: 15px;
      padding: 4px 16px;
      text-transform: uppercase;
`;

export const FlightTripDetailInfo = styled.div`
      margin-top: 1rem;
      border-bottom: ${(props) => props.border || "1px dashed #224082"};
      padding-bottom: 10px;
      border-top: ${(props) => props.border || "1px dashed #224082"};
      padding-top: 10px;
      font-size: ${(props) => props.fontSize};
`;

export const FlightTripDetailTitle = styled.div`
      color: #1a1a1a;
      font-size: 16px;
      font-weight: 400;
      line-height: 28.57px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;

      span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #6a6868;
      }
`;

export const FlightTripDetailAirline = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: ${(props) => props.marginBottom};
      margin-top: ${(props) => props.marginTop};
      font-size: 14px;
      border-top: ${(props) =>
            props.border && "1px solid" + props.theme.primary};

      .flight__price-content {
            width: 33.33%;
      }

      .flight__trip-title {
            width: 100%;
            margin-top: 5px;
            color: ${(props) => props.theme.primary};
      }

      .flight__price-header {
            font-weight: 600;
      }
`;

export const FlightTripAirline = styled.div`
      display: flex;
      gap: 10px;
      align-items: center;
      width: ${(props) => props.width};
`;

export const FlightTripRefund = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .flight__duration {
            font-size: 14px;
            font-weight: 500;
            line-height: 17.14px;
            text-align: center;
            color: #6a6868;
      }

      .line {
            display: block;
            width: 100%;
            height: 1px;
            background: #b6afafee;
      }

      .flight__refund {
            color: #2dca1c;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            border: 2px solid #2dca1c;
            width: 130px;
            border-radius: 45px;
            /* border: 1px 0px 0px 0px; */
            padding: 4px 16px;
            min-width: max-content;
      }
`;

export const FlightTripAirlineTitle = styled.div`
      div {
            font-size: 16px;
            font-weight: 500;
            line-height: 34.29px;
      }
      span {
            color: #6a6868;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.14px;
            display: block;
      }
`;

export const FlightTripAirlineTime = styled.div`
      width: ${(props) => props.width};
      font-size: 16px;

      div {
            font-size: ${(props) => props.fontSize || "16px"};
            font-weight: 500;
            line-height: 34.29px;
      }

      span {
            color: #6a6868;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.14px;
            display: block;
      }
`;

export const FlightTripLayoverContainer = styled.div`
      width: 100%;
      margin: 1.125rem 0;
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;

      p {
            margin-bottom: 0;
      }
`;

export const FlightTripLayoverLine = styled.div`
      left: 0;
      top: 50%;
      width: 100%;
      height: 0.0625rem;
      position: absolute;
      background: #eaeaea;
`;

export const FlightTripLayoverContent = styled.div`
      z-index: 99;
      position: relative;
      display: inline-block;
      background: #fff;
      padding: 0.25rem 2.5rem;
      border: 1px solid #eaeaea;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 1.875rem 1.875rem 1.875rem 1.875rem;
`;

export const FlightTripLayoverTitle = styled.span`
      color: ${(props) => props.color};
      font-size: ${(props) => props.fontSize || "0.8125rem"};
      margin-top: ${(props) => props.marginTop};
      margin-bottom: 0;
      font-weight: ${(props) => props.fontWeight};
`;
