import styled from "styled-components";

export const LoginInput = styled.div`
      display: flex;
      align-items: center;
      gap: 0px;
      border-radius: var(--bs-border-radius);
      width: 100%;

      svg {
            width: 19px;
            display: inline-block;
            font-size: inherit;
            height: 18px;
            overflow: visible;
            vertical-align: -0.125em;
            color: rgb(119, 119, 119);
      }

      .form-select {
            background-color: transparent;
            box-shadow: none;
            border: 0;
      }

      background: ${(props) => (props.disabled ? "#e9ecef" : "#f0f0f0")};
      input {
            border: 0;
            padding: 10px;
            width: 100%;
            background-color: transparent;
            font-size: 14px;
            color: ${(props) => props.theme.inputColor};
            &:placeholder {
                  color: ${(props) => props.theme.inputColor};
            }
            &:visited,
            &:focus-visible {
                  border: 0;
                  box-shadow: none;
                  outline: 0;
                  background-color: transparent;
            }
            &:disabled {
                  background-color: #e9ecef;
                  opacity: 1;
            }
            &:-webkit-autofill {
                  background-color: white !important;
            }
            /* For Chrome, Safari, and Edge */
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }

            /* For Firefox */
            &:-moz-autofill,
            &:-moz-autofill:hover,
            &:-moz-autofill:focus,
            &:-moz-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }

            /* For Internet Explorer */
            &:-ms-autofill,
            &:-ms-autofill:hover,
            &:-ms-autofill:focus,
            &:-ms-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }
      }
      input[type="file"] {
            &::file-selector-button {
                  background-color: #fff;
                  color: #9f9f9f;
                  border-right: 1px solid #9f9f9f;
                  cursor: pointer;
            }
            &:hover {
                  &::file-selector-button {
                        background-color: #fff;
                        color: #9f9f9f;
                        border-right: 1px solid #9f9f9f;
                        cursor: pointer;
                  }
            }
            &:disabled {
                  background-color: #e9ecef;
                  opacity: 1;
                  &::file-selector-button {
                        background-color: #e9ecef;
                        color: #9f9f9f;
                        border-right: 1px solid #9f9f9f;
                        cursor: pointer;
                  }
            }
      }
      span {
            font-size: 19px;
            color: ${(props) => props.theme.inputColor};
      }
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
`;
