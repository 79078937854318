import styled, { css, keyframes } from "styled-components";
import {
      bounceAnimation,
      rotateAnimation,
} from "../../Frontend/home/TravelerBox/Test";

export const UnoButtonContainer = styled.div`
      width: ${(props) => props.width};
      margin-right: 25px;
      margin-bottom: -20px;

      &:hover {
            cursor: pointer;
      }
      /* Apply animation when active */
      ${(props) =>
            props.isActive &&
            css`
                  animation: ${bounceAnimation} 0.3s ease;
                  transform: rotate(-180deg);
            `}

      span {
            border: 1.5px solid;
            background: white;
            padding: 4px 16px;
      }
      /* Apply rotation to capsule when not active */
      ${(props) =>
            !props.isActive &&
            css`
                  animation: ${rotateAnimation} 0.3s ease;
            `}
`;
