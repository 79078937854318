import React from "react";
import { TravellersTitle } from "../TravellersDetailBox/styles";
import { TravellersForm } from "../../../../frontend/checkout/styles";
import { Col, FormCheck, FormControl, FormLabel, Row } from "react-bootstrap";

const GstForm = ({ gstDetails, handleGstDetails }) => {
      return (
            <>
                  <TravellersTitle className="mb-3 d-flex gap-2">
                        <h3>GST Number for Business Travel (Optional)</h3>
                  </TravellersTitle>
                  <TravellersForm padding="0" className="g-3 ">
                        <Row className="g-3">
                              <Col lg={12}>
                                    <FormCheck
                                          name="gstStatus"
                                          checked={
                                                gstDetails?.gstStatus === "true"
                                                      ? true
                                                      : false
                                          }
                                          value={
                                                gstDetails?.gstStatus ===
                                                "false"
                                                      ? true
                                                      : false
                                          }
                                          onClick={handleGstDetails}
                                          label="I Have a GST number (Optional)"
                                    />
                              </Col>
                              {gstDetails?.gstStatus === "true" && (
                                    <>
                                          <Col lg={4}>
                                                <FormLabel>
                                                      Registration Number
                                                </FormLabel>
                                                <FormControl
                                                      required={
                                                            gstDetails?.gstStatus
                                                      }
                                                      name="registrationNumber"
                                                      onChange={
                                                            handleGstDetails
                                                      }
                                                      value={
                                                            gstDetails?.registrationNumber
                                                      }
                                                />
                                          </Col>
                                          <Col lg={4}>
                                                <FormLabel>
                                                      Registered Company Name
                                                </FormLabel>
                                                <FormControl
                                                      type="text"
                                                      name="companyName"
                                                      required={
                                                            gstDetails?.gstStatus
                                                      }
                                                      onChange={
                                                            handleGstDetails
                                                      }
                                                      value={
                                                            gstDetails?.companyName
                                                      }
                                                />
                                          </Col>
                                          <Col lg={4}>
                                                <FormLabel>
                                                      Registered Email
                                                </FormLabel>
                                                <FormControl
                                                      type="email"
                                                      name="companyEmail"
                                                      required={
                                                            gstDetails?.gstStatus
                                                      }
                                                      onChange={
                                                            handleGstDetails
                                                      }
                                                      value={
                                                            gstDetails?.companyEmail
                                                      }
                                                />
                                          </Col>
                                          <Col lg={4}>
                                                <FormLabel>
                                                      Registered Phone
                                                </FormLabel>
                                                <FormControl
                                                      type="text"
                                                      name="companyPhone"
                                                      required={
                                                            gstDetails?.gstStatus
                                                      }
                                                      onChange={
                                                            handleGstDetails
                                                      }
                                                      value={
                                                            gstDetails?.companyPhone
                                                      }
                                                />
                                          </Col>
                                          <Col lg={4}>
                                                <FormLabel>
                                                      Registered Address
                                                </FormLabel>
                                                <FormControl
                                                      type="text"
                                                      name="companyAddress"
                                                      required={
                                                            gstDetails?.gstStatus
                                                      }
                                                      onChange={
                                                            handleGstDetails
                                                      }
                                                      value={
                                                            gstDetails?.companyAddress
                                                      }
                                                />
                                          </Col>
                                    </>
                              )}
                        </Row>
                  </TravellersForm>
            </>
      );
};

export default GstForm;
