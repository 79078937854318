import React from "react";
import styled from "styled-components";

const IconWrapper = styled.span`
      &:hover {
            cursor: pointer;
      }
`;

const VisbilityIcon = ({ show, setShowPassword }) => {
      const handleClick = () => {
            setShowPassword(true);
      };

      return (
            <>
                  {show && (
                        <IconWrapper
                              className="material-symbols-outlined"
                              onClick={handleClick}
                        >
                              visibility
                        </IconWrapper>
                  )}
            </>
      );
};

export default VisbilityIcon;
