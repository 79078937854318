import { Link } from "react-router-dom";
import styled from "styled-components";

export const RoomContainer = styled.div``;

export const RoomImage = styled.div`
      width: 100%;
`;

export const RoomInfo = styled.div`
      width: 30%;
      padding: 20px;
`;

export const RoomDescription = styled.div`
      border-left: 1px dashed #224082;
      border-right: 1px dashed #224082;
      padding: 20px;
`;

export const RoomItem = styled.div`
      border-radius: 10px;
      margin-bottom: 30px;
      background-color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const RoomTitle = styled.h4`
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
`;

export const RoomAccount = styled.div`
      padding: 20px;
`;

export const RoomPrice = styled.div`
      font-weight: 600;
      font-size: 24px;
`;

export const RoomButton = styled(Link)`
      color: ${(props) => props.theme.white};
      padding: 10px 30px;
      display: block;
      border-radius: 50rem;
      margin-top: 10px;
      background-color: ${(props) => props.theme.primary};
`;
