import React, { useEffect, useState } from "react";
import {
      DashboardButton,
      DashboardContainer,
      DashboardContent,
      DashboardFlightRanges,
      DashboardFlightWrapper,
      DashboardForm,
      DashboardMenu,
      DashboardMenuContent,
      DashboardTitle,
      FilterContainer,
} from "./styles";
import {
      SearchFormAction,
      SearchFormWrapper,
} from "../../components/Frontend/home/SearchForm/styles";
import { Button, Form } from "react-bootstrap";
import {} from "react-router-dom";
import FormItem from "../../components/form";
import UnoButton from "../../components/form/UnoButton";
import CustomButton from "../../components/Common/CustomButton";
import getCurrentDate from "../../hooks/CurrentData";
import ToggleButton from "../../components/Common/CommonToogle";
import DashboardList from "../../components/Dashboard/DashboardList";
import {
      airlines,
      departures,
      flightInfo,
      flightRanges,
      slider,
      stops,
} from "../../data/dashboard";
import FLightInfoRange from "../../components/Dashboard/FlightInfoRange";
import FlightInfo from "../../components/Dashboard/FlightInfo";
import FlightLoader from "../../components/Common/FlightLoader";

const Dashboard = () => {
      const [travelers, setTravelers] = useState([
            {
                  adults: "1",
                  children: "0",
                  infants: "0",
                  class: "Economy",
            },
      ]);
      const [formData, setFormData] = useState({
            leavingfrom: "New Delhi (DEL)",
            destination: " Japiur (JAI)",
            depart: getCurrentDate(),
            return: "",
            travelers: travelers,
      });

      const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                  ...formData,
                  [name]: value,
            });
      };

      const handleSubmit = (e) => {
            e.preventDefault();
            console.log("show");
      };
      const [active, setActive] = useState(false);
      const [changeDestination, setChangeDestination] = useState(false);
      const [loading, setLoading] = useState(true);

      useEffect(() => {
            const timer = setTimeout(() => {
                  setLoading(false);
            }, 2000);
            return () => clearTimeout(timer);
      });
      return (
            <>
                  {!loading ? (
                        <DashboardContainer>
                              <DashboardMenu className="">
                                    <DashboardTitle className="mb-4">
                                          Filter
                                    </DashboardTitle>
                                    <DashboardMenuContent>
                                          <FilterContainer>
                                                <DashboardList lists={stops} />
                                                <DashboardList
                                                      lists={departures}
                                                />
                                                <DashboardList lists={slider} />
                                                <DashboardList
                                                      lists={airlines}
                                                />
                                                <DashboardButton>
                                                      <Button>See More</Button>
                                                </DashboardButton>
                                          </FilterContainer>
                                    </DashboardMenuContent>
                              </DashboardMenu>
                              <DashboardContent>
                                    <DashboardForm>
                                          <SearchFormWrapper>
                                                <Form onSubmit={handleSubmit}>
                                                      <FormItem
                                                            controlId="formName"
                                                            type="text"
                                                            name={
                                                                  changeDestination
                                                                        ? "destination"
                                                                        : "leavingfrom"
                                                            }
                                                            value={
                                                                  changeDestination
                                                                        ? formData.destination
                                                                        : formData.leavingfrom
                                                            }
                                                            onChange={
                                                                  handleChange
                                                            }
                                                            placeholder="New Delhi (DEL)"
                                                            width="14%"
                                                            padding="0px"
                                                            backgroundNone={
                                                                  true
                                                            }
                                                            state={true}
                                                            inputPadding={
                                                                  "0.8rem 0rem"
                                                            }
                                                      />
                                                      <UnoButton
                                                            width={"5%"}
                                                            setChangeDestination={
                                                                  setChangeDestination
                                                            }
                                                            changeDestination={
                                                                  changeDestination
                                                            }
                                                            plane={true}
                                                      />
                                                      <FormItem
                                                            controlId="formName"
                                                            type="text"
                                                            name={
                                                                  changeDestination
                                                                        ? "leavingfrom"
                                                                        : "destination"
                                                            }
                                                            value={
                                                                  changeDestination
                                                                        ? formData.leavingfrom
                                                                        : formData.destination
                                                            }
                                                            onChange={
                                                                  handleChange
                                                            }
                                                            placeholder="Jaipur (JAI)"
                                                            backgroundNone={
                                                                  true
                                                            }
                                                            width="14%"
                                                            state={true}
                                                            inputPadding={
                                                                  "0.8rem 0rem"
                                                            }
                                                      />
                                                      <FormItem
                                                            controlId="formName"
                                                            type="date"
                                                            name="depart"
                                                            value={
                                                                  formData.depart
                                                            }
                                                            onChange={
                                                                  handleChange
                                                            }
                                                            placeholder="Enter Departure Date"
                                                            width="13%"
                                                            state={true}
                                                      />
                                                      <ToggleButton
                                                            active={active}
                                                            setActive={
                                                                  setActive
                                                            }
                                                            margin={
                                                                  "-3px 25px 0px 0px"
                                                            }
                                                      />
                                                      <FormItem
                                                            controlId="formName"
                                                            type="date"
                                                            name="return"
                                                            value={
                                                                  formData.return
                                                            }
                                                            onChange={
                                                                  handleChange
                                                            }
                                                            placeholder="Enter Return Date"
                                                            width="13%"
                                                            state={active}
                                                      />
                                                      <FormItem
                                                            controlId="formName"
                                                            type="number"
                                                            name="name"
                                                            data={travelers}
                                                            value={travelers}
                                                            onChange={
                                                                  handleChange
                                                            }
                                                            placeholder="New Delhi (DEL)"
                                                            width="15%"
                                                            setData={
                                                                  setTravelers
                                                            }
                                                            state={true}
                                                      />

                                                      <SearchFormAction>
                                                            <CustomButton
                                                                  type={
                                                                        "submit"
                                                                  }
                                                                  className="mt-3"
                                                                  title={
                                                                        "Search Flights"
                                                                  }
                                                                  fontSize={
                                                                        "13px"
                                                                  }
                                                                  width={
                                                                        "100px"
                                                                  }
                                                            />
                                                      </SearchFormAction>
                                                </Form>
                                          </SearchFormWrapper>
                                    </DashboardForm>
                                    <FLightInfoRange
                                          flightRanges={flightRanges}
                                    />
                                    <DashboardFlightRanges>
                                          <DashboardFlightWrapper>
                                                {flightInfo?.map(
                                                      (item, index) => (
                                                            <FlightInfo
                                                                  item={item}
                                                            />
                                                      )
                                                )}
                                          </DashboardFlightWrapper>
                                    </DashboardFlightRanges>
                              </DashboardContent>
                        </DashboardContainer>
                  ) : (
                        <DashboardContainer>
                              <div className="col-lg-12">
                                    <FlightLoader />
                              </div>
                        </DashboardContainer>
                  )}
            </>
      );
};

export default Dashboard;
