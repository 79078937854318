import React from "react";
import { Link } from "react-router-dom";
import {
      FooterListIcon,
      FooterListItem,
} from "../../../../layout/footer/styles";
import { FooterItemLink } from "./styles";

const FooterItem = ({ image, titles, link }) => {
      return (
            <>
                  <FooterListItem>
                        {image && (
                              <FooterListIcon>
                                    <img
                                          src={image}
                                          alt={"contact us information"}
                                          width="100%"
                                          height="100%"
                                    />
                              </FooterListIcon>
                        )}
                        <FooterItemLink>
                              {Array.isArray(titles) ? (
                                    titles.map((title, index) => (
                                          <Link key={index} to={link}>
                                                {title}
                                          </Link>
                                    ))
                              ) : (
                                    <Link to={link}>{titles}</Link>
                              )}
                        </FooterItemLink>
                  </FooterListItem>
            </>
      );
};

export default FooterItem;
