import styled from "styled-components";

export const HotelItemContainer = styled.div`
      background-color: ${(props) => props.theme.white};
      border-radius: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-bottom: 20px;
`;

export const HotelItem = styled.div`
      display: flex;
      padding: 5px 5px;
      gap: 10px;
      justify-content: space-between;
`;

export const HotelItemImage = styled.div`
      width: 200px;
      height: 130px;
      border-radius: 5px;
      margin: 10px;

      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
      }
`;

export const HotelItemTitle = styled.div`
      font-size: 20px;
      padding-top: 5px;
      width: ${(props) => props.width};
`;

export const HotelItemInfo = styled.div`
      width: ${(props) => props.width};
`;

export const HotelItemSummary = styled.div`
      font-size: 14px;
      color: #999;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 5px;

      span {
            font-size: 14px;
            line-height: 1;
      }
`;

export const HotelItemOffer = styled.div`
      display: flex;
      gap: 10px;
      margin-top: ${(props) => props.marginTop || "10px"};
      margin-bottom: ${(props) => props.marginBottom};
`;

export const HotelOfferItem = styled.div`
      display: flex;
      align-items: center;
      background-color: #eee;
      color: #888;
      font-size: 12px;
      padding: ${(props) => props.padding || "2.5px 10px"};
      border-radius: 2.5px;
`;

export const HotelSubItem = styled.div`
      border-top: 1px dashed ${(props) => props.theme.primary};
      padding: 10px 15px 16px;
      display: flex;
      justify-content: space-between;
`;

export const HotelSubItemPrice = styled.div`
      color: ${(props) => props.theme.primary};
      font-weight: 600;
`;
