import styled from "styled-components";

export const FormWrapper = styled.div`
      position: relative;
      width: ${(props) => props.width};
      padding-right: ${(props) => (props.padding ? props.padding : "20px")};

      label {
            font-size: 14px;
            padding-left: 0.5rem;
      }

      input {
            font-size: 14px;
            font-weight: 400;
            width: 100%;
            border: 0;
            background-color: #f0f0f0;
            border-radius: 8px;
            padding: 0.8rem 1rem;

            &::placeholder {
                  font-size: 14px;
                  font-weight: 400;
                  color: ${(props) => props.theme.textColor};
            }
      }
`;
export const FormInfo = styled.div`
      position: relative;
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      border: 0;
      background-color: #f0f0f0;
      border-radius: 8px;
      padding: 0.8rem 1rem;
      box-shadow: rgb(0 0 0 / 25%) 0px 0px 0px 1px;

      span {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
            color: ${(props) => props.theme.black};
      }
      &:hover {
            cursor: pointer;
      }
      &:before {
            background-color: transparent;
            background-image: url(/images/svg/download.svg);
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 16px 12px;
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            height: 20px;
            width: 27px;
      }
`;

export const FormAdditional = styled.div`
      position: absolute;
      z-index: 15001;
      top: 88px;
      opacity: 0;
      display: none;
      width: 220px;
      background-color: #fff;
      color: #333;
      font-size: 13px;
      font-weight: normal;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 0px 1px;
      border-radius: 3px;

      &:after {
            -webkit-clip-path: polygon(0 100%, 49% 0, 100% 100%);
            clip-path: polygon(0 100%, 49% 0, 100% 100%);
            content: "";
            height: 15px;
            left: 100px;
            position: absolute;
            top: -9px;
            width: 26px;
            z-index: 10;
      }
      &.show {
            display: block;
            opacity: 1;
      }
`;
export const FormAdditionalHeader = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;

      select {
            width: 80px;
            height: 24px;
            font-size: 12px;
            border: unset;
            border-radius: 4px;
            background: rgb(240, 240, 240);
            display: inline-block;
            background: #f0f0f0;
            font-size: 12px;
            text-align: center;
            line-height: 10px;
      }
`;
export const FormAdditionalTitle = styled.div`
      margin-bottom: 5px;
      font-size: 14px;
`;
export const FormAdditionalCounter = styled.div`
      display: flex;
      align-items: center;
`;

export const FormAdditionalCounterIcon = styled.div`
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 4px 0 0 4px;
      line-height: 32px;
      background: rgb(240, 240, 240);
      align-items: center;
      justify-content: center;
      display: inline-flex;
      text-align: center;
      cursor: pointer;
      -webkit-user-select: none;
      vertical-align: middle;

      &.add {
            border-radius: 0px 4px 4px 0px;
      }
`;
export const FormAdditionalCounterInput = styled.div`
      display: inline-block;
      border-left: 1px solid ${(props) => props.theme.white};
      border-right: 1px solid ${(props) => props.theme.white};
      height: 24px;
      display: inline-block;
      align-items: center;
      justify-content: center;
      background: rgb(240, 240, 240);
      height: 24px;
      width: 30px;
      font-size: 12px;
      text-align: center;
      line-height: 24px;

      input {
            width: 40px !important;
            height: 19px;
            font-size: 12px;
            text-align: center;
            display: inline-flex;
      }
`;

export const AdultChildSection = styled.div`
      border-top: 1px dashed rgba(33, 37, 41, 0.3);
`;
export const AdultChildTitle = styled.div`
      margin-bottom: 9px;
`;
export const AdultSection = styled.div``;
export const ChildSection = styled.div``;
