import React from "react";
import { HotelRatingContainer } from "./style";

const HotelRating = ({ num }) => {
      return (
            <>
                  <HotelRatingContainer>
                        {num === 1 && (
                              <>
                                    <img src="/images/star.png" />
                              </>
                        )}
                        {num === 2 && (
                              <>
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                              </>
                        )}
                        {num === 3 && (
                              <>
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                              </>
                        )}
                        {num === 4 && (
                              <>
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                              </>
                        )}
                        {num === 5 && (
                              <>
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                                    <img src="/images/star.png" />
                              </>
                        )}
                  </HotelRatingContainer>
            </>
      );
};

export default HotelRating;
