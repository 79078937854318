import React from "react";

const DateIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        calendar_month
                  </span>
            </>
      );
};

export default DateIcon;
