import styled from "styled-components";

export const CustomSelectContainer = styled.div`
      position: relative;
      display: inline-block;
      width: 100%;
`;
export const SelectButton = styled.div`
      position: relative;
      width: 100%;
      padding: 2px 6px;
      font-size: 14px;
      border: 1px solid transparent;
      background-color: rgb(240, 240, 240);
      cursor: pointer;
      text-align: left;
      border-radius: 4px;
      border-bottom-left-radius: ${(props) => (props.isOpen ? "0px" : "4px")};
      border-bottom-right-radius: ${(props) => (props.isOpen ? "0px" : "4px")};

      .selected {
            width: ${(props) =>
                  props.selectedWidth ? props.selectedWidth : "100%"};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
      }
`;
export const SelectList = styled.ul`
      position: absolute;
      top: 22px;
      border-top: 0px solid #fff;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 150px;
      overflow-y: auto;
      z-index: 1000;
      background-color: rgb(240, 240, 240);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
`;
export const SelectListItem = styled.li`
      padding: 2px 10px;
      border-top: 1px solid #ddd;
      cursor: pointer;

      &:hover {
            background-color: #f1f1f1;
      }
`;

export const ChevronIcon = styled.div`
      position: absolute;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 24px;
      border-left: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
`;
