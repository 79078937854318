import React from "react";
import styled from "styled-components";

const IconWrapper = styled.span`
      &:hover {
            cursor: pointer;
      }
`;
const NonVisibilityIcon = ({ show, setShowPassword }) => {
      const handleClick = () => {
            setShowPassword(!show);
      };

      return (
            <>
                  <IconWrapper
                        className="material-symbols-outlined"
                        onClick={handleClick}
                  >
                        {show ? "visibility" : "visibility_off"}
                  </IconWrapper>
            </>
      );
};

export default NonVisibilityIcon;
