import React from "react";
import FlightItem from "../FlightItem";

const FilterAirlines = ({ handleClick, airlinesList }) => {
      return (
            <>
                  <FlightItem
                        handleClick={handleClick}
                        title="Airlines"
                        items={airlinesList}
                  />
            </>
      );
};

export default FilterAirlines;
