import React, { useState } from "react";
import {
      FlightDetailContainer,
      FlightDetailInfo,
} from "../../../Dashboard/FlightDetails/style";
import {
      BaggageIcon,
      DoubleIcon,
      MealIcon,
      ServiceIcon,
} from "../../../../icons";
import CommonButton from "../../../Common/CommonButton";
import { useNavigate } from "react-router-dom";
import {
      FlightReviewContent,
      FlightReviewHeader,
      FlightReviewInfo,
      FlightReviewTable,
} from "./style";
import FlightDetailBox from "../../checkout/FlightDetailBox";

const FlightReviewDetail = ({
      currentOption,
      setCurrentOption,
      options,
      setOptions,
      reviewId,
      tripInfos,
}) => {
      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);
      const navigate = useNavigate();

      const handleOption = () => {
            setLoading(true);
            let optionItem = currentOption.index + 1;
            setTimeout(() => {
                  setLoading(false);
                  const updateOptions = options.map((item, i) => {
                        if (i <= optionItem) {
                              return { ...item, status: true };
                        }
                        return { ...item, status: false };
                  });
                  setOptions(updateOptions);
                  setCurrentOption({
                        id: updateOptions[optionItem].id,
                        index: optionItem,
                  });
                  navigate(
                        `/flight/${updateOptions[optionItem].id}/${reviewId}`
                  );
            }, 3000);
      };

      const handleBack = () => {
            setLoadingBack(true);
            let optionItem = currentOption.index - 1;
            setTimeout(() => {
                  setLoadingBack(false);
                  const updateOptions = options.map((item, i) => {
                        if (i <= optionItem) {
                              return { ...item, status: true };
                        }
                        return { ...item, status: false };
                  });
                  setOptions(updateOptions);
                  setCurrentOption({
                        id: updateOptions[optionItem].id,
                        index: optionItem,
                  });
                  navigate(
                        `/flight/${updateOptions[optionItem].id}/${reviewId}`
                  );
            }, 3000);
      };

      const travellerDetails = JSON.parse(localStorage.getItem("travellers"));
      const services = JSON.parse(localStorage.getItem("services"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));
      const gstDetails = JSON.parse(localStorage.getItem("gstDetails"));

      return (
            <>
                  <FlightDetailBox
                        tripInfos={tripInfos}
                        options={options}
                        setOptions={setOptions}
                        currentOption={currentOption}
                        setCurrentOption={setCurrentOption}
                        marginBottom={"20px"}
                        reviewId={reviewId}
                  />
                  <FlightDetailContainer>
                        <FlightReviewHeader>
                              Travellers Details ( {travellerDetails?.length} )
                        </FlightReviewHeader>
                        <FlightDetailInfo className="mt-0" border="0">
                              <FlightReviewTable>
                                    <div className="flight__table-header">
                                          S.N
                                    </div>
                                    <div className="flight__table-header">
                                          Traveller Name
                                    </div>
                                    <div className="flight__table-header">
                                          Seat
                                    </div>
                                    <div className="flight__table-header">
                                          Meal, Baggage, Other Services
                                    </div>
                              </FlightReviewTable>
                              {travellerDetails?.length > 0 &&
                                    travellerDetails?.map((item, index) => (
                                          <FlightReviewTable border={true}>
                                                <div>{++index}</div>
                                                <div>
                                                      {`${item?.title} ${item?.firstName} ${item?.lastName}`}
                                                </div>
                                                <div>
                                                      {Object.keys(
                                                            services
                                                      )?.map((service) => (
                                                            <>
                                                                  <li>
                                                                        <strong>
                                                                              {
                                                                                    service
                                                                              }
                                                                        </strong>
                                                                        :{" "}
                                                                        <span className="text-muted">
                                                                              {
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Seat
                                                                                          ?.code
                                                                              }
                                                                        </span>
                                                                  </li>
                                                            </>
                                                      ))}
                                                </div>
                                                <div>
                                                      {Object.keys(
                                                            services
                                                      )?.map((service) => (
                                                            <>
                                                                  <li>
                                                                        {services[
                                                                              service
                                                                        ][
                                                                              item
                                                                                    ?.ptName
                                                                        ]?.Meal
                                                                              ?.name && (
                                                                              <div className="d-flex align-items-center w-100">
                                                                                    <MealIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                                    {
                                                                                          service
                                                                                    }
                                                                                    {
                                                                                          ": "
                                                                                    }
                                                                                    {
                                                                                          services[
                                                                                                service
                                                                                          ][
                                                                                                item
                                                                                                      ?.ptName
                                                                                          ]
                                                                                                ?.Meal
                                                                                                ?.name
                                                                                    }
                                                                              </div>
                                                                        )}
                                                                        {services[
                                                                              service
                                                                        ][
                                                                              item
                                                                                    ?.ptName
                                                                        ]
                                                                              ?.Baggage
                                                                              ?.name && (
                                                                              <div className="d-flex align-items-center w-100">
                                                                                    <BaggageIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                                    {
                                                                                          service
                                                                                    }
                                                                                    {
                                                                                          ": "
                                                                                    }
                                                                                    {
                                                                                          services[
                                                                                                service
                                                                                          ][
                                                                                                item
                                                                                                      ?.ptName
                                                                                          ]
                                                                                                ?.Baggage
                                                                                                ?.name
                                                                                    }
                                                                              </div>
                                                                        )}
                                                                        {services[
                                                                              service
                                                                        ][
                                                                              item
                                                                                    ?.ptName
                                                                        ]
                                                                              ?.Baggage
                                                                              ?.name && (
                                                                              <div className="d-flex align-items-center w-100">
                                                                                    <ServiceIcon
                                                                                          width="20px"
                                                                                          height="20px"
                                                                                    />
                                                                                    {
                                                                                          service
                                                                                    }
                                                                                    {
                                                                                          ": "
                                                                                    }
                                                                                    {
                                                                                          services[
                                                                                                service
                                                                                          ][
                                                                                                item
                                                                                                      ?.ptName
                                                                                          ]
                                                                                                ?.Baggage
                                                                                                ?.name
                                                                                    }
                                                                              </div>
                                                                        )}
                                                                  </li>
                                                            </>
                                                      ))}
                                                </div>
                                          </FlightReviewTable>
                                    ))}
                        </FlightDetailInfo>
                        <FlightReviewContent>
                              <FlightReviewHeader>
                                    Contact Details
                              </FlightReviewHeader>

                              <FlightReviewInfo>
                                    <ul>
                                          <li>
                                                Email:{" "}
                                                {contactDetails?.emailAddress}
                                          </li>
                                          <li>
                                                Mobile:{" "}
                                                {`${contactDetails?.countryCode}-${contactDetails?.phoneNumber}`}
                                          </li>
                                    </ul>
                              </FlightReviewInfo>
                        </FlightReviewContent>
                        {gstDetails && (
                              <FlightReviewContent>
                                    <FlightReviewHeader>
                                          GST Details
                                    </FlightReviewHeader>
                                    <FlightReviewInfo>
                                          <ul>
                                                <li>
                                                      Reg. Number:{" "}
                                                      {
                                                            gstDetails?.registrationNumber
                                                      }
                                                </li>
                                                <li>
                                                      Company Name:{" "}
                                                      {`${gstDetails?.companyName}`}
                                                </li>
                                          </ul>
                                    </FlightReviewInfo>
                              </FlightReviewContent>
                        )}
                  </FlightDetailContainer>

                  <div className="price__button d-flex w-100 mt-4 justify-content-between align-items-center mt-0">
                        <CommonButton
                              title={"Back"}
                              leftIcon={
                                    <DoubleIcon
                                          transform="rotate(180deg)"
                                          color="#FFF"
                                    />
                              }
                              padding={"8px 16px"}
                              type="button"
                              button={true}
                              handleClick={handleBack}
                              loading={loadingBack}
                        />
                        <CommonButton
                              title={"Process To Pay"}
                              icon={<DoubleIcon color="#FFF" />}
                              padding={"8px 30px"}
                              type="submit"
                              button={true}
                              handleClick={handleOption}
                              loading={loading}
                        />
                  </div>
            </>
      );
};

export default FlightReviewDetail;
