import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
      CustomSelectContainer,
      SelectButton,
      SelectList,
} from "../../components/custom/CustomSelect/styles";

export const LoginContainer = styled.div`
      background-image: url("/images/banners/Mobikwik_Hotel_landing_page_banner-5.jpg");
      background-repeat: none;
      background-position: top center;
      background-size: cover;
      overflow: hidden;
      min-height: 100vh;
`;
export const LoginForm = styled.div``;
export const LoginAdditional = styled.div``;
export const LoginSIgnup = styled.div``;

export const B2BIcon = styled.div``;

export const LoginSection = styled.div`
      margin: 2rem 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 5px;
      background-color: #224082;
`;

export const LoginDetail = styled(Col)`
      background-color: #ffffff75;
      border-right: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
`;

export const LoginDetailContent = styled(Col)`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      height: 100%;
`;

export const LoginBanner = styled.div`
      padding: 10px;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* flex-direction: column; */
`;

export const LoginContent = styled.div`
      color: ${(props) => props.theme.white};
      text-align: center;

      a {
            color: ${(props) => props.theme.secondary};
            /* display: block; */
            text-decoration: none;
      }
`;

export const LoginFormSection = styled(Form)`
      background-color: white;
      padding: 32px;
      border-radius: 5px;
      margin: 10px 0;
      position: relative;
      transition: transform 1s ease;
      margin-top: 1.5rem;

      &.register {
            top: 0px;
            position: absolute;
            right: -420px;
      }
      ${SelectButton} {
            position: relative;
            width: 100%;
            padding: 8px 12px;
            font-size: 15px;
            border: 1px solid transparent;
            background-color: #224082;
            cursor: pointer;
            text-align: left;
            border-radius: 4px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #fff;
      }
      ${CustomSelectContainer} {
            width: 120px;
      }
      ${SelectList} {
            top: 39px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            background: ${(props) => props.theme.white};
      }
`;

export const LoginInput = styled.div`
      display: flex;
      align-items: center;
      gap: 0px;
      border-radius: var(--bs-border-radius);
      width: 100%;

      svg {
            width: 19px;
            display: inline-block;
            font-size: inherit;
            height: 18px;
            overflow: visible;
            vertical-align: -0.125em;
            color: rgb(119, 119, 119);
      }

      .form-select {
            background-color: transparent;
            box-shadow: none;
            border: 0;
      }

      background: ${(props) => (props.disabled ? "#e9ecef" : "#f0f0f0")};
      input {
            border: 0;
            padding: 10px;
            width: 100%;
            background-color: transparent;
            font-size: 14px;
            color: ${(props) => props.theme.inputColor};
            &:placeholder {
                  color: ${(props) => props.theme.inputColor};
            }
            &:visited,
            &:focus-visible {
                  border: 0;
                  box-shadow: none;
                  outline: 0;
                  background-color: transparent;
            }
            &:disabled {
                  background-color: #e9ecef;
                  opacity: 1;
            }
            &:-webkit-autofill {
                  background-color: white !important;
            }
            /* For Chrome, Safari, and Edge */
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }

            /* For Firefox */
            &:-moz-autofill,
            &:-moz-autofill:hover,
            &:-moz-autofill:focus,
            &:-moz-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }

            /* For Internet Explorer */
            &:-ms-autofill,
            &:-ms-autofill:hover,
            &:-ms-autofill:focus,
            &:-ms-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }
      }
      input[type="file"] {
            &::file-selector-button {
                  background-color: #fff;
                  color: #9f9f9f;
                  border-right: 1px solid #9f9f9f;
                  cursor: pointer;
            }
            &:hover {
                  &::file-selector-button {
                        background-color: #fff;
                        color: #9f9f9f;
                        border-right: 1px solid #9f9f9f;
                        cursor: pointer;
                  }
            }
            &:disabled {
                  background-color: #e9ecef;
                  opacity: 1;
                  &::file-selector-button {
                        background-color: #e9ecef;
                        color: #9f9f9f;
                        border-right: 1px solid #9f9f9f;
                        cursor: pointer;
                  }
            }
      }
      span {
            font-size: 19px;
            color: ${(props) => props.theme.inputColor};
      }
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
`;

export const LoginFileInput = styled.div`
      display: flex;
      align-items: center;
      gap: 0px;
      border-radius: var(--bs-border-radius);
      width: 100%;

      svg {
            width: 19px;
            display: inline-block;
            font-size: inherit;
            height: 18px;
            overflow: visible;
            vertical-align: -0.125em;
            color: rgb(119, 119, 119);
      }

      .form-select {
            background-color: transparent;
            box-shadow: none;
            border: 0;
      }

      background: ${(props) => (props.disabled ? "#e9ecef" : "#f0f0f0")};
      input {
            border: 0;
            padding: 10px;
            width: 100%;
            background-color: transparent;
            font-size: 14px;
            color: ${(props) => props.theme.inputColor};
            &:placeholder {
                  color: ${(props) => props.theme.inputColor};
            }
            &:visited,
            &:focus-visible {
                  border: 0;
                  box-shadow: none;
                  outline: 0;
                  background-color: transparent;
            }

            &:-webkit-autofill {
                  background-color: white !important;
            }
            /* For Chrome, Safari, and Edge */
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }

            /* For Firefox */
            &:-moz-autofill,
            &:-moz-autofill:hover,
            &:-moz-autofill:focus,
            &:-moz-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }

            /* For Internet Explorer */
            &:-ms-autofill,
            &:-ms-autofill:hover,
            &:-ms-autofill:focus,
            &:-ms-autofill:active {
                  background: transparent;
                  background-color: transparent;
            }
      }
      input[type="file"] {
            &::file-selector-button {
                  background-color: #fff;
                  color: #9f9f9f;
                  border-right: 1px solid #9f9f9f;
                  cursor: pointer;
            }
            &:hover {
                  &::file-selector-button {
                        background-color: #fff;
                        color: #9f9f9f;
                        border-right: 1px solid #9f9f9f;
                        cursor: pointer;
                  }
            }
      }
      span {
            font-size: 19px;
            color: ${(props) => props.theme.inputColor};
      }
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
`;

export const LoginIcon = styled.div``;

export const LoginLink = styled(Link)`
      text-decoration: none;
      color: ${(props) => props.theme.primary};
      transition: all 0.3s ease-in;
      font-size: 13px;
      &:hover {
            color: ${(props) => props.theme.secondary};
      }
`;

export const LoginTerms = styled.div`
      border-top: 1px dashed ${(props) => props.theme.primary};
      margin-top: 5rem;
      padding-top: 20px;
      text-align: center;
      font-size: 14px;

      a {
            text-decoration: none;
            color: ${(props) => props.theme.primary};
      }
`;

export const LoginAction = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
`;

export const LoginButton = styled(Button)`
      background-color: transparent;
      border: none;
      color: ${(props) => props.theme.primary};
      &:hover {
            background-color: transparent;
            color: ${(props) => props.theme.secondary};
      }
`;

export const LoginButtonContainer = styled.div`
      position: relative;
      transition: transform 1s ease;
      &:after {
            position: absolute;
            content: "";
            background: ${(props) => props.theme.secondary};
            height: 2px;
            width: 50%;
            bottom: 0px;
            left: 0px;
            transition: transform 0.3s ease;
            transform: ${(props) =>
                  props.activeForm === "register"
                        ? "translateX(70px)"
                        : "translateX(0px)"};
      }
      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
            color: ${(props) => props.theme.primary};
            background-color: transparent;
            border-color: unset;
      }
`;

export const LoginFormContainer = styled.div`
      position: relative;
`;
