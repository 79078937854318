import React, { useState } from "react";
import getCurrentDate from "../../../../hooks/CurrentData";
import {
      SearchFormAction,
      SearchFormWrapper,
} from "../../home/SearchForm/styles";
import { Form } from "react-bootstrap";
import FormItem from "../../../form";
import CustomButton from "../../../Common/CustomButton";
import PlaneTookOffIcon from "../../../../icons/PlaneTookOffIcon";
import PlaneLandingIcon from "../../../../icons/PlaneLandingIcon";

const PackageForm = ({ width, marginTop, marginBottom, marginRight, wrap }) => {
      const [travelers, setTravelers] = useState([
            {
                  room: "1",
                  children: "0",
                  adult: "1",
                  childrenAges: [],
            },
      ]);
      const [formData, setFormData] = useState({
            destination: "New Delhi (DEL)",
            depart: getCurrentDate(),
            checkOut: "",
            duration: "All Packages",
      });

      const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                  ...formData,
                  [name]: value,
            });
      };

      const handleSubmit = (e) => {
            e.preventDefault();
      };
      const [active, setActive] = useState(false);
      const [changeDestination, setChangeDestination] = useState(false);
      return (
            <SearchFormWrapper
                  width={width}
                  marginBottom={marginBottom}
                  marginTop={marginTop}
                  wrap={wrap}
                  flex="flex"
            >
                  <Form onSubmit={handleSubmit}>
                        <FormItem
                              controlId="formName"
                              label="Leaving From"
                              type="icon"
                              name={"destination"}
                              value={formData.destination}
                              onChange={handleChange}
                              placeholder="Jaipur (JAI)"
                              width="18%"
                              state={true}
                              icon={<PlaneTookOffIcon />}
                        />
                        <FormItem
                              controlId="formName"
                              label="Destination"
                              type="icon"
                              name={"destination"}
                              value={formData.destination}
                              onChange={handleChange}
                              placeholder="Jaipur (JAI)"
                              width="18%"
                              state={true}
                              icon={<PlaneLandingIcon />}
                        />
                        <FormItem
                              controlId="formName"
                              label="Depart"
                              type="date"
                              name="depart"
                              value={formData.depart}
                              onChange={handleChange}
                              placeholder="Select Travel Month"
                              width="15%"
                              state={true}
                        />
                        <FormItem
                              controlId="formName"
                              label="Duration"
                              type="text"
                              name="depart"
                              value={formData.checkOut}
                              onChange={handleChange}
                              placeholder="1-Night"
                              width="15%"
                              state={true}
                        />
                        <FormItem
                              controlId="formName"
                              label="Travellers"
                              type="hotel"
                              name={"duration"}
                              onChange={handleChange}
                              placeholder="Jaipur (JAI)"
                              width="20%"
                              value={travelers}
                              data={travelers}
                              setData={setTravelers}
                              state={true}
                        />
                        <SearchFormAction>
                              <label style={{ visibility: "hidden" }}>
                                    Submit
                              </label>
                              <CustomButton
                                    type={"submit"}
                                    className="mt-3"
                                    title={"Search "}
                              />
                        </SearchFormAction>
                  </Form>
            </SearchFormWrapper>
      );
};

export default PackageForm;
