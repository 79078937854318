import React from "react";

const PhoneIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">call</span>
            </>
      );
};

export default PhoneIcon;
