import React, { useState } from "react";
import {
      RoundtripBookContainer,
      RoundtripInfoContent,
      RoundtripInfoLogo,
      RoundtripNetPrice,
} from "./style";
import { Container, Form } from "react-bootstrap";
import {
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "../../../Dashboard/FlightInfo/styles";
import { NumberFormatBase } from "react-number-format";
import CommonButton from "../../../Common/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";

const RoundtripBook = ({
      returnFare,
      fareId,
      flights,
      returnFlights,
      searchDetail,
      netFare,
}) => {
      const previousSearch = useLocation().pathname;
      const previousQuery = useLocation().search;
      const previousLink = `${previousSearch}${previousQuery}`;

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const flightDetail = flights.find((item) =>
            item?.totalPriceList?.find(
                  (currentFare) => currentFare?.id === fareId?.id
            )
      );
      const returnDetail = returnFlights.find((item) =>
            item?.totalPriceList?.find(
                  (currentFare) => currentFare?.id === returnFare?.id
            )
      );

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const arrivalTime = (sI) => {
            const totalSi = sI.length;
            const lastSi = sI[totalSi - 1];
            return lastSi;
      };

      const calculateTotalAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.ADULT?.fC?.BF * searchDetail?.adult +
                                    currentItem?.ADULT?.fC?.TAF *
                                          searchDetail?.adult
                        );
            }
            if (currentItem?.CHILD) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.CHILD?.fC?.BF *
                                    searchDetail?.children +
                                    currentItem?.CHILD?.fC?.TAF *
                                          searchDetail?.children
                        );
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.INFANT?.fC?.BF *
                                    searchDetail?.infants +
                                    currentItem?.INFANT?.fC?.TAF *
                                          searchDetail?.infants
                        );
            }
            return newAmount;
      };

      const calculateTotalNetAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.ADULT?.fC?.NF * searchDetail?.adult
                        );
            }
            if (currentItem?.CHILD) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.CHILD?.fC?.NF *
                                    searchDetail?.children
                        );
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        parseFloat(newAmount) +
                        parseFloat(
                              currentItem?.INFANT?.fC?.NF *
                                    searchDetail?.infants
                        );
            }
            return newAmount;
      };

      const grandTotalAmount = () => {
            let grandTotal = 0;

            grandTotal =
                  parseFloat(calculateTotalAmount(fareId?.fd)) +
                  parseFloat(calculateTotalAmount(returnFare?.fd));

            return grandTotal;
      };

      const grandNetTotalAmount = () => {
            let grandTotal = 0;

            grandTotal =
                  parseFloat(calculateTotalNetAmount(fareId?.fd)) +
                  parseFloat(calculateTotalNetAmount(returnFare?.fd));

            return grandTotal;
      };

      const handleSubmit = (event) => {
            event.preventDefault();
            setLoading(true);
            const optionId = `${fareId?.id},${returnFare?.id}`;

            localStorage.setItem("previousSearch", previousLink);
            localStorage.setItem("reviewId", optionId);

            localStorage.removeItem("contactDetails");
            localStorage.removeItem("gstDetails");
            localStorage.removeItem("travellers");
            localStorage.removeItem("services");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/travellers/${optionId}`);
            }, 3000);
      };

      return (
            <>
                  <RoundtripBookContainer>
                        <Container>
                              <Form onSubmit={handleSubmit}>
                                    <div className="d-flex gap-3">
                                          {flightDetail && (
                                                <>
                                                      <RoundtripInfoContent>
                                                            <FlightInfoTitle className="gap-2">
                                                                  <RoundtripInfoLogo>
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${flightDetail?.sI[0]?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    flightDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  </RoundtripInfoLogo>
                                                                  <div className="title">
                                                                        <h4 className="pb-0 mb-0">
                                                                              {
                                                                                    flightDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        </h4>
                                                                        <div
                                                                              style={{
                                                                                    fontSize: "11px",
                                                                                    color: "#666",
                                                                              }}
                                                                        >
                                                                              {
                                                                                    flightDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code
                                                                              }{" "}
                                                                              {
                                                                                    flightDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.fN
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            </FlightInfoTitle>
                                                            <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                                  <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                                        {formatTime(
                                                                              flightDetail
                                                                                    ?.sI[0]
                                                                                    ?.dt
                                                                        )}{" "}
                                                                        -{" "}
                                                                        {formatTime(
                                                                              arrivalTime(
                                                                                    flightDetail?.sI
                                                                              )
                                                                                    ?.at
                                                                        )}
                                                                  </FlightInfoTime>

                                                                  <div
                                                                        className="place d-flex gap-1 align-items-center"
                                                                        style={{
                                                                              fontSize: "11px",
                                                                              color: "#666",
                                                                        }}
                                                                  >
                                                                        {
                                                                              searchDetail?.leavingFrom
                                                                        }
                                                                        <span
                                                                              className="material-symbols-outlined"
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                        >
                                                                              east
                                                                        </span>
                                                                        {
                                                                              searchDetail?.destination
                                                                        }
                                                                  </div>
                                                            </FlightInfoTime>
                                                            <FlightInfoPrice>
                                                                  <div className="flight__info-price ">
                                                                        ₹
                                                                        <NumberFormatBase
                                                                              prefix={
                                                                                    ""
                                                                              }
                                                                              thousandsGroupStyle="lakh"
                                                                              thousandSeparator={
                                                                                    true
                                                                              }
                                                                              displayType={
                                                                                    "text"
                                                                              }
                                                                              value={calculateTotalAmount(
                                                                                    fareId?.fd
                                                                              )}
                                                                        />
                                                                        {netFare && (
                                                                              <span className="flight__info-net text-muted">{` | NET ${calculateTotalNetAmount(
                                                                                    fareId?.fd
                                                                              )}`}</span>
                                                                        )}
                                                                  </div>
                                                                  {fareId.fd
                                                                        ?.ADULT
                                                                        ?.sR <=
                                                                        5 && (
                                                                        <div
                                                                              style={{
                                                                                    fontSize: "11px",
                                                                                    color: "#666",
                                                                              }}
                                                                              className="text-danger"
                                                                        >
                                                                              {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                                        </div>
                                                                  )}
                                                            </FlightInfoPrice>
                                                      </RoundtripInfoContent>
                                                </>
                                          )}
                                          {returnDetail && (
                                                <>
                                                      <RoundtripInfoContent>
                                                            <FlightInfoTitle className="gap-2">
                                                                  <RoundtripInfoLogo>
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${returnDetail?.sI[0]?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    returnDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  </RoundtripInfoLogo>
                                                                  <div className="title">
                                                                        <h4 className="pb-0 mb-0">
                                                                              {
                                                                                    returnDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        </h4>
                                                                        <div
                                                                              style={{
                                                                                    fontSize: "11px",
                                                                                    color: "#666",
                                                                              }}
                                                                        >
                                                                              {
                                                                                    returnDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code
                                                                              }{" "}
                                                                              {
                                                                                    returnDetail
                                                                                          ?.sI[0]
                                                                                          ?.fD
                                                                                          ?.fN
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            </FlightInfoTitle>
                                                            <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                                  <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                                        {formatTime(
                                                                              returnDetail
                                                                                    ?.sI[0]
                                                                                    ?.dt
                                                                        )}{" "}
                                                                        -{" "}
                                                                        {formatTime(
                                                                              arrivalTime(
                                                                                    returnDetail?.sI
                                                                              )
                                                                                    ?.at
                                                                        )}
                                                                  </FlightInfoTime>

                                                                  <div
                                                                        className="place d-flex gap-1 align-items-center"
                                                                        style={{
                                                                              fontSize: "11px",
                                                                              color: "#666",
                                                                        }}
                                                                  >
                                                                        {
                                                                              searchDetail?.destination
                                                                        }
                                                                        <span
                                                                              className="material-symbols-outlined"
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                        >
                                                                              east
                                                                        </span>
                                                                        {
                                                                              searchDetail?.leavingFrom
                                                                        }
                                                                  </div>
                                                            </FlightInfoTime>
                                                            <FlightInfoPrice>
                                                                  <div className="flight__info-price ">
                                                                        ₹
                                                                        <NumberFormatBase
                                                                              prefix={
                                                                                    ""
                                                                              }
                                                                              thousandsGroupStyle="lakh"
                                                                              thousandSeparator={
                                                                                    true
                                                                              }
                                                                              displayType={
                                                                                    "text"
                                                                              }
                                                                              value={calculateTotalAmount(
                                                                                    returnFare?.fd
                                                                              )}
                                                                        />
                                                                        {netFare && (
                                                                              <span className="flight__info-net text-muted">{` | NET ${calculateTotalAmount(
                                                                                    returnFare?.fd
                                                                              )}`}</span>
                                                                        )}
                                                                  </div>
                                                                  {returnFare
                                                                        ?.fd
                                                                        ?.ADULT
                                                                        ?.sR <=
                                                                        5 && (
                                                                        <div
                                                                              style={{
                                                                                    fontSize: "11px",
                                                                                    color: "#666",
                                                                              }}
                                                                              className="text-danger"
                                                                        >
                                                                              {`${returnFare?.fd?.ADULT?.sR} Seats left`}
                                                                        </div>
                                                                  )}
                                                            </FlightInfoPrice>
                                                      </RoundtripInfoContent>
                                                </>
                                          )}
                                          <div>
                                                <RoundtripNetPrice fontSize="16px">
                                                      ₹ {grandTotalAmount()}
                                                </RoundtripNetPrice>
                                                <RoundtripNetPrice className="text-muted ">
                                                      NET ₹{" "}
                                                      {grandNetTotalAmount()}
                                                </RoundtripNetPrice>
                                          </div>
                                          <CommonButton
                                                type="submit"
                                                button={true}
                                                title="Book Now"
                                                loading={loading}
                                          />
                                    </div>
                              </Form>
                        </Container>
                  </RoundtripBookContainer>
            </>
      );
};

export default RoundtripBook;
