import styled from "styled-components";

export const FooterButtonContainer = styled.div`
      padding: 5px 8px;
      font-size: 15px;
      text-transform: capitalize;
      border: 1px solid ${(props) => props.theme.textColor};
      border-radius: 100px;
      text-align: center;
      display: inline-block;
      margin-bottom: 1rem;
      min-width: 93px;
`;
