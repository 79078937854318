import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";

// Define keyframes for the bounce animation
export const bounceAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-160deg); }
  50% { transform:  rotate(-225deg); }
  65% { transform:  rotate(-190deg); }
  80% { transform:  rotate(-200deg); }
  80% { transform:  rotate(-180deg); }
  100% { transform:  rotate(-180deg); }
  animation-fill-mode: forwards; 
`;

export const rotateAnimation = keyframes`
  0% { transform: rotate(-180deg); }
  100% { transform: rotate(0deg); }
`;

// Styled component for the capsule
const Capsule = styled.div`
      width: 60px;
      height: 30px;
      background-color: ${(props) =>
            props.isActive ? props.theme.primary : "#f0f0f0"};
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 3px;
      cursor: pointer;
      transition: background-color 0.3s;
      position: relative;
      /* Apply animation when active */
      ${(props) =>
            props.isActive &&
            css`
                  animation: ${bounceAnimation} 0.3s ease;
                  transform: rotate(-180deg);
            `}
      /* Apply rotation to capsule when not active */
  ${(props) =>
            !props.isActive &&
            css`
                  animation: ${rotateAnimation} 0.3s ease;
            `}
`;

const Circle = styled.span`
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${(props) =>
            props.isActive ? "#f0f0f0" : props.theme.primary};
      transition: transform 0.3s, background-color 0.3s;
      position: absolute;
      left: 3px;
      right: 3px;

      /* Apply rotation when active */
      ${(props) =>
            props.isActive &&
            css`
                  animation: ${bounceAnimation} 0.3s ease;
            `}
`;

const Test = ({ isActive, setIsActive }) => {
      const handleClick = () => {
            setIsActive(!isActive);
      };

      return (
            <Capsule isActive={isActive} onClick={handleClick}>
                  <Circle isActive={isActive} />
            </Capsule>
      );
};

export default Test;
