import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import { BrowserRouter as Router } from "react-router-dom";
import FormProvider from "./context/FormContext";

// creating the global constant for form

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <Router>
            <FormProvider>
                  <App />
            </FormProvider>
      </Router>
);
